import React, {Component} from "react";
import Test2 from "./Test2";
import {sendPostRequest} from "../tool/http/Request";
import {findMsgByError} from "../tool/http/ErrorMgr";

class Test extends Test2 {
    constructor(props) {
        super(
            props,
            {name: "2", select: "1"},
            {isEdit: props.isEdit}

        );
        this.state = {
            count: 0,
        }

        this.timeout = null
    }

    testIt(w) {
        console.log("22222")
    }

    componentDidMount() {
        this.testIt("1")
        // let fun = new IntervalFun(10, 2000)
        // let res = fun.runBackOver()
        // console.log("shuosho")
        // console.log(res)
        // // this.launch();
        // // let auth = this.state.auth;
        // // let frame = this.state.frame;
        // // auth.name = "shibowen"
        // // this.setState({
        // //     auth: auth,
        // // })

    }

    launch = () => {
        // IntervalTool();
    }

    // async checkSuccess() {
    //     return await sendPostRequest({url: "http://localhost:8083/currencyHost", data: {}}, {}, false).then(res => {
    //         console.log(res)
    //         if (res === "await") {
    //             let count = this.state.count;
    //             if (count < 20) {
    //                 this.setState({
    //                     count: count++,
    //                 }, () => {
    //                     this.timeout = setTimeout(() => {
    //                         this.checkSuccess();
    //                     }, 3000)
    //                 })
    //
    //             } else {
    //                 fail("超时")
    //             }
    //         } else if (res === "success") {
    //
    //         } else {
    //             fail(findMsgByError(res[error]))
    //         }
    //     })
    // }

    // import React, {useEffect, useReducer, useState} from "react";
//
// function reducer(state: { count: number }) {
//     return {count: state.count - 1};
// }
//
// export default function IntervalTool() {
//     const [state, dispatch] = useReducer(reducer, {count: 10});
//
//     const [timer, setTimer] = useState(null);
//
//     useEffect(() => {
//         if (timer === null) {
//             console.log("set")
//             setTimer(
//                 setInterval(() => {
//                     dispatch();
//                 }, 1000)
//             )
//         }
//         return () => {
//             console.log("clear final")
//             clearInterval(timer)
//         }
//     }, []);
//
//     useEffect(() => {
//         console.log(state.count)
//         if (state.count === 0) {
//             console.log("clear")
//             clearInterval(timer)
//             setTimer(null)
//         }
//     }, [state]);
//
//     return (
//         <div>
//             <p>当前计数{state.count}</p>
//         </div>
//     );
// }
//
//     import {storage} from "./Const";

    render() {
        return (
            <div style={{background: "grey", minHeight: "100vh", width: "100vw"}} id={"shi"}>
                {/*<IntervalTool/>*/}
            </div>
        )
    }
}

export default Test;