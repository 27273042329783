import React, {ReactNode, useEffect, useMemo} from 'react';
import "./tool.css";
import QueueAnim from "rc-queue-anim";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {createRoot, Root} from "react-dom/client";

let warp: HTMLElement;

let root: Root;

export const createMsg = () => {
    return (contentC: ReactNode, duration: ReactNode, typeI: ReactNode, toUrl: ReactNode) => {
        if (typeof document === "undefined") {
            return;
        }
        if (!warp) {
            warp = document.createElement("div");

        }
        if (warp) {
            document.body && document.body.appendChild(warp);
        }

        warp.style.cssText = 'width: 100%; height: 50px; position: fixed; background: transparent; display: flex; justify-content: center; top: 10vh; z-index: 999';
        const divs = document.createElement("div");
        root = createRoot(divs)
        warp.appendChild(divs);
        root.render(
            <Message rootDom={warp} parentDom={divs} content={contentC} duration={duration} typeI={typeI}
                     toUrl={toUrl}/>
        )
    }
}

export type MessageProps = {
    rootDom: HTMLElement;
    parentDom: Element | DocumentFragment;
    content: ReactNode;
    duration: ReactNode;
    typeI: ReactNode;
    toUrl: ReactNode;
}

export function Message(props: MessageProps) {
    const {rootDom, parentDom, content, duration, typeI, toUrl} = props;

    const getIcon = (typeI) => {
        if (typeI === 1) {
            return <div className={"check-box"} style={{background: "rgb(44,213,15)"}}>
                <CheckOutlined/>
            </div>
        } else {
            return <div className={"check-box"} style={{background: "rgb(255,55,55)"}}>
                <CloseOutlined/>
            </div>
        }
    }

    const unmount = useMemo(() => {
        return () => {
            if (parentDom && rootDom) {
                root.unmount();
                rootDom.removeChild(parentDom);
                rootDom.style.cssText = 'display: none';
            }
        }
    }, [parentDom, rootDom]);

    const toTarget = () => {
        if (toUrl) {
            window.location.href = toUrl;
        }
    }

    useEffect(() => {
        let time = duration * 1000;
        setTimeout(() => {
            unmount();
        }, time);
        setTimeout(() => {
            toTarget();
        }, time + 200);
    }, [unmount]);

    return document.getElementById("msg-win-s") === null ?
        <QueueAnim type={"top"} animConfig={{translateY: [0, -40]}}
                   duration={500} delay={0} id={"msg-win-s"}>
            <div className={"message-win"} key={"msg-win"}>
                {getIcon(typeI)}
                {content}
            </div>
        </QueueAnim>
        :
        null;
}