import {
    checkUser,
    countUsers, disableComp, disablePer,
    findUsers,
    genPojo,
    sendPostBack, updatePoints, updateUserPhone,
} from "../../tool/http/Request";

class UserOperator {
    findData = (back: DataBack, userRequest: UserRequest) => {
        sendPostBack(genPojo(findUsers, userRequest), true, back);
    }

    findDataSize = (back: DataBack, userRequest: UserRequest) => {
        sendPostBack(genPojo(countUsers, userRequest), true, back);
    }

    findDataById = (back: DataBack, userRequest: UserRequest) => {
        sendPostBack(genPojo(checkUser, userRequest), true, back);
    }

    updateUserPhone = (back: DataBack, userRequest: UserRequest) => {
        sendPostBack(genPojo(updateUserPhone, userRequest), true, back);
    }

    disableUserPer = (back: DataBack, userRequest: UserRequest) => {
        sendPostBack(genPojo(disablePer, userRequest), true, back);
    }

    disableUserComp = (back: DataBack, userRequest: UserRequest) => {
        sendPostBack(genPojo(disableComp, userRequest), true, back);
    }

    updatePoint = (back: DataBack, userRequest: UserRequest) => {
        sendPostBack(genPojo(updatePoints, userRequest), true, back);
    }
}

export default UserOperator;