import React, {Fragment} from "react";
import "./component.css";
import FrameParent from "./FrameParent";
import {
    AreaMap,
    expertAreas, expertEdu,
    expertTitle,
    itemAreas,
    itemCop,
    itemSrc,
    itemType,
    mapToJson,
    picTypes, storage
} from "../../tool/Const";
import {ResourceHeader} from "../../tool/http/Request";
import GeoFilter from "../../tool/GeoFilter";

class ExpertFrame extends FrameParent {
    constructor(props) {
        super(props);
        this.state = {
            pojoTmp: JSON.parse(JSON.stringify(this.props.pojo)),  // findDataPojo缓存
            isEdit: false,  // 是否编辑
            areas: JSON.parse(JSON.stringify(expertAreas)),  // 行业选择
            areasTmp: '',  // 行业选择缓存
            areasLoad: false,  // 行业加载完成

            pics: new Map(),
            imgSrc: {
                expertBanner: '',
                picSrc: [],
            },
            imgUrl: {
                expertBanner: '',
                picSrc: [],
            },

            selectItem: -1,
            openGeo: false,
            showCity: -1,
        }
    }

    loadEdit = (pojoTmp, isAdd, isEdit, areasTmp, areasLoad, imgUrl, imgSrc) => {
        let dataStorage = storage.get('expertData');
        console.log("dataStorage: ", dataStorage);
        let data = dataStorage ? dataStorage.pojoStr : pojoTmp.pojoStr;
        let img = ResourceHeader + data['expertId'] + "/" + data['expertBanner'];
        let imgBanner = imgUrl['expertBanner'] ? imgUrl['expertBanner'] : img;
        return (
            <div className={"policy-edit-part-wrap"}>
                <div className={"policy-edit-part"}>
                    {this.loadEditItem('650px', true, '专家名称',
                        this.loadEditItemInput('540px', isEdit, !isAdd, 30, pojoTmp, data, 'expertName', false))}
                    {this.loadEditItem('240px', true, "专家来源",
                        this.loadEditItemSelect('160px', isEdit, !isEdit, pojoTmp, data, 'srcType', false, itemSrc))}
                    {this.loadEditItem('240px', true, "专家职称",
                        this.loadEditItemSelect('160px', isEdit, !isEdit, pojoTmp, data, 'expertTitle', true, expertTitle))}
                    {this.loadEditItem('600px', false, '荣誉职称',
                        this.loadEditItemInput('280px', isEdit, !isEdit, 30, pojoTmp, data, 'expertHor', false),
                        <span className={"outcome-item-title-tip"}>{"（多个职称，请以;分开）"}</span>)}
                    {this.loadEditItem('600px', true, '担任职务',
                        this.loadEditItemInput('280px', isEdit, !isEdit, 30, pojoTmp, data, 'expertJob', false),
                        <span className={"outcome-item-title-tip"}>{"（多个职务，请以;分开）"}</span>)}
                    <div className={"outcome-edit-line"} style={{minHeight: "50px"}}>
                        <div className={"outcome-edit-item"}>
                            {this.loadEditItemTitle(true, "地理位置")}
                            <GeoFilter options={data['expertGeo']} parent={this}
                                       areaClass={isEdit ? "filter-area" : "filter-area-unactivated"}
                                       map={AreaMap}  isSub={true}
                                       mainType={null}
                                       isEdit={isEdit} setGeo={this.setGeo}
                            />
                        </div>
                    </div>
                    {this.loadEditItem('400px', true, '所属机构',
                        this.loadEditItemInput('280px', isEdit, !isEdit, 30, pojoTmp, data, 'expertRoot', false))}
                    {this.loadEditItem('400px', true, '毕业院校',
                        this.loadEditItemInput('280px', isEdit, !isEdit, 30, pojoTmp, data, 'expertSch', false))}
                    {this.loadEditItem('240px', false, '教育程度',
                        this.loadEditItemSelect('160px', isEdit, !isEdit, pojoTmp, data, 'expertEdu', false, expertEdu))}
                    {this.loadEditItem('400px', false, '未来目标',
                        this.loadEditItemInput('280px', isEdit, !isEdit, 30, pojoTmp, data, 'expertTarget', false))}
                    {this.loadEditItem('200px', true, '预约价格',
                        this.loadEditItemInput('100px', isEdit, !isAdd, 22, pojoTmp, data, 'expertPrice', false))}
                    {this.loadEditItem('300px', true, '初始点击量',
                        this.loadEditItemInput('100px', isEdit, !isEdit, 10, pojoTmp, data, 'clickNum', false))}
                    {this.loadEditItem('300px', true, '初始收藏量',
                        this.loadEditItemInput('100px', isEdit, !isEdit, 10, pojoTmp, data, 'saveNum', false))}
                    {this.loadEditItem('400px', false, '关联用户id',
                        this.loadEditItemInput('280px', isEdit, !isAdd, 22, pojoTmp, data, 'refId', false))}
                    <div className={"outcome-edit-line"} style={{minHeight: "100px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 0"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"研究领域"}
                        </span>
                        <div>
                            {areasLoad ? this.loadBoxes(areasTmp, isEdit, data, "expertAreas") : null}
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "100px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 0"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"专家封面"}
                        </span>
                        {
                            data['expertBanner'] ?
                                <div className={"img-wrap"}>
                                    <img className={"img-style contain"} src={imgBanner} alt={""}/>
                                </div>
                                : null
                        }
                        <div className={"img-upload-fun"}>
                            <label htmlFor={"banner"} className={"img-upload-btn"}
                                   style={{pointerEvents: isEdit ? "" : "none"}}
                            >
                                {"点击上传"}
                            </label>
                            <input type={"file"} name={"file"} id={"banner"}
                                   className={"input-file"}
                                   accept={picTypes}
                                   onChange={(e) => {
                                       this.handleImgUpload(e, 1400, 1400, imgSrc, imgUrl, "expertBanner", 1, pojoTmp)
                                   }}
                            />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "200px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"专家描述"}
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{height: "150px", minWidth: "600px", fontSize: "14px", padding: "10px"}}
                                          key={"expertDes"}
                                          value={data['expertDes']}
                                          maxLength={60}
                                          readOnly={!isEdit}
                                          onChange={(e) => {this.handleInput(e, pojoTmp, data, 'expertDes', false)}}
                                />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "300px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"专家详情"}
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{height: "600px", minWidth: "600px", fontSize: "14px", padding: "10px"}}
                                          key={"expertCt"}
                                          value={data['expertCt']}
                                          maxLength={400}
                                          readOnly={!isEdit}
                                          onChange={(e) => {this.handleInput(e, pojoTmp, data, 'expertCt', false)}}
                                />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "150px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            {"专家关键词"}
                            <span className={"outcome-item-title-tip"}>
                                {"（备注：不需要输入名称、来源、职称、荣誉职称、职务、地理位置、所属机构、毕业院校、教育程度、未来目标、预约价格、领域）"}
                            </span>
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{height: "100px", minWidth: "600px", fontSize: "14px", padding: "10px"}}
                                          key={"keyMap"}
                                          value={this.handleKeyMap(data['keyMap'], data['expertName'])}
                                          maxLength={30}
                                          readOnly={!isEdit}
                                          onChange={(e) => {this.handleInput(e, pojoTmp, data, 'keyMap', false)}}
                                />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // geoFilter调用方法
    setGeo = (geo) => {
        this.setInitGeo(geo, "expertGeo")
    }

    componentDidMount() {
        this.initCheckByArea('expertAreas');
    }

    render() {
        let pojo = this.props.pojo;
        let isAdd = this.props.isAdd;
        let backUrl = this.props.backUrl;

        let pojoTmp = this.state.pojoTmp;
        let isEdit = isAdd ? true : this.state.isEdit;

        let areasTmp = this.state.areasTmp;
        let areasLoad = this.state.areasLoad;

        let pics = this.state.pics;
        let imgSrc = this.state.imgSrc;
        let imgUrl = this.state.imgUrl;

        console.log("缓存pojo：", pojoTmp);
        console.log("imgSrc：", imgSrc);

        return (
            <div className={"page-inner"}>
                {this.loadHeader("expert", backUrl, pojoTmp, isAdd, isEdit, pics, imgSrc, "expertBanner", null, areasTmp, "expertAreas")}
                {this.loadEdit(pojoTmp, isAdd, isEdit, areasTmp, areasLoad, imgUrl, imgSrc)}
            </div>
        );
    }
}

export default ExpertFrame;
