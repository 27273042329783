import React, {Component, Fragment} from "react";
import "../data/data.css";
import "../../tool/tool.css";
import "../component/component.css";
import {
    genProcessBack,
    InitAuth,
    InitAuthPojo, InitExpertDataPojo, initExpertPojoStr, initFindDataPojo,
    initUserSelector,
    jsonToMap, SchTime,
    scrollToAnchor,
    storage
} from "../../tool/Const";
import {
    CheckExpertByUserIdUrl,
    CountAvailableExpertsUrl,
    FindAwaitEditExpertUrl,
    sendGetBack
} from "../../tool/http/Request";
import Search from "../../tool/Search";
import EMPTY from "../../assets/img/record_empty.svg";
import Partitioner from "../../tool/Partitioner";
import UserOperator from "../component/UserOperator";
import ExpertOperator from "../component/ExpertOperator";
import partitioner from "../../tool/Partitioner";
import {ArrowLeftOutlined} from "@ant-design/icons";
import LunchWin from "../../tool/Tools";
import AuthWin from "../../tool/AuthWin";
import ExpertFrame from "../component/ExpertFrame";

const awaitStyle = {
    userId: '20%',
    userReal: '12%',
    userNick: '13%',
    userPhone: '15%',
    userComp: '20%',
    successNum: '10%',
    visitPrice: '10%',
}

const ExpertStatus = [
    {name: "全部", key: 1},
    {name: "待编辑", key: 2},
];

const loadSetting = {
    loadingBatch: 30,
    classes: {
        outer: "data-partition-ct",
    }
}

class ExpertMgr extends Component<null, ExpertState> {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            selected: false,
            appointmentMap: new Map(),
            type: 1,
            appointmentData: '',
        }
    }

    componentDidMount() {
        this.init(1);
        // this.getExpertInfo("0501739161649486438017");
    }

    init = (pageNum) => {
        let hostInfo = storage.get("hostInfo");
        let auth = InitAuthPojo(hostInfo);
        let request = this.initExpertRequest(auth, pageNum);
        let operator = new ExpertOperator();
        this.setInitState(request, operator);
    }

    initExpertRequest = (auth, pageNum) => {
        const request: ExpertRequest = {
            auth: auth,
            pageLimit: loadSetting.loadingBatch,
            pageNum: pageNum,
        }

        return request;
    }

    setInitState = (request, operator) => {
        this.setState({
            request: request,
            operator: operator,
            loaded: true,
            partitioner: {
                totalSize: 0,
                showData: [],
                loadComplete: false,
            },
            type: 1,
        })
    }

    getAwaitEditExpert = () => {
        let params = this.state.request;
        let operator = this.state.operator;

        let _this = this;
        const expertBack: WinBack = {
            callDataBack(data: any) {
                console.log(data);
                let partitioner = {
                    totalSize: 1,
                    showData: data,
                    loadComplete: false,
                }
                _this.setState({
                    loaded: true,
                    partitioner: partitioner,
                    type: 2,
                })
            }
        }

        operator.findAwaitData(genProcessBack(expertBack, true), params);
    }

    // 获取专家信息
    getExpertInfo = (userId) => {
        let params = this.state.request;
        params.userId = userId;
        let operator = this.state.operator;


        let _this = this;
        let list = [];
        const infoBack: WinBack = {
            callDataBack(data: any) {
                console.log(data);
                list.push(data);
                let partitioner = {
                    totalSize: 1,
                    showData: list,
                    loadComplete: false,
                }
                _this.setState({
                    loaded: true,
                    partitioner: partitioner,
                })
            }
        }

        operator.findExpertById(genProcessBack(infoBack, true), params);
    }

    // 已完成审核正等待编辑资料的expert
    loadExpertTable = (showData, request, operator) => {
        return (
            <div className={"page-ct"}>
                <div className={"data-selector"} id={"selector"}>
                    {this.genSelector()}
                </div>
                <div className={"data-ct"}>
                    {this.loadShowData(showData)}
                </div>
                <div className={"data-partition"}
                     style={{display: showData.length > 0 ? "" : "none"}}
                >
                    <Partitioner parent={this} loadSetting={loadSetting}
                                 options={request} sizeFun={operator.findDataSize}
                                 dataFun={operator.findData}/>
                </div>
            </div>
        )
    }

    genSelector = () => {
        let ui = [];
        let options = [];
        ExpertStatus.forEach((t, index) => {
            options.push(
                <option value={t.key} key={index}>
                    {t.name}
                </option>
            )
        });
        ui.push(
            <div className={"log-select"} key={"a"}>
                <span style={{marginRight: "14px"}}>{"专家状态筛选"}</span>
                <select className={"search-input"} style={{cursor: "pointer"}} defaultValue={null}
                        onChange={(e) => {
                            this.setDropDown(e)
                        }}
                >
                    {options}
                </select>
            </div>
        )
        ui.push(
            <div className={"log-select"} key={"expert"}>
                <span style={{marginRight: "14px"}}>{"查找专家ID"}</span>
                <Search onChange={this.setText} onClick={this.searchInfo} inputSize={30} hold={"请输入专家ID"}/>
            </div>
        )
        return ui;
    }

    setDropDown = (e) => {
        let val = e.target.value;
        console.log(val);
        if (Number.parseInt(val) === 1) {
            this.init(1);
        } else {
            this.getAwaitEditExpert();
        }
    }

    setText = (e) => {
        let value = e.target.value;
        if (value) {
            this.setState({
                text: value,
            })
        }
    }

    searchInfo = () => {
        let text = this.state.text;
        if (text) {
            this.getExpertInfo(text);
        }
    }

    loadShowData = (showData) => {
        let len = showData.length;
        let ui = [];
        let list = [];
        if (len > 0) {
            showData.forEach((item, index) => {
               let userId = item.userId;
               let expertDes = item.expertDes;
               let userReal = expertDes.userReal;
               let userNick = expertDes.userNick;
               let userPhone = expertDes.userPhone;
               let userComp = expertDes.userComp;
               let successNum = item.successNum;
               let visitPrice = item.visitPrice;
                list.push(
                   <div className={"data-item"} key={item + index}
                        style={{marginBottom: index === (len - 1) ? "0" : ""}}
                        onClick={() => {
                            this.setSelectInfo(item)
                        }}
                   >
                       {this.loadLayout(awaitStyle, userId, userReal, userNick, userPhone, userComp, successNum, visitPrice)}
                   </div>
               )
           })
        } else {
            list.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        ui.push(
            <Fragment>
                <div className={"data-header"}>
                    {this.loadLayout(awaitStyle, "专家ID", "真实姓名", "用户昵称", "用户手机", "所在单位", "已完成次数", "预约价格")}
                </div>
                <div className={"data-form"}>
                    {list}
                </div>
            </Fragment>
        )
        return ui;
    }

    loadLayout = (style, userId, userReal, userNick, userPhone, userComp, successNum, visitPrice) => {
        return (
            <Fragment>
                <div className={"table-line-item"} style={{width: style.userId}} id={"line-ct:userId"} key={"userId"}>
                    {userId}
                </div>
                <div className={"table-line-item"} style={{width: style.userReal}} id={"line-ct:userReal"} key={"userReal"}>
                    {userReal}
                </div>
                <div className={"table-line-item"} style={{width: style.userNick}} id={"line-ct:userNick"} key={"userNick"}>
                    {userNick}
                </div>
                <div className={"table-line-item"} style={{width: style.userPhone}} id={"line-ct:userPhone"} key={"userPhone"}>
                    {userPhone}
                </div>
                <div className={"table-line-item"} style={{width: style.userComp}} id={"line-ct:userComp"} key={"userComp"}>
                    {userComp}
                </div>
                <div className={"table-line-item"} style={{width: style.successNum}} id={"line-ct:successNum"} key={"successNum"}>
                    {successNum}
                </div>
                <div className={"table-line-item"} style={{width: style.visitPrice}} id={"line-ct:visitPrice"} key={"visitPrice"}>
                    {visitPrice}
                </div>
            </Fragment>
        )
    }

    setSelectInfo = (item) => {
        this.setState({
            selected: true,
            selectInfo: item,
        },
            () => {
                this.getExpertAppointsPre(0)
            })
    }

    loadExpertInfoPage = (type, selectInfo, appointmentMap) => {
        console.log(selectInfo)
        if (type === 1) {
            return (
                <div className={"page-inner"}>
                    <div className={"edit-header"} style={{background: 'rgb(238, 238, 238)'}}>
                        <div className={"item-back"} onClick={() => {window.location.href = "/expertMgr"}}>
                            <ArrowLeftOutlined />
                        </div>
                        <span className={"user-header-title"}>{}</span>
                    </div>
                    <div style={{minWidth: "1000px", margin: "20px 0 0", height: "calc(100% - 100px)"}}>
                        <div className={"user-info-outer"}>
                            {this.loadExpertInfo(selectInfo, appointmentMap)}
                        </div>
                    </div>
                </div>
            )
        } else {
            let request = this.state.request;
            let findDataPojo = initFindDataPojo(request.auth);
            findDataPojo.pojoStr = initExpertPojoStr();
            return (
                <ExpertFrame pojo={Object.assign({}, findDataPojo)} isAdd={true} isSub={false} backUrl={"/expertMgr"}/>
            )
        }
    }

    loadExpertInfo = (data, map) => {
        let expertDes = data.expertDes;
        return (
            <Fragment>
                {this.fillDetailLine(<span className={"user-info-big-title"}>{"专家基本信息"}</span>, "t")}
                {this.fillDetailLine([this.genCommTag("专家ID", data.userId, "userId"),
                    this.genCommTag("真实姓名", expertDes.userReal, "userReal"),
                    this.genCommTag("用户昵称", expertDes.userNick, "userNick"),
                    this.genCommTag("用户手机", expertDes.userPhone, "userPhone")], "ct")}
                {/*{this.fillDetailLine(null, "expert-line-one")}*/}
                {this.fillDetailLine(<span className={"user-info-big-title"}>{"专家基本特征"}</span>, "info")}
                {this.fillDetailLine([this.genCommTag("所在单位", expertDes.userComp, "userComp"),
                    this.genCommTag("所属行业", expertDes.userArea ? expertDes.userArea : "待补充", "userArea"),
                    this.genCommTag("担任职务", expertDes.userJob ? expertDes.userJob : "待补充", "userJob")], "info-ct")}
                {/*{this.fillDetailLine(null, "expert-line-two")}*/}
                {this.fillDetailLine(<span className={"user-info-big-title"}>{"专家预约日程"}</span>, "schedule")}
                {this.loadSchedule(data['expertSchedule'])}
                {/*{this.fillDetailLine(null, "expert-line-three")}*/}
                {this.fillDetailLine(<span className={"user-info-big-title"}>{"专家预约详情"}</span>, "appointment")}
                {this.loadAppointment(map)}
            </Fragment>
        )
    }

    fillDetailLine = (items, key) => {
        return (
            <div className={"user-detail-line"} key={key}>
                {items}
            </div>
        )
    }

    genCommTag = (name, val, key) => {
        return (
            <div className={"user-line-item"} key={key} style={{paddingTop: 0, paddingBottom: '10px'}}>
                <span className={"user-detail-title"}>{name}</span>
                <span className={"user-detail-value"}>{val}</span>
            </div>
        )
    }

    loadSchedule = (schedule) => {
        let scheduleMap = jsonToMap(JSON.parse(schedule));
        let monday = scheduleMap.get('1');
        let tuesday = scheduleMap.get('2');
        let wednesday = scheduleMap.get('3');
        let thursday = scheduleMap.get('4');
        let friday = scheduleMap.get('5');
        let saturday = scheduleMap.get('6');
        let sunday = scheduleMap.get('7');
        console.log(monday);
        console.log(this.handleSchedule(monday));
        console.log(this.convertToSch(this.handleSchedule(monday)))
        return (
            <Fragment>
                {this.fillDetailLine([this.genCommTag("星期一", this.handleSchTime(this.convertToSch(this.handleSchedule(monday))), "monday")], "monday-line")}
                {this.fillDetailLine([this.genCommTag("星期二", this.handleSchTime(this.convertToSch(this.handleSchedule(tuesday))), "tuesday")], "tuesday-line")}
                {this.fillDetailLine([this.genCommTag("星期三", this.handleSchTime(this.convertToSch(this.handleSchedule(wednesday))), "wednesday")], "wednesday-line")}
                {this.fillDetailLine([this.genCommTag("星期四", this.handleSchTime(this.convertToSch(this.handleSchedule(thursday))), "thursday")], "thursday-line")}
                {this.fillDetailLine([this.genCommTag("星期五", this.handleSchTime(this.convertToSch(this.handleSchedule(friday))), "friday")], "friday-line")}
                {this.fillDetailLine([this.genCommTag("星期六", this.handleSchTime(this.convertToSch(this.handleSchedule(saturday))), "saturday")], "saturday-line")}
                {this.fillDetailLine([this.genCommTag("星期日", this.handleSchTime(this.convertToSch(this.handleSchedule(sunday))), "sunday")], "sunday-line")}
            </Fragment>
        )
    }

    handleSchedule = (list) => {
        let t;
        let ta;
        let r = [];

        for(let j = 0; j < list.length; j++){
            let v = list[j];
            if(v != null) {
                //console.log(t, v);   // 跟踪调试用
                if (t === v) {
                    ta.push(t);
                    t++;
                    continue;
                }

                ta = [v];
                t = v + 1;
                r.push(ta);
            }
        }
        return r;
    }

    convertToSch = (list) => {
        let r = [];
        if (list.length > 0) {
            list.forEach(item => {
                let len = item.length;
                let startTime = SchTime[item[0]];
                let endTime = SchTime[item[len - 1]];
                let time = startTime + '-' + endTime;
                r.push(time);
            })
        }
        return r;
    }

    handleSchTime = (list) => {
        let ui = [];
        if (list.length > 0) {
            list.forEach(item => {
                ui.push(
                    <div style={{marginRight: '8px'}}>
                        {item}
                    </div>
                )
            })
        } else {
            ui.push(
                <div>
                    {"暂无当天日程设置"}
                </div>
            )
        }
        return ui;
    }

    loadAppointment = (map) => {
        let status = this.state.request.status;
        return (
            <div className={"user-info-table"}>
                <div className={"user-info-table-header"}>
                    <div className={status === 0 ? "user-info-table-header-item-active" : "user-info-table-header-item"} onClick={() => {this.getExpertAppointsPre(0)}}>
                        {"审核流程中"}
                    </div>
                    <div className={status === 1 ? "user-info-table-header-item-active" : "user-info-table-header-item"} onClick={() => {this.getExpertAppointsPre(1)}}>
                        {"预约成功"}
                    </div>
                    <div className={status === 2 ? "user-info-table-header-item-active" : "user-info-table-header-item"} onClick={() => {this.getExpertAppointsPre(2)}}>
                        {"预约失败"}
                    </div>
                    <div className={status === 3 ? "user-info-table-header-item-active" : "user-info-table-header-item"} onClick={() => {this.getExpertAppointsPre(3)}}>
                        {"预约取消"}
                    </div>
                    <div className={status === 4 ? "user-info-table-header-item-active" : "user-info-table-header-item"} onClick={() => {this.getExpertAppointsPre(4)}}>
                        {"预约完成"}
                    </div>
                </div>
                {this.fillTableItem("user-info-table-ct-header", "订单id", "用户id", "日期", "时间", "主题", "用户名", "用户手机")}
                {this.loadAppointmentDetail(map, status)}
            </div>
        )
    }

    getExpertAppointsPre = (status) => {
        let request = this.state.request;
        let selectInfo = this.state.selectInfo;
        let params = {
            auth: request.auth,
            expertId: selectInfo.userId,
            status: status,
        };
        this.setState({
            request: params,
        },
            () => {
                this.getExpertAppointsByStatusFinal();
            })
    }

    // 生成authPojo
    genAuthPojo = (authPojo, angle, angleSec, sec, pass) => {
        authPojo.anglePhone = angle;
        authPojo.secCode = sec;
        authPojo.angleSec = angleSec;
        authPojo.passCode = pass;
        return authPojo;
    }

    getExpertAppointsByStatusFinal = () => {
        let operator = this.state.operator;
        let request = this.state.request;

        console.log("查看专家预约params: ", request);

        let _this = this;
        const appBack: WinBack = {
            callDataBack(data: any) {
                console.log(data);
                let map = _this.state.appointmentMap;
                let status = _this.state.request.status;
                map.set(Number.parseInt(status), data);
                _this.setState({
                    appointmentMap: map,
                    appointmentData: data,
                })
            }
        }

        operator.findExpertAppoints(genProcessBack(appBack, true), request);
    }

    loadAppointmentDetail = () => {
        // let data = map.size > 0 ? map.get(status) : [];
        let data = this.state.appointmentData;
        console.log(data);
        let len = data.length;
        let ui = [];
        if (len > 0) {
            data.forEach((item, index) => {
                let userDes = item.userDes;
                let expertDes = item.expertDes;
                let startTime = SchTime[item.appointTime.split(';')[0]];
                let endTime = SchTime[item.appointTime.split(';')[2]];
                let time = startTime + '-' + endTime;
                ui.push(
                    this.fillTableItem("user-info-table-ct", item.orderId, item.userId, item.appointDate, time, item.appointTitle, userDes.userNick, userDes.userPhone)
                )
            })
        } else {
            ui.push(
                <div className={"user-info-table-ct"}>
                    <div className={"user-info-table-ct-item"}>
                        {"暂无预约数据"}
                    </div>
                </div>
            )
        }
        return ui;
    }

    fillTableItem = (style, orderId, userId, appointDate, appointTime, appointTitle, userName, userPhone) => {
        return (
            <div className={style}>
                <div className={"user-info-table-ct-item app-orderId"}>
                    {orderId}
                </div>
                <div className={"user-info-table-ct-item app-userId"}>
                    {userId}
                </div>
                <div className={"user-info-table-ct-item app-date"}>
                    {appointDate}
                </div>
                <div className={"user-info-table-ct-item app-time"}>
                    {appointTime}
                </div>
                <div className={"user-info-table-ct-item app-title"}>
                    {appointTitle}
                </div>
                <div className={"user-info-table-ct-item app-name"}>
                    {userName}
                </div>
                <div className={"user-info-table-ct-item app-phone"}>
                    {userPhone}
                </div>
            </div>
        )
    }

    render() {
        let loaded = this.state.loaded;
        let partitioner = this.state.partitioner;
        let request = this.state.request;
        let operator = this.state.operator;

        let selected = this.state.selected;
        let selectInfo = this.state.selectInfo;
        let appointmentMap = this.state.appointmentMap;

        let type = this.state.type;

        console.log("request: ", request);
        console.log("partitioner: ", partitioner);
        console.log("appointmentMap: ", appointmentMap);

        return (
            <div className={"page-wrap"}>
                {loaded ? (selected ? this.loadExpertInfoPage(type, selectInfo, appointmentMap) : this.loadExpertTable(partitioner.showData, request, operator)) : null}
            </div>
        );
    }
}

export default ExpertMgr;