import React, {Fragment} from "react";
import OutcomeFrame from "../component/OutcomeFrame";
import {advTypes, HandleItemType, openTypes, pushTypes} from "../../tool/Const";
import DataParent from "./DataParent";
import EMPTY from "../../assets/img/record_empty.svg";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import moment from "moment";

const loadSetting = {
    loadingBatch: 20,
    classes: {
        outer: "data-partition-ct",
    }
}

class DataOutcome extends DataParent {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // 加载数据列表
    loadCt = (partitioner, findDataPojo) => {
        let ui = [];
        let list = [];
        let showData = partitioner.showData;
        let len = showData.length;
        if (len > 0) {
            showData.forEach((item, index) => {
                let isOpen = item['isOpen'];
                list.push(
                    <div className={"data-item"} key={item + index}
                         id={"line-ct:" + index}
                         style={{marginBottom: index === (len - 1) ? "0" : ""}}
                         onClick={(e) => {
                             this.launchOutcomeFrame(e, item, findDataPojo)
                         }}
                    >
                        <div className={"data-item-name"} id={"line-ct:out"}>
                            {item['outcomeName']}
                        </div>
                        <div className={"data-item-id"} id={"line-ct:id"}>
                            {item['outcomeId']}
                        </div>
                        <div className={"data-item-type"} id={"line-ct:type"}>
                            {HandleItemType(item['outcomeType'])}
                        </div>
                        <div className={"data-item-area"} id={"line-ct:area"}>
                            {this.loadArea(item['outcomeAreas'])}
                        </div>
                        <div className={"data-item-date"} id={"line-ct:date"}>
                            {item['relDate']}
                        </div>
                        <div className={"data-item-status"} id={"line-ct:push"}>
                            {item['isPush'] ? <CheckOutlined/> : <CloseOutlined/>}
                        </div>
                        <div className={"data-item-status"} id={"line-ct:ad"}>
                            {item['isAd'] ? <CheckOutlined/> : <CloseOutlined/>}
                        </div>
                        <div className={"data-item-fun"} id={"line-ct:fun"}>
                            <div className={"data-item-fun-open"} style={{background: isOpen ? "#d41a1a" : "#099240"}} id={"line-fun"}>
                                {isOpen ? "下架" : "上架"}
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            list.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        ui.push(
            <Fragment>
                <div className={"data-header"}>
                    <div className={"data-item-name"}>
                        {"成果名称"}
                    </div>
                    <div className={"data-item-id"}>
                        {"ID"}
                    </div>
                    <div className={"data-item-type"}>
                        {"类型"}
                    </div>
                    <div className={"data-item-area"}>
                        {"行业"}
                    </div>
                    <div className={"data-item-date"}>
                        {"发布日期"}
                    </div>
                    <div className={"data-item-status"}>
                        {"推送首页"}
                    </div>
                    <div className={"data-item-status"}>
                        {"推送广告"}
                    </div>
                    <div className={"data-item-fun"}>
                        {"操作"}
                    </div>
                </div>
                <div className={"data-form"}>
                    {list}
                </div>
            </Fragment>
        )
        return ui;
    }

    launchOutcomeFrame = (e, data, dataPojo) => {
        if (e.target.id.includes("line-ct")) {
            let id = data['outcomeId'];
            if (id) {
                window.location.href = "/dataOutcome?id=" + id
            }
        } else {
            this.handleOpenItem(data, dataPojo, "menu", "auth", 4, this.switchDataShowPre);
        }
    }

    // pojoStr初始化
    initPojoStr = () => {
        return {
            clickNum: 0,
            copWay: '',
            futureCt: '',
            isAd: false,
            isOpen: true,
            isPush: false,
            keyMap: '',
            outcomeAreas: '',
            outcomeBanner: '',
            outcomeDes: '',
            outcomeGeo: '',
            outcomeId: '',
            outcomeName: '',
            outcomePrice: '',
            outcomeRoot: '',
            outcomeStage: '',
            outcomeType: '',
            patentNo: '',
            picSrc: '',
            refId: '',
            relDate: moment().format('YYYY-MM-DD'),
            saveNum: 0,
            srcType: '',
            usageCt: '',
        };
    }

    componentDidMount() {
        this.init(null, '1344', loadSetting.loadingBatch);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 判断selectId的变化执行findDataById接口获取id数据
        if (prevState.selectId !== this.state.selectId) {
            // console.log("修改的id：", this.state.selectId);
            this.handleSelectId("dataOutcome");
        }
    }

    render() {
        let findDataPojo = this.state.findDataPojo;
        let partitioner = this.state.partitioner;
        let selectId = this.state.selectId;
        let loaded = this.state.loaded;
        let dataLoaded = this.state.dataLoaded;
        let isAdd = this.state.isAdd;

        let typeList = [
            {ct: pushTypes, key: "push", txt: "推送首页"},
            {ct: advTypes, key: "adv", txt: "推送广告"},
            {ct: openTypes, key: "openType", txt: "发布状态"}
        ];

        let searchList = {
            0: "关键词",
            1: "数据id",
            2: "作者id"
        };

        console.log("outcome pojo 对象：", findDataPojo)

        return (
            <div className={"page-wrap"}>
                {selectId == null ?
                    loaded ?
                        this.genLayout(typeList, searchList, "dataOutcome", this.loadCt(partitioner, findDataPojo), loadSetting, findDataPojo, partitioner)
                        : null
                    :
                    dataLoaded ? <OutcomeFrame pojo={findDataPojo} isAdd={isAdd} isSub={false}/> : null
                }
            </div>
        )
    }

}

export default DataOutcome;