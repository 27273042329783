import React, {Component} from "react";
import {ItemType, LogOpType, RemoveLoginInfo, storage} from "../../tool/Const";
import "../data/data.css"
import "../../tool/tool.css"
import {fail, failReturn, findMsgByError, loginOut} from "../../tool/http/ErrorMgr";
import {findHostLogs, findHostNames, sendGetBack} from "../../tool/http/Request";

class ULog extends Component<null, LogState> {
    constructor(props) {
        super(props);
        this.state = {
            hostInfo: "",
            loaded: false,
            nameList: [],
            selectName: "",
            dataList: [],
        }
    }

    componentDidMount() {
        const host: HostInfo = storage.get("hostInfo");
        host.authPojo = {
            phone: host.hostPhone,
            account: host.hostAccount,
        };

        let level = host.authLevel;
        let hostAccount = host.hostAccount;
        if (level < 2) {
            let lst = [hostAccount]
            let loadBack = this.genLoadBack(lst, host, hostAccount);
            this.findLogs(hostAccount, host.authPojo, loadBack);
        } else {
            this.genNameList(level, hostAccount, host.authPojo, host);
        }
    }

    genLoadBack = (lst, host, hostAccount) => {
        let dom = this;
        const loadBack: WinBack = {
            callDataBack(data: any) {
                dom.setState({
                    hostInfo: host,
                    loaded: true,
                    nameList: lst,
                    selectName: hostAccount,
                    dataList: data,
                })
            }
        }
        return loadBack;
    }

    genNameList = (level, account, auth, host) => {
        let dom = this;
        const getHostNamesBack: DataBack = {
            successBack(res: any) {
                dom.findLogs(account, auth, dom.genLoadBack(res, host, account));
            },

            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }
        sendGetBack(findHostNames, {auth: JSON.stringify(auth), level: host.authLevel}, true, getHostNamesBack);
    }

    loadDataList = (dataList) => {
        let len = dataList.length;
        if (dataList.length === 0) {
            return null;
        }
        let lst = [];
        dataList.forEach((log, i) => {
            let notice = log["opNotice"] ? log["opNotice"] : "无额外操作明细";
            lst.push(<div className={"log-line"} key={i}
                          style={{border: "2px solid rgba(125, 163, 205, 0.4)",
                              margin: i === 0 ? "0 0 8px 0" : i === (len - 1) ? "8px 0 0 0" : "8px 0"}}>
                {this.loadingDataLine(LogOpType[log["opType"]], ItemType[log["itemType"]], log["opItem"], notice, log["opDate"])}
            </div>)
        })
        return lst;
    }

    loadingDataLine = (opTp, itemTp, opItem, opNotice, opDate) => {
        return [
            <div className={"log-line-op-tp"} key={"op-tp"}>
                {opTp}
            </div>,
            <div className={"log-line-item-tp"} key={"item-tp"}>
                {itemTp}
            </div>,
            <div className={"log-line-op-item"} key={"op-item"}>
                {opItem}
            </div>,
            <div className={"log-line-op-date"} key={"op-date"}>
                {opDate}
            </div>,
            <div className={"log-line-op-notice"} key={"op-notice"}>
                {opNotice}
            </div>
        ];
    }

    loadNameSelectors = (names) => {
        let selects = [];
        names.forEach((n) => {
            selects.push(<option value={n} key={n}>
                {n}
            </option>)
        })
        return selects;
    }

    handLogByName = (e, auth) => {
        let val = e.target.value;
        let dom = this;
        const switchBack: WinBack = {
            callDataBack(data: any) {
                dom.setState({
                    dataList: data,
                })
            }
        }
        if (val) {
            this.setState({
                selectName: val,
            }, () => {
                this.findLogs(val, auth, switchBack)
            })
        }
    }

    findLogs = (account, auth, winBack) => {
        const logBack: DataBack = {
            successBack(res: any) {
                winBack.callDataBack(res)
            },

            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        sendGetBack(findHostLogs, {auth: auth, account: account}, true, logBack);
    }

    render() {
        let loaded = this.state.loaded;
        let host = this.state.hostInfo;
        let names = this.state.nameList;
        let selectName = this.state.selectName;
        let dataList = this.state.dataList;
        return (
            <div className={"page-wrap"}>
                {loaded ?
                    <div style={{position: "relative", width: "100%", height: "100%"}}>
                        <div style={{minWidth: "1130px", padding: "10px 30px"}}>
                            <div className={"log-line"} style={{marginBottom: "10px"}}>
                                <div className={"log-select"}>
                                    <span style={{marginRight: "10px"}}>{"已选择管理员账号"}</span>
                                    <select className={"search-input"} key={"name-selector"} style={{width: "220px"}}
                                            defaultValue={selectName} onChange={(e) => {
                                        this.handLogByName(e, host.authPojo)
                                    }}>
                                        {this.loadNameSelectors(names)}
                                    </select>
                                </div>
                            </div>
                            <div className={"log-line"} style={{fontWeight: "bold", background: "#d1e4f8"}}>
                                {this.loadingDataLine("操作类型", "操作对象", "对象明细", "操作明细", "执行日期")}
                            </div>
                        </div>
                        <div style={{minWidth: "1130px", padding: "0 30px", height: "calc(100% - 140px)"}}>
                            <div className={"host-table"}>
                                {this.loadDataList(dataList)}
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        )
    }

}

export default ULog;