import React, {Component} from 'react';
import "./page.css";
import Menu from "./Menu";
import PageRoutes from "../PageRoutes";
import {InitAuth, MenuGroup, RemoveLoginInfo, storage} from "../tool/Const";
import LunchWin from "../tool/Tools";
import Login from "./Login";
import {fail, failReturn, findMsgByError} from "../tool/http/ErrorMgr";
import {refreshHost, sendGetBack, sendGetRequest} from "../tool/http/Request";

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hiddenMenu: false,  // 菜单栏显示/隐藏
            menuIndexGroup: {index: 0, subIndex: 0, select: 0, close: true},  // 菜单状态管理
            hasLogin: false,
            level: 1,
        }
    }

    // 处理菜单点击
    handleClickMenu = (index, menuIndex, hasSub, url, select, menuSubIndex, close, title) => {
        if (title === "高级管理" && this.state.level < 2) {
            fail("您无此操作权限, 请联系高级管理员！")
            return;
        }

        if (!hasSub) {
            if (index !== menuIndex) {
                // 选择一级菜单栏（未含二级菜单）
                window.location.href = url;
            }
            return;
        }
        if (index !== menuIndex) {
            // 选择一级菜单栏（含二级菜单）
            this.setState({
                menuIndexGroup: {index: index, subIndex: 0, select: select, close: false},
            })
        } else {
            // 展开/关闭一级菜单栏（含二级菜单）
            this.setState({
                menuIndexGroup: {index: index, subIndex: menuSubIndex, select: select, close: !close},
            })
        }
    }

    // 处理二级菜单点击
    handleClickSubMenu = (url, subIndex, menuSubIndex) => {
        if (menuSubIndex !== subIndex) {
            window.location.href = url;
        }
    }

    componentDidMount() {
        this.handleUrl();
        this.handleLogin();
    }

    handleLogin = () => {
        let userInfo = storage.get("hostInfo");
        let time = storage.get("infoTime");
        if (userInfo && time) {
            let mill = parseInt(time);
            if (new Date().getTime() - mill > 60000) {
                let dom = this;
                const refreshBack: DataBack = {

                    successBack(res: any) {
                        storage.set("hostInfo", res);
                        storage.set("infoTime", new Date().getTime())
                        dom.setLoadedState(res.authLevel);
                    },

                    failBack(error: any) {
                        RemoveLoginInfo();
                        failReturn(findMsgByError(error), window.location.href)
                    }
                }
                sendGetBack(refreshHost, {auth: InitAuth(userInfo.hostAccount)}, true, refreshBack);
                return;
            }
            this.setLoadedState(userInfo.authLevel);
        } else {
            let id = "menu";
            let winId = "login";
            LunchWin(<Login />, id, winId);
        }
    }

    setLoadedState = (level) => {
        this.setState({
            hasLogin: true,
            level: level,
        })
    }

    // 处理url传参
    handleUrl = () => {
        let url = window.location.href;
        let params = url.split("/");
        let param = params[params.length - 1].split("?")[0];
        if (param) {
            let menuIndexGroup = MenuGroup[param.toLowerCase()];
            this.setState({
                menuIndexGroup: menuIndexGroup,
            })
        }
    }

    render() {
        let hiddenMenu = this.state.hiddenMenu;
        let menuIndexGroup = this.state.menuIndexGroup;
        let hasLogin = this.state.hasLogin;

        return (
            <div className={"layout-wrap"} id={"menu"}>
                <div className={"layout-ct"}>
                    <Menu parent={this} hiddenMenu={hiddenMenu} menuIndexGroup={menuIndexGroup} hasLogin={hasLogin}/>
                    {
                        hasLogin ?
                            <div className={"layout-container"} id={"page"} style={hiddenMenu ? {width:  "calc(100% - 18px)"} : {transitionDelay: "150ms"}}>
                                <PageRoutes/>
                            </div>
                            : null
                    }
                </div>
            </div>
        )
    }
}

export default Page;