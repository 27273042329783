import {
    AcceptSubItemUrl,
    CheckAuthsByUserUrl,
    CheckUserAuthMaterialsUrl,
    CommitAuthUrl,
    FindAwaitAuthListUrl,
    FindSubItemBySubIndexUrl,
    FindSubItemMediasUrl,
    FindSubItemsByStatusUrl,
    genPojo,
    RejectSubItemUrl,
    sendGetBack,
    sendPostBack
} from "../../tool/http/Request";

class CheckOperator {
    findDataByStatus(subItemRequest: SubItemPojo, back: DataBack) {
        sendGetBack(FindSubItemsByStatusUrl, subItemRequest, true, back);
    }

    findDataBySubIndex(subItemRequest: SubItemPojo, back: DataBack) {
        sendGetBack(FindSubItemBySubIndexUrl, subItemRequest, true, back);
    }

    findMedias(subItemRequest: SubItemPojo, back: DataBack) {
        sendGetBack(FindSubItemMediasUrl, subItemRequest, true, back);
    }

    rejectItem(subItemRequest: SubItemPojo, back: DataBack) {
        sendGetBack(RejectSubItemUrl, subItemRequest, true, back);
    }

    acceptItem(subItemRequest: SubItemPojo, back: DataBack) {
        sendGetBack(AcceptSubItemUrl, subItemRequest, true, back);
    }

    findAwaitAuthList(params, back: DataBack) {
        sendGetBack(FindAwaitAuthListUrl, params, true, back);
    }

    checkAuthsByUser(params, back: DataBack) {
        sendGetBack(CheckAuthsByUserUrl, params, true, back);
    }

    checkUserAuthMaterials(params, back: DataBack) {
        sendGetBack(CheckUserAuthMaterialsUrl, params, true, back);
    }

    commitAuth(pojo, back: DataBack) {
        sendPostBack(genPojo(CommitAuthUrl, pojo), true, back);
    }
}

export default CheckOperator;