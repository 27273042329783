import React, {Component} from "react";
import "../data/data.css";
import "../../tool/tool.css";
import "../component/component.css";
import {ArrowLeftOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {
    genAuth,
    genProcessBack,
    InitAuth,
    initUserSelector,
    RemoveLoginInfo,
    scrollToAnchor,
    storage
} from "../../tool/Const";
import Search from "../../tool/Search";
import Partitioner from "../../tool/Partitioner";
import {fail, failReturn, findMsgByError, loginOut, success, successReturn} from "../../tool/http/ErrorMgr";
import UserOperator from "../component/UserOperator";
import {
    checkUserFavor,
    checkUserRefill,
    delayVipBase,
    findUserUnlockItems,
    findVipById,
    genPojo,
    sendGetBack,
    sendPostBack,
    unlockItemForUser
} from "../../tool/http/Request";
import LunchWin from "../../tool/Tools";
import VipDelayHandler from "../component/VipDelayHandler";
import {delWin} from "../../tool/Luncher";
import AuthWin from "../../tool/AuthWin";
import PtcWin from "../component/PtcWin";

const UserCond = [
    {name: "所有注册用户", key: null},
    {name: "VIP用户", key: "vip"},
    {name: "个人认证用户", key: "per"},
    {name: "企业认证用户", key: "comp"},
    {name: "专家认证用户", key: "exp"}
];

const Detail = {
    user: {index: 0, titles: [], state: ""},
    save: {
        index: 1,
        titles: [{name: "科技成果", val: "outcomeIds"}, {name: "科技需求", val: "demandIds"}, {
            name: "科技政策",
            val: "policyIds"
        }, {name: "专家人才", val: "expertIds"}], state: "saveVal"
    },
    unlock: {
        index: 2,
        titles: [{name: "科技需求", val: "demandIds"}, {name: "科技政策", val: "policyIds"}],
        state: "unlockedVal"
    },
    account: {
        index: 3,
        titles: [],
        state: "",
    }
}

const loadSetting = {
    loadingBatch: 30,
    classes: {
        outer: "data-partition-ct",
    }
}

const WinIds = {
    root: "menu",
    phone: "update-phone",
    phoneAuth: "auth-phone",
    vip: "vip",
    vipAuth: "vip-auth",
    perAuth: "per-auth",
    compAuth: "comp-auth",
    pointAuth: "point-auth",
    point: "update-points",
    unlock: "item-unlock",
    unlockAuth: "unlocked-auth",
    ptc: "ptc-adj",
    ptcAuth: "ptc-auth",
}

class UserMgr extends Component<null, UserState> {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            auth: null,
            operator: null,
            userRequest: null,
            partitioner: {
                totalSize: 0,
                showData: [],
                loadComplete: false,
            },
            userInfo: null,
            vip: null,
            selected: false,
            detail: 0,
            demandIds: [],
            outcomeIds: [],
            policyIds: [],
            expertIds: [],
            saveVal: "outcomeIds",
            unlockedVal: "demandIds",
            unlockedId: null,
            account: null,
            ptc: null,
        }
    }

    componentDidMount() {
        let host = storage.get("hostInfo");
        let auth = InitAuth(host.hostAccount);
        auth.phone = host.hostPhone;
        let operator = new UserOperator();
        let request = this.initUserRequest(auth)
        let user = this.getTargetUser();

        if (user) {
            if (user.userVip !== 0) {
                this.initVipInfo(user, auth, operator, request);
            } else {
                this.setInitState(auth, operator, request, user, true, null);
            }
        } else {
            this.setInitState(auth, operator, request, null, false, null);
        }
    }

    initVipInfo = (user, auth, operator, request) => {
        let userId = user.userId;
        let dom = this;

        const vipBack: WinBack = {
            callDataBack(data: any) {
                dom.setInitState(auth, operator, request, user, true, data);
            }
        }
        const failBack: WinBack = {
            callDataBack(data: any) {
                if (data === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(data), window.location.href);
                } else {
                    failReturn(findMsgByError(data), "/userMgr")
                }
            }
        }

        sendGetBack(findVipById, {auth: auth, userId: userId}, true, genProcessBack(vipBack, true, failBack));
    }

    // 209972

    searchUser = (userRequest) => {
        let userId = userRequest.userId
        console.log("in user")
        if (userId) {
            console.log("in user2222")
            const userBack: WinBack = {
                callDataBack(data: any) {
                    storage.set(userId, data);
                    window.location.href = "/userMgr?id=" + userId;
                }
            }

            this.state.operator.findDataById(genProcessBack(userBack, true), userRequest);
        }
    }

    setInitState = (auth, operator, request, user, selected, vip) => {
        this.setState({
            auth: auth,
            operator: operator,
            loaded: true,
            userRequest: request,
            userInfo: user,
            selected: selected,
            vip: vip,
        })
    }

    getTargetUser = () => {
        let url = window.location.href;
        let param = url.split("?id=");
        if (param.length !== 1) {
            let id = param[1];
            return storage.get(id);
        }
        return null;
    }

    initUserRequest = (auth) => {
        const request: UserRequest = {
            authPojo: auth,
            userId: null,
            phone: null,
            demandNum: 0,
            outcomeNum: 0,
            techPoint: 0,
            pageLimit: loadSetting.loadingBatch,
            pageNo: 1,
            selector: initUserSelector(),
        }

        return request;
    }

    setDropDown = (e) => {
        let val = e.target.value;
        console.log(val)
        if (val) {
            let request = this.state.userRequest;
            let selector = initUserSelector();
            selector[val] = 1;
            request.selector = selector;
            this.setState({
                userRequest: Object.assign({}, request),
            }, () => {
                scrollToAnchor(0 + "data")
            })
        }
    }

    setIdInputId = (e) => {
        let value = e.target.value;
        if (value) {
            let userRequest = this.state.userRequest;
            userRequest.userId = value;
            this.setState({
                userRequest: userRequest,
            })
        }
    }

    searchUserPer = () => {
        let userRequest = this.state.userRequest;
        this.searchUser(userRequest)
    }

    loadingSelector = () => {
        let lst = [];
        let options = [];
        UserCond.forEach((t, index) => {
            options.push(
                <option value={t.key} key={index}>
                    {t.name}
                </option>
            )
        })
        lst.push(
            <div className={"log-select"} key={"a"}>
                <span style={{marginRight: "14px"}}>{"用户状态筛选"}</span>
                <select className={"search-input"} style={{cursor: "pointer"}} defaultValue={null}
                        onChange={(e) => {
                            this.setDropDown(e)
                        }}
                >
                    {options}
                </select>
            </div>
        )
        lst.push(
            <div className={"log-select"} key={"b"}>
                <span style={{marginRight: "14px"}}>{"查找用户ID"}</span>
                <Search onChange={this.setIdInputId} onClick={this.searchUserPer} inputSize={22} hold={"请输入用户ID"}/>
            </div>
        )
        return lst;
    }

    loadingDataLine = (userId, phone, vip, coin, per, comp, exp) => {
        return [
            <div className={"user-id-item"} key={"u-account"}>
                {userId}
            </div>,
            <div className={"user-phone-item"} key={"u-phone"}>
                {phone}
            </div>,
            <div className={"user-coin-item"} key={"u-coin"}>
                {coin}
            </div>,
            <div className={"user-check-item"} key={"u-vip"}>
                {vip}
            </div>,
            <div className={"user-check-item"} key={"u-per"}>
                {per}
            </div>,
            <div className={"user-check-item"} key={"u-comp"}>
                {comp}
            </div>,
            <div className={"user-check-item"} key={"u-exp"}>
                {exp}
            </div>,
        ];
    }

    loadingShowData = (showData, request) => {
        let len = showData.length;
        if (len === 0) {
            return null;
        }
        let lst = [];

        showData.forEach((user, i) => {
            let isPer = user["isPer"];
            let isComp = user["isComp"];
            let isExp = user["isExp"];
            let userId = user["userId"];
            lst.push(
                <div className={"log-line-item"} key={i} id={i + "data"}
                     style={{
                         margin: i === 0 ? "0 0 8px 0" : i === (len - 1) ? "8px 0 0 0" : "8px 0"
                     }}
                     onClick={() => {
                         request.userId = userId;
                         this.searchUser(request);
                     }}>
                    {this.loadingDataLine(user["userId"], user["userPhone"], user["userVip"], user["userCoin"],
                        isPer ? <CheckOutlined/> : <CloseOutlined/>, isComp ? <CheckOutlined/> : <CloseOutlined/>,
                        isExp ? <CheckOutlined/> : <CloseOutlined/>)}
                </div>
            )
        })

        return lst;
    }

    loadUsersTable = (showData, request, operator) => {
        return (
            <div style={{position: "relative", width: "100%", height: "100%"}}>
                <div style={{minWidth: "1130px", height: "100px", marginBottom: "8px", padding: "20px 30px 0 30px"}}>
                    <div className={"log-line"} style={{justifyContent: "space-between"}}>
                        {this.loadingSelector()}
                    </div>
                    <div className={"log-line"} style={{fontWeight: "bold", background: "#3c80d4", color: "white"}}>
                        {this.loadingDataLine("用户账号ID", "用户手机", "VIP等级", "湃通币余额", "个人认证", "企业认证", "专家认证")}
                    </div>
                </div>
                <div style={{minWidth: "1130px", padding: "0 30px", height: "calc(100% - 220px)"}}>
                    <div className={"user-table"}>
                        {this.loadingShowData(showData, request)}
                    </div>
                </div>
                <div style={{minWidth: "1130px", height: "80px", display: "flex", alignItems: "center"}}>
                    <Partitioner parent={this} loadSetting={loadSetting}
                                 options={request} sizeFun={operator.findDataSize}
                                 dataFun={operator.findData}/>
                </div>
            </div>
        )
    }

    fillUserDetailLine = (items, key) => {
        return (
            <div className={"user-detail-line"} key={key}>
                {items}
            </div>
        )
    }

    setDays = (e) => {
        let val = parseInt(e.target.value);
        if (val) {
            let vip = this.state.vip;
            vip.days = val;
            this.setState({
                vip: vip,
            })
        }
    }

    setUserRequestValue = (e, tag) => {
        let val = e.target.value;
        if (val) {
            if (tag === "demandNum" || tag === "outcomeNum" || tag === "techPoint") {
                val = parseInt(val);
            }
            let userRequest = this.state.userRequest;
            userRequest[tag] = val;
            this.setState({
                userRequest: userRequest,
            })
        }
    }

    launchPointsWin = () => {
        let win = (
            <div className={"user-points-win"}>
                <div className={"auth-close"}>
                    <CloseOutlined style={{fontSize: "22px", marginRight: "10px"}} onClick={() => {
                        delWin(WinIds.root, WinIds.point)
                    }}/>
                </div>
                <div className={"point-update-win-ct"}>
                    <div style={{height: "50px", display: "flex", justifyContent: "space-between", width: "80%"}}>
                        <div>
                            <span className={"vip-win-des"}>{"提交需求数"}</span>
                            <input className={"search-input"} style={{width: "60px", margin: "0 10px"}} maxLength={6}
                                   onChange={(e) => {
                                       this.setUserRequestValue(e, "demandNum")
                                   }}/>
                        </div>
                        <div>
                            <span className={"vip-win-des"}>{"提交成果数"}</span>
                            <input className={"search-input"} style={{width: "60px", margin: "0 10px"}} maxLength={6}
                                   onChange={(e) => {
                                       this.setUserRequestValue(e, "outcomeNum")
                                   }}/>
                        </div>
                    </div>
                    <div style={{height: "50px", display: "flex", width: "80%", alignItems: "center"}}>
                        <span className={"vip-win-des"}>{"科技贡献基数"}</span>
                        <input className={"search-input"} style={{width: "100px", margin: "0 10px"}} maxLength={11}
                               onChange={(e) => {
                                   this.setUserRequestValue(e, "techPoint")
                               }}/>
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <button className={"search-bt"} onClick={() => {
                            this.handleUpdatePoints()
                        }}>{"确认"}</button>
                    </div>
                </div>
            </div>
        )

        LunchWin(win, WinIds.root, WinIds.point)
    }


    launchPhoneWin = () => {
        let win = (
            <div className={"vip-delay-win"}>
                <div className={"auth-close"}>
                    <CloseOutlined style={{fontSize: "22px", marginRight: "10px"}} onClick={() => {
                        delWin(WinIds.root, WinIds.phone)
                    }}/>
                </div>
                <div className={"vip-delay-win-ct"}>
                    <div style={{height: "60px"}}>
                        <span className={"vip-win-des"}>{"用户手机"}</span>
                        <input className={"search-input"} style={{width: "200px", margin: "0 10px"}} maxLength={11}
                               placeholder={"请输入新号码"}
                               onChange={(e) => {
                                   this.setUserRequestValue(e, "phone")
                               }}/>
                    </div>
                    <div>
                        <button className={"search-bt"} onClick={() => {
                            this.handleUpdatePhone()
                        }}>{"确认"}</button>
                    </div>
                </div>
            </div>
        )

        LunchWin(win, WinIds.root, WinIds.phone)
    }

    setUnlockedId = (e) => {
        let val = e.target.value;
        if (val) {
            this.setState({
                unlockedId: val,
            })
        }
    }

    delUnlockWin = () => {
        delWin(WinIds.root, WinIds.unlock);
        this.setState({
            unlockedId: null,
        })
    }

    launchUnlockedWin = () => {
        let win = (
            <div className={"vip-delay-win"}>
                <div className={"auth-close"}>
                    <CloseOutlined style={{fontSize: "22px", marginRight: "10px"}} onClick={() => {
                        this.delUnlockWin()
                    }}/>
                </div>
                <div className={"vip-delay-win-ct"}>
                    <div style={{height: "60px"}}>
                        <span className={"vip-win-des"}>{"解锁项目ID"}</span>
                        <input className={"search-input"} style={{width: "200px", margin: "0 10px"}} maxLength={25}
                               placeholder={"请输入项目ID"}
                               onChange={(e) => {
                                   this.setUnlockedId(e)
                               }}/>
                    </div>
                    <div>
                        <button className={"search-bt"} onClick={() => {
                            this.handleUnlockItem()
                        }}>{"确认"}</button>
                    </div>
                </div>
            </div>
        )

        LunchWin(win, WinIds.root, WinIds.unlock)
    }

    launchPtcAdjustWin = () => {
        LunchWin(<PtcWin props={{
            root: WinIds.root,
            win: WinIds.ptc,
            ptc: this.setPtc,
            total: this.state.account.account["coinBalance"]
        }}/>, WinIds.root, WinIds.ptc)
    }

    setPtc = (ptc) => {
        this.setState({
            ptc: ptc
        }, () => {
            this.handlePtcChange()
        })
    }

    handlePtcChange = () => {
        delWin(WinIds.root, WinIds.ptc)
        LunchWin(this.genAuthWin(WinIds.ptcAuth, this.sendPtcRequest, 4, this.cleanPtc), WinIds.root, WinIds.ptcAuth);
    }

    cleanPtc = () => {
        this.setState({
            ptc: null,
        })
    }

    sendPtcRequest = (angle, angleSec, sec, pass, myBack) => {
        let auth = genAuth(angle, angleSec, sec, pass, this.state.auth);
        let userId = this.state.userInfo.userId;
        let ptc = this.state.ptc;

        let userRequest = this.state.userRequest;
        userRequest.authPojo = auth;
        userRequest.userId = userId;

        this.setState({
            userRequest: userRequest,
        }, () => {
            sendPostBack(genPojo(ptc.url, {
                amount: ptc.amount,
                userId: userId,
                notice: ptc.notice,
                authPojo: auth
            }), true, genProcessBack(this.updateUserAndBack("湃通币账户更新成功", myBack), false))
        })
    }

    cleanUnlock = () => {
        this.setState({
            unlockedId: null,
        })
    }

    cleanPhone = () => {
        let userRequest = this.state.userRequest;
        userRequest.phone = null;
        this.setState({
            userRequest: userRequest,
        })
    }

    handleUnlockItem = () => {
        let item = this.state.unlockedId;
        if (item) {
            delWin(WinIds.root, WinIds.unlock);
            LunchWin(this.genAuthWin(WinIds.unlockAuth, this.sendUnlockItemRequest, 4, this.cleanUnlock), WinIds.root, WinIds.unlockAuth);
        }
    }

    handleUpdatePhone = () => {
        let userRequest = this.state.userRequest;
        if (userRequest.phone) {
            delWin(WinIds.root, WinIds.phone);
            LunchWin(this.genAuthWin(WinIds.phoneAuth, this.sendPhoneUpdateRequest, 4, this.cleanPhone), WinIds.root, WinIds.phoneAuth);
        }
    }

    sendUnlockItemRequest = (angle, angleSec, sec, pass, myBack) => {
        let auth = genAuth(angle, angleSec, sec, pass, this.state.auth);
        let userId = this.state.userInfo.userId;
        let itemId = this.state.unlockedId;
        sendGetBack(unlockItemForUser, {auth: auth, userId: userId, itemId: itemId}, true,
            genProcessBack(this.unlockBack("解锁项目成功", myBack), false));
    }

    sendPhoneUpdateRequest = (angle, angleSec, sec, pass, myBack) => {
        let auth = genAuth(angle, angleSec, sec, pass, this.state.auth);
        let userRequest = this.state.userRequest;
        userRequest.authPojo = auth;
        userRequest.userId = this.state.userInfo.userId;
        this.state.operator.updateUserPhone(genProcessBack(this.updateUserAndBack("更新用户手机成功", myBack), false), userRequest);
    }

    handleDisPerClick = () => {
        LunchWin(this.genAuthWin(WinIds.perAuth, this.sendDisPerRequest, 4), WinIds.root, WinIds.perAuth);
    }

    handleUpdatePoints = () => {
        let userRequest = this.state.userRequest;
        if (userRequest.demandNum && userRequest.outcomeNum && userRequest.techPoint) {
            delWin(WinIds.root, WinIds.point);
            LunchWin(this.genAuthWin(WinIds.pointAuth, this.sendPointRequest, 4, this.cleanPoints), WinIds.root, WinIds.pointAuth);
        }
    }

    cleanPoints = () => {
        let userRequest = this.state.userRequest;
        userRequest.demandNum = null;
        userRequest.outcomeNum = null;
        userRequest.techPoint = null;
        this.setState({
            userRequest: userRequest,
        })
    }

    sendDisCompRequest = (angle, angleSec, sec, pass, myBack) => {
        let auth = genAuth(angle, angleSec, sec, pass, this.state.auth);
        let userRequest = this.state.userRequest;
        userRequest.authPojo = auth;
        userRequest.userId = this.state.userInfo.userId;
        this.state.operator.disableUserComp(genProcessBack(this.updateUserAndBack("取消用户企业资质成功", myBack), false), userRequest);
    }

    sendPointRequest = (angle, angleSec, sec, pass, myBack) => {
        let auth = genAuth(angle, angleSec, sec, pass, this.state.auth);
        let userRequest = this.state.userRequest;
        userRequest.authPojo = auth;
        userRequest.userId = this.state.userInfo.userId;
        this.state.operator.updatePoint(genProcessBack(this.updateUserAndBack("更新用户贡献成功", myBack), false), userRequest);
    }

    sendDisPerRequest = (angle, angleSec, sec, pass, myBack) => {
        let auth = genAuth(angle, angleSec, sec, pass, this.state.auth);
        let userRequest = this.state.userRequest;
        userRequest.authPojo = auth;
        userRequest.userId = this.state.userInfo.userId;

        const back: WinBack = {
            callDataBack(data: any) {
                if (data === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(data), window.location.href);
                } else if (data === "OP_ERROR") {
                    fail("请先取消用户的专家资质")
                } else {
                    fail(findMsgByError(data))
                }
            }
        }

        this.state.operator.disableUserPer(genProcessBack(this.updateUserAndBack("取消用户个人认证成功", myBack), false, back), userRequest);
    }

    handleDisCompClick = () => {
        LunchWin(this.genAuthWin(WinIds.compAuth, this.sendDisCompRequest, 4), WinIds.root, WinIds.compAuth);
    }

    handleDisExpClick = () => {
        return fail("请至专家管理页面解除专家资质")
    }

    handleDelay = (winId) => {
        let vip = this.state.vip;
        if (vip["days"] && vip["days"] !== 0) {
            delWin(WinIds.root, winId);
            LunchWin(this.genAuthWin(WinIds.vipAuth, this.sendVipDelayRequest, 3, this.cleanDelay), WinIds.root, WinIds.vipAuth)
        }
    }

    cleanDelay = () => {
        let vip = this.state.vip;
        vip["days"] = 0;
        this.setState({
            vip: vip,
        })
    }

    sendVipDelayRequest = (angle, angleSec, sec, pass, myBack) => {
        let auth = genAuth(angle, angleSec, sec, pass, this.state.auth);
        sendGetBack(delayVipBase, {auth: auth, vip: this.state.vip}, true,
            genProcessBack(this.genSuccessReturnBack("延长/赠予 VIP 成功", myBack), false));
    }

    unlockBack = (msg, myBack) => {
        let dom = this;
        const back: WinBack = {
            callBack() {
                myBack.callBack();
                success(msg);
                dom.unlockClick()
            }
        }

        return back;
    }

    updateUserAndBack = (msg, myBack) => {
        let dom = this;
        const back: WinBack = {
            callBack() {
                myBack.callBack();
                success(msg);
                dom.searchUser(dom.state.userRequest)
            }
        }

        return back;
    }

    genSuccessReturnBack = (msg, myBack) => {
        const back: WinBack = {
            callBack() {
                myBack.callBack();
                successReturn(msg, window.location.href);
            }
        }

        return back;
    }

    genAuthWin = (winId, authAfter, level, cleanFun) => {
        let auth = this.state.auth;
        let id = "menu";
        return <AuthWin level={level} parentId={id} phone={auth.phone} winId={winId}
                        backFun={authAfter} account={auth.account} cleanFun={cleanFun}/>
    }

    lunchVip = () => {
        LunchWin(<VipDelayHandler winId={"vip"} setDays={this.setDays}
                                  handleDelay={this.handleDelay}/>, WinIds.root, WinIds.vip)
    }

    genBaseLine = (nick, vip, coin) => {
        let lst = [];
        lst.push(this.genUserCommTag("用户昵称", nick, "nick"));
        if (vip === 0) {
            lst.push(this.genUserCommTag("VIP等级", "未开通", "vip"))
        } else {
            let toTime = this.state.vip.toTime;
            lst.push(this.genUserOpTag("VIP等级", vip + " （ 至/于 " + toTime + " 到期 )", "延长 / 激活", "vip", this.lunchVip));
        }
        lst.push(this.genUserOpTag("湃通币余额", coin, "湃通币管理", "ptc-account", this.clickPtc));
        return lst
    }

    genUserOpTag = (name, val, bt, key, click) => {
        return (
            <div className={"user-line-item"} key={key}>
                <span className={"user-detail-title"}>{name}</span>
                <span className={"user-detail-value"}>{val}</span>
                <button className={"user-button"} onClick={() => {
                    click()
                }}>{bt}</button>
            </div>
        )
    }

    genUserCommTag = (name, val, key) => {
        return (
            <div className={"user-line-item"} key={key}>
                <span className={"user-detail-title"}>{name}</span>
                <span className={"user-detail-value"}>{val}</span>
            </div>
        )
    }

    genContactLine = (phone, email, aliId, wechatId) => {
        let ct = [];
        ct.push(this.genUserOpTag("用户手机", phone, "更正", "phone", this.launchPhoneWin));
        ct.push(this.genUserCommTag("用户邮箱", email ? email : "未绑定", "email"));
        ct.push(this.genUserCommTag("微信认证", wechatId ? "已绑定" : "未绑定", "wechat"));
        ct.push(this.genUserCommTag("支付宝认证", aliId ? "已绑定" : "未绑定", "ali"));
        return ct
    }

    genTimeLine = (reg, login) => {
        let ct = [];
        ct.push(this.genUserCommTag("账号注册日期", reg, "reg"));
        ct.push(this.genUserCommTag("最近登录时间", login, "login"));
        return ct
    }

    genUserDesLine = (comp, area, job, target) => {
        let not = "待补充";
        let ct = [];
        ct.push(this.genUserCommTag("所在单位", comp ? comp : not, "cp"));
        ct.push(this.genUserCommTag("所属行业", area ? area : not, "area"));
        ct.push(this.genUserCommTag("担任职务", job ? job : not, "job"));
        ct.push(this.genUserCommTag("感兴趣领域", target ? target : not, "target"));
        return ct
    }

    genUserAccountLine = (summary) => {
        let ct = [];
        ct.push(this.genUserCommTag("湃通币余额", summary["coinBalance"], "bal"));
        ct.push(this.genUserCommTag("冻结数量", summary["lockAmount"], "lok"));
        ct.push(this.genUserCommTag("充值总数", summary["historyTotal"], "his"));
        return ct
    }

    genAuthLine = (isPer, realName, isComp, userComp, isExp) => {
        let ct = [];
        let perName = "个人认证";
        let compName = "企业认证";
        let expName = "专家认证";
        let perKey = "per";
        let compKey = "comp";
        let expKey = "exp"
        let cancel = "取消资格";
        let not = "未认证";
        ct.push(isPer ? this.genUserOpTag(perName, realName, cancel, perKey, this.handleDisPerClick) : this.genUserCommTag(perName, not, perKey));
        ct.push(isComp ? this.genUserOpTag(compName, userComp, cancel, compKey, this.handleDisCompClick) : this.genUserCommTag(compName, not, compKey));
        ct.push(isExp ? this.genUserOpTag(expName, realName, cancel, expKey, this.handleDisExpClick) : this.genUserCommTag(expName, not, expKey));
        return ct;
    }

    genStaticLine = (demand, outcome, tech) => {
        let ct = [];
        ct.push(this.genUserCommTag("提交需求数", demand, "demand"));
        ct.push(this.genUserCommTag("提交成果数", outcome, "outcome"));
        ct.push(this.genUserCommTag("科技贡献基数", tech, "tech"));
        return ct
    }

    genDataLookingLine = () => {
        let ct = [];
        ct.push(this.genUserOpTag("用户收藏栏目", "", "点击查看收藏数据", "save", this.saveClick));
        ct.push(this.genUserOpTag("用户解锁栏目", "", "点击查看解锁数据", "unlock", this.unlockClick));
        return ct
    }

    loadItemLst = (data) => {
        let lst = data.split(";");
        let len = lst.length;
        return lst.slice(0, len - 1);
    }

    clickPtc = () => {
        let dom = this;
        const accountBack: WinBack = {
            callDataBack(data: any) {
                dom.setState({
                    account: data,
                    detail: Detail.account.index,
                })
            }
        }

        sendGetBack(checkUserRefill, {
            auth: this.state.auth,
            userId: this.state.userInfo.userId
        }, true, genProcessBack(accountBack, true));
    }

    saveClick = () => {
        let dom = this;
        const saveBack: WinBack = {
            callDataBack(data: any) {
                let outcome = data.outcomeIds;
                let expert = data.expertIds;
                let policy = data.policyIds;
                let demand = data.demandIds;
                if (!outcome && !expert && !policy && !demand) {
                    fail("用户没有任何收藏")
                } else {
                    let outcomes = outcome ? dom.loadItemLst(outcome) : [];
                    let experts = expert ? dom.loadItemLst(expert) : [];
                    let policies = policy ? dom.loadItemLst(policy) : [];
                    let demands = demand ? dom.loadItemLst(demand) : [];
                    dom.setState({
                        outcomeIds: outcomes,
                        expertIds: experts,
                        policyIds: policies,
                        demandIds: demands,
                        detail: Detail.save.index,
                    })
                }
            }
        }

        sendGetBack(checkUserFavor, {
            auth: this.state.auth,
            userId: this.state.userInfo.userId
        }, true, genProcessBack(saveBack, true))
    }

    unlockClick = () => {
        let dom = this;
        const unlockBack: WinBack = {
            callDataBack(data: any) {
                if (!data || data.length === 0) {
                    fail("用户没有解锁相关数据")
                } else {
                    console.log(data)
                    let demands = [];
                    let policies = []
                    data.forEach((item) => {
                        let id = item.itemId;
                        let index = id.substr(0, 4);
                        if (index === "3478") {
                            policies.push(id + ";" + item["unlockTime"])
                        } else {
                            demands.push(id + ";" + item["unlockTime"])
                        }
                    })
                    dom.setState({
                        policyIds: policies,
                        demandIds: demands,
                        detail: Detail.unlock.index,
                    })
                }
            }
        }

        sendGetBack(findUserUnlockItems, {auth: this.state.auth, userId: this.state.userInfo.userId},
            true, genProcessBack(unlockBack, true))
    }

    genSaveItemLine = (index, id) => {
        return (
            [
                <div className={"id-index"} key={"s-index"}>
                    {index}
                </div>,
                <div className={"id-item"} key={"s-id"}>
                    {id}
                </div>
            ]
        )
    }

    genUnlockItemLine = (index, id, time) => {
        return (
            [
                <div className={"id-index"} key={"id-i"}>
                    {index}
                </div>,
                <div className={"id-item"} key={"id-d"}>
                    {id}
                </div>,
                <div className={"id-item"} key={"id-t"}>
                    {time}
                </div>
            ]
        )
    }

    handleIdChange = (e, tag) => {
        let val = e.target.value;
        if (val) {
            let state = this.state;
            state[tag] = val;
            this.setState(state)
        }
    }

    genIdSelector = (detail, val) => {
        let titles = detail.titles;
        let ops = [];
        titles.forEach((item, index) => {
            ops.push(
                <option value={item.val} key={index}>
                    {item.name}
                </option>
            )
        })

        return (
            <select className={"search-input"} style={{cursor: "pointer", marginLeft: "10px"}} defaultValue={val}
                    key={"se"} onChange={(e) => {
                this.handleIdChange(e, detail.state)
            }}>
                {ops}
            </select>
        )
    }

    loadSaveIdTable = (key) => {
        let state = this.state;
        let selected = state[key];
        let ids = state[selected];
        let lst = [];
        ids.forEach((id, index) => {
            lst.push(
                this.fillUserDetailLine(this.genSaveItemLine(index + 1, id))
            )
        })
        return lst;
    }

    loadUnlockIdTable = (key) => {
        let state = this.state;
        let selected = state[key];
        let ids = state[selected];
        let lst = [];
        ids.forEach((id, index) => {
            let items = id.split(";")
            lst.push(
                this.fillUserDetailLine(this.genUnlockItemLine(index + 1, items[0], items[1]), index)
            )
        })
        return lst;
    }

    loadUserCurrencySeries = (series) => {
        let lines = [];
        series.forEach((item, index) => {
            let amount = item["amount"];
            let extra = item["extra"];
            lines.push(
                this.fillUserDetailLine(this.genCurrencyLine(item["orderId"], amount, extra, amount + extra, item["subTime"]), index)
            )
        })
        return lines;
    }

    genCurrencyLine = (order, amount, extra, total, time) => {
        return (
            [
                <div className={"currency-item"} style={{width: "320px"}} key={"id-or"}>
                    {order}
                </div>,
                <div className={"currency-item"} key={"id-am"}>
                    {amount}
                </div>,
                <div className={"currency-item"} key={"id-ex"}>
                    {extra}
                </div>,
                <div className={"currency-item"} key={"id-tot"}>
                    {total}
                </div>,
                <div className={"id-item"} key={"id-time"}>
                    {time}
                </div>
            ]
        )
    }

    loadDetailByIndex = (index, userInfo) => {
        switch (index) {
            case Detail.user.index:
                return (
                    [
                        this.fillUserDetailLine(<span className={"user-info-big-title"}>{"账号基本信息"}</span>, "base-t"),
                        this.fillUserDetailLine(this.genBaseLine(userInfo.userNick, userInfo.userVip, userInfo.userCoin), "base-info"),
                        this.fillUserDetailLine(this.genContactLine(userInfo.userPhone, userInfo.userEmail, userInfo.aliId, userInfo.wechatId), "cont-info"),
                        this.fillUserDetailLine(this.genTimeLine(userInfo.regDate, userInfo.lastDate), "date-info"),
                        this.fillUserDetailLine(null, "date-s"),
                        this.fillUserDetailLine(<span className={"user-info-big-title"}>{"资质认证相关"}</span>, "auth-t"),
                        this.fillUserDetailLine(this.genAuthLine(userInfo.isPer, userInfo.userReal, userInfo.isComp, userInfo.userComp, userInfo.isExp), "auth-info"),
                        this.fillUserDetailLine(null, "auth-s"),
                        this.fillUserDetailLine(<span className={"user-info-big-title"}>{"用户基本特征"}</span>, "ch-t"),
                        this.fillUserDetailLine(this.genUserDesLine(userInfo.userComp, userInfo.userArea, userInfo.userJob, userInfo.userTarget), "ch-info"),
                        this.fillUserDetailLine(null, "ch-s"),
                        this.fillUserDetailLine(
                            <div>
                                <span className={"user-info-big-title"}>{"用户贡献"}</span>
                                <button className={"user-button"} onClick={() => {
                                    this.launchPointsWin()
                                }}>{"编辑"}</button>
                            </div>,
                            "con-t"
                        ),
                        this.fillUserDetailLine(this.genStaticLine(userInfo.demandNum, userInfo.outcomeNum, userInfo.techPoint), "con-info"),
                        this.fillUserDetailLine(null, "con-s"),
                        this.fillUserDetailLine(<span className={"user-info-big-title"}>{"用户行为轨迹"}</span>, "act-t"),
                        this.fillUserDetailLine(this.genDataLookingLine(), "act-info"),
                        this.fillUserDetailLine(null, "act-s"),
                    ]
                )

            case Detail.save.index:

                return (
                    [
                        this.fillUserDetailLine(
                            <div>
                                <span className={"user-info-big-title"}>{"用户收藏列表"}</span>
                                {this.genIdSelector(Detail.save, this.state.saveVal)}
                            </div>,
                            "save-title"
                        ),
                        <div style={{height: "60px", display: "flex"}} key={"save-title"}>
                            {this.fillUserDetailLine(
                                this.genSaveItemLine("序号", "ID号"), "st")
                            }
                        </div>,
                        <div style={{height: "calc(100% - 140px)", overflowY: "auto"}} key={"save-table"}>
                            {this.loadSaveIdTable(Detail.save.state)}
                        </div>
                    ]
                )

            case Detail.unlock.index:
                return (
                    [
                        this.fillUserDetailLine(
                            <div>
                                <span className={"user-info-big-title"}>{"用户解锁列表"}</span>
                                {this.genIdSelector(Detail.unlock, this.state.unlockedVal)}
                                <button className={"user-button"} style={{marginLeft: "40px"}} onClick={() => {
                                    this.launchUnlockedWin()
                                }}>{"添加解锁栏目"}</button>
                            </div>,
                            "unlock-title"
                        ),
                        <div style={{height: "60px", display: "flex"}} key={"unlock-title-line"}>
                            {this.fillUserDetailLine(
                                this.genUnlockItemLine("序号", "ID号", "解锁时间"), "st")
                            }
                        </div>,
                        <div style={{height: "calc(100% - 140px)", overflowY: "auto"}} key={"unlock-table"}>
                            {this.loadUnlockIdTable(Detail.unlock.state)}
                        </div>
                    ]
                )

            case Detail.account.index:
                let account = this.state.account;
                let series = account["series"];
                let hasSeries = series && series.length > 0;

                let data = [
                    this.fillUserDetailLine(
                        <div style={{display: "flex"}}>
                            <span className={"user-info-big-title"}>{"用户湃通币账号信息"}</span>
                            <button className={"user-button"} style={{marginLeft: "40px"}} onClick={() => {
                                this.launchPtcAdjustWin()
                            }}>{"金额调整"}</button>
                        </div>,
                        "account-title"
                    ),
                    this.fillUserDetailLine(
                        this.genUserAccountLine(account["account"]), "account-summary"
                    ),
                ]

                if (hasSeries) {
                    data.push(
                        <div style={{height: "60px", display: "flex", marginTop: "20px"}} key={"account-title-line"}>
                            {this.fillUserDetailLine(
                                this.genCurrencyLine("订单号", "充值数量", "赠送数量", "到账总数", "到账时间"), "account-table-title")
                            }
                        </div>,
                        <div style={{height: "calc(100% - 200px)", overflowY: "auto"}} key={"series-table"}>
                            {this.loadUserCurrencySeries(series)}
                        </div>
                    )
                } else {
                    data.push(this.fillUserDetailLine(<span
                        style={{fontSize: "14px", fontWeight: "bold", color: "grey"}}>{"暂无充值记录"}</span>, "no-record"))
                }

                return data;

            default:
                return null;
        }
    }

    handleHeaderClick = (detail) => {
        if (detail === Detail.user.index) {
            window.location.href = "/userMgr";
        } else {
            this.setState({
                detail: Detail.user.index,
            })
        }
    }

    loadSingleUserPage = (userInfo) => {
        let detail = this.state.detail;
        return (
            <div style={{position: "relative", width: "100%", height: "100%"}}>
                <div style={{minWidth: "1000px", height: "60px", marginBottom: "18px", padding: "20px 30px 0 30px"}}>
                    <div className={"log-line"} style={{background: "#eeeeee", display: "flex", alignItems: "center"}}>
                        <div className={"item-back"} onClick={() => {
                            this.handleHeaderClick(detail)
                        }}>
                            <ArrowLeftOutlined/>
                        </div>
                        <span className={"user-header-title"}>{"用户ID: " + userInfo.userId}</span>
                    </div>
                </div>
                <div style={{
                    minWidth: "1000px",
                    margin: "0 30px",
                    height: "calc(100% - 120px)",
                }}>
                    <div className={"user-info-outer"}>
                        {this.loadDetailByIndex(detail, userInfo)}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let loaded = this.state.loaded;
        let operator = this.state.operator;
        let request = this.state.userRequest;
        let showData = this.state.partitioner.showData;
        let selected = this.state.selected;
        let userInfo = this.state.userInfo;
        console.log(this.state.userInfo)
        return <div className={"page-wrap"}>
            {loaded ? (selected ? this.loadSingleUserPage(userInfo) : this.loadUsersTable(showData, request, operator)) : null}
        </div>;
    }
}

export default UserMgr;