import React, {Fragment} from "react";
import "./data.css";
import DataParent from "./DataParent";
import {advTypes, pushTypes} from "../../tool/Const";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import EMPTY from "../../assets/img/record_empty.svg";
import NewsFrame from "../component/NewsFrame";
import moment from "moment";

const loadSetting = {
    loadingBatch: 20,
    classes: {
        outer: "data-partition-ct",
    }
}

class DataNews extends DataParent {
    constructor(props) {
        super(props);
        this.state = {
            isAdd: false,
        }
    }

    loadCt = (partitioner, findDataPojo) => {
        let ui = [];
        let list = [];
        let showData = partitioner.showData;
        let len = showData.length;
        if (len > 0) {
            showData.forEach((item, index) => {
                let name = item['newsName'];
                let id = item['newsId'];
                let root = item['newsRoot'];
                let date = item['newsDate'];
                let push = item['isPush'] ? <CheckOutlined/> : <CloseOutlined/>;
                let adv = item['isAd'] ? <CheckOutlined/> : <CloseOutlined/>;
                let focus = item['isFocus'] ? <CheckOutlined/> : <CloseOutlined/>;
                let fun = <div className={"data-item-fun-open"} style={{background: "#d41a1a"}} id={"line-fun"}>
                    {"删除"}
                </div>;
                list.push(
                    <div className={"data-item"} key={item + index}
                         style={{marginBottom: index === (len - 1) ? "0" : ""}}
                         id={"line-ct:" + index}
                         onClick={(e) => {
                             this.launchNewsFrame(e, item, findDataPojo)
                         }}
                    >
                        {this.loadLayout(name, id, root, date, push, adv, focus, fun)}
                    </div>
                )
            })
        } else {
            list.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        ui.push(
            <Fragment>
                <div className={"data-header"}>
                    {this.loadLayout("资讯名称", "ID", "发布者", "发布日期", "推送首页", "推送广告", "精华推荐", "操作")}
                </div>
                <div className={"data-form"}>
                    {list}
                </div>
            </Fragment>
        )
        return ui;
    }

    loadLayout = (name, id, root, date, push, adv, focus, fun) => {
        return (
            <Fragment>
                <div className={"data-item-name"} style={{width: '33%'}} id={"line-ct-name"}>
                    {name}
                </div>
                <div className={"data-item-id"} id={"line-ct-id"}>
                    {id}
                </div>
                <div className={"data-item-type"} style={{width: '18%'}} id={"line-ct-type"}>
                    {root}
                </div>
                <div className={"data-item-date"} id={"line-ct-date"}>
                    {date}
                </div>
                <div className={"data-item-status"} id={"line-ct-push"}>
                    {push}
                </div>
                <div className={"data-item-status"} id={"line-ct-adv"}>
                    {adv}
                </div>
                <div className={"data-item-status"} id={"line-ct-focus"}>
                    {focus}
                </div>
                <div className={"data-item-fun"} id={"line-ct-fun"}>
                    {fun}
                </div>
            </Fragment>
        )
    }

    launchNewsFrame = (e, data, dataPojo) => {
        if (e.target.id.includes("line-ct")) {
            let id = data['newsId'];
            if (id) {
                window.location.href = "/dataNews?id=" + id
            }
        } else {
            this.handleOpenItem(data, dataPojo, "menu", "auth", 4, this.delDataPre)
        }
    }

    // pojoStr初始化
    initPojoStr = () => {
        return {
            bigData: '',
            clickNum: '',
            isAd: false,
            isFocus: false,
            isPush: false,
            keyMap: '',
            newsBanner: '',
            newsDate: moment().format('YYYY-MM-DD'),
            newsDes: '',
            newsId: '',
            newsName: '',
            newsRoot: '',
            saveNum: '',
            srcType: '',
        };
    }

    componentDidMount() {
        this.init(null, '3566', loadSetting.loadingBatch);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 判断selectId的变化执行findDataById接口获取id数据
        if (prevState.selectId !== this.state.selectId) {
            this.handleSelectId("dataNews");
        }
    }

    render() {
        let findDataPojo = this.state.findDataPojo;
        let partitioner = this.state.partitioner;
        let selectId = this.state.selectId;
        let loaded = this.state.loaded;
        let dataLoaded = this.state.dataLoaded;
        let isAdd = this.state.isAdd;

        let typeList = [
            {ct: pushTypes, key: "push", txt: "推送首页"},
            {ct: advTypes, key: "adv", txt: "推送广告"},
        ];

        let searchList = {
            0: "关键词",
            1: "数据id",
        };

        return (
            <div className={"page-wrap"}>
                {selectId == null ?
                    loaded ?
                        this.genLayout(typeList, searchList, "dataNews", this.loadCt(partitioner, findDataPojo), loadSetting, findDataPojo, partitioner)
                        : null
                    :
                    dataLoaded ? <NewsFrame pojo={Object.assign({}, findDataPojo)} isAdd={isAdd} isSub={false}/> : null
                }
            </div>
        )
    }
}

export default DataNews;