import React from "react";
import ULog from "../page/content/ULog";
import DataOutcome from "../page/data/DataOutcome";
import {fail, failReturn, findMsgByError, findMsgByTip, loginOut, success} from "./http/ErrorMgr";
import {SendAnglePhoneValidUrl, sendGetBack, SendPhoneValidUrl, TempId} from "./http/Request";
import DataPolicy from "../page/data/DataPolicy";
import DataDemand from "../page/data/DataDemand";
import HostMgr from "../page/content/HostMgr";
import DataNews from "../page/data/DataNews";
import DataExpert from "../page/data/DataExpert";
import CheckOutcome from "../page/check/CheckOutcome";
import CheckDemand from "../page/check/CheckDemand";
import VipMgr from "../page/content/VipMgr";
import moment from "moment";
import UserMgr from "../page/content/UserMgr";
import CurrencySummary from "../page/content/CurrencySummary";
import CheckCert from "../page/check/CheckCert";
import OrderExpert from "../page/order/OrderExpert";
import OrderVip from "../page/order/OrderVip";
import OrderUnlock from "../page/order/OrderUnlock";
import OrderPtc from "../page/order/OrderPtc";
import OrderOther from "../page/order/OrderOther";
import ExpertMgr from "../page/content/ExpertMgr";
import StatisticsSummary from "../page/content/StatisticsSummary";

const urlHeader = "http://localhost:3001/";

const LoginRoot = "/login";

// 菜单栏
const menuList = [
    {
        title: "操作日志",
        subTitles: [],
        url: "/",
        ct: <ULog/>,
    },
    {
        title: "审核",
        ct: null,
        subTitles: [
            // {
            //     title: "订单核准",
            //     index: 1,
            //     url: "/checkOrders",
            //     ct: <CheckOrders/>,
            // },
            {
                title: "资质认证",
                index: 1,
                url: "/checkCertification",
                ct: <CheckCert/>,
            },
            {
                title: "科技成果提交",
                index: 2,
                url: "/checkOutcome",
                ct: <CheckOutcome/>,
            },
            {
                title: "技术需求提交",
                index: 3,
                url: "/checkDemand",
                ct: <CheckDemand/>,
            },
        ],
    },
    {
        title: "订单管理",
        ct: null,
        subTitles: [
            {
                title: "专家预约订单",
                index: 1,
                url: "/orderExpert",
                ct: <OrderExpert/>,
            },
            {
                title: "VIP订单",
                index: 2,
                url: "/orderVip",
                ct: <OrderVip/>,
            },
            {
                title: "解锁订单",
                index: 3,
                url: "/orderUnlock",
                ct: <OrderUnlock/>,
            },
            {
                title: "充值湃通币",
                index: 4,
                url: "/orderPtc",
                ct: <OrderPtc/>,
            },
            {
                title: "其他订单",
                index: 5,
                url: "/orderOther",
                ct: <OrderOther/>,
            },
        ],
    },
    {
        title: "数据管理",
        ct: null,
        subTitles: [
            {
                title: "科技成果管理",
                index: 1,
                url: "/dataOutcome",
                ct: <DataOutcome/>,
            },
            {
                title: "技术需求管理",
                index: 2,
                url: "/dataDemand",
                ct: <DataDemand/>,
            },
            {
                title: "专家人才管理",
                index: 3,
                url: "/dataExpert",
                ct: <DataExpert/>,
            },
            {
                title: "科技政策管理",
                index: 4,
                url: "/dataPolicy",
                ct: <DataPolicy/>,
            },
            {
                title: "科技资讯管理",
                index: 5,
                url: "/dataNews",
                ct: <DataNews/>
            },
        ],
    },
    {
        title: "用户管理",
        subTitles: [],
        url: "/userMgr",
        ct: <UserMgr/>,
        // title: "用户管理",
        // ct: null,
        // subTitles: [
        //     {
        //         title: "用户基本信息",
        //         index: 1,
        //         url: "/userInfo",
        //     },
        //     {
        //         title: "用户订单",
        //         index: 2,
        //         url: "/userOrders",
        //     },
        //     {
        //         title: "用户湃通币/流水",
        //         index: 3,
        //         url: "/userDeal",
        //     },
        // ],
    },
    {
        title: "专家管理",
        subTitles: [],
        url: "/expertMgr",
        ct: <ExpertMgr/>,
        // ct: null,
        // subTitles: [
        //     {
        //         title: "专家预约/完成情况",
        //         index: 1,
        //         url: "/expertAppoint",
        //     },
        //     {
        //         title: "专家日程表",
        //         index: 2,
        //         url: "/expertSchedule",
        //     },
        // ],
    },
    // {
    //     title: "静态资源管理",
    //     subTitles: [],
    //     url: "/resource",
    // },
    {
        title: "高级管理",
        ct: null,
        subTitles: [
            {
                title: "客服管理",
                index: 1,
                url: "/mgrService",
                ct: <HostMgr/>
            },
            {
                title: "网站统计数据",
                index: 2,
                url: "/mgrStatistic",
                ct: <StatisticsSummary/>
            },
            {
                title: "ptc流水核准/订单金额核准",
                index: 3,
                url: "/mgrDeal",
                ct: <CurrencySummary/>
            },
            {
                title: "VIP管理",
                index: 4,
                url: "/mgrVip",
                ct: <VipMgr/>
            },
        ],
    },
]

// 菜单参数
const MenuGroup = {
    // checkorders: {index: 1, subIndex: 1, select: 1, close: false},
    checkcertification: {index: 1, subIndex: 1, select: 1, close: false},
    checkoutcome: {index: 1, subIndex: 2, select: 1, close: false},
    checkdemand: {index: 1, subIndex: 3, select: 1, close: false},
    orderexpert: {index: 2, subIndex: 1, select: 2, close: false},
    ordervip: {index: 2, subIndex: 2, select: 2, close: false},
    orderunlock: {index: 2, subIndex: 3, select: 2, close: false},
    orderptc: {index: 2, subIndex: 4, select: 2, close: false},
    orderother: {index: 2, subIndex: 5, select: 2, close: false},
    dataoutcome: {index: 3, subIndex: 1, select: 3, close: false},
    datademand: {index: 3, subIndex: 2, select: 3, close: false},
    dataexpert: {index: 3, subIndex: 3, select: 3, close: false},
    datapolicy: {index: 3, subIndex: 4, select: 3, close: false},
    datanews: {index: 3, subIndex: 5, select: 3, close: false},
    usermgr: {index: 4, subIndex: 0, select: 4, close: false},
    expertmgr: {index: 5, subIndex: 0, select: 5, close: false},
    mgrservice: {index: 6, subIndex: 1, select: 6, close: false},
    mgrstatistic: {index: 6, subIndex: 2, select: 6, close: false},
    mgrdeal: {index: 6, subIndex: 3, select: 6, close: false},
    mgrvip: {index: 6, subIndex: 4, select: 6, close: false},
}

// 正则检验输入内容
const InputTest = {
    userPwd: {test: /^(?!\d+$)[^\u4e00-\u9fa5]{6,20}$/, error: "密码由6-20位英文字母、符号、数字组成，不能为纯数字"},
    userPhone: {
        test: /^((13[0-9])|(14[0-9])|(15[0-9])|(17[0-9])|(18[0-9])|(16[0-9])|(19[0-9])|(92[0-9])|(98[0-9]))\d{8}$/,
        error: "手机号码格式错误"
    },
    userEmail: {test: /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4}){1,50}$/, error: "邮箱格式错误"},
    userNick: {test: /^[\u4e00-\u9fa5_a-zA-Z0-9]{4,10}$/, error: "昵称由4-10位英文字母、中文、下划线组成"},
    code: {test: /^\d{6}$/, error: "验证码为6位数字"},
}

// 手机号正则隐藏中间四位
const regPhone = /^(\d{3})\d{4}(\d{4})$/;

// 计时器
const handleCountDown = (seconds, dom) => {
    let second = seconds;
    const countDown = () => {
        if (second > 0) {
            second--;
            dom.setState({
                secondI: second
            })
        }
        if (second === 0) {
            second = seconds;
            dom.setState({
                secondI: second
            })
            return;
        }
        setTimeout(countDown, 1000);
    };
    setTimeout(countDown, 1000);
};


// 获取验证码发送接口（手机）
const GetVerCode = (pojo, page) => {
    let phone = pojo.hostPhone;
    let account = pojo.hostAccount;
    if (phone && account) {
        let params = {
            phone: pojo.hostPhone,
            tempId: TempId,
            account: pojo.hostAccount,
        };

        const sendPhoneBack: DataBack = {
            successBack(res: any) {
                handleCountDown(30, page);
                success("验证码发送成功，请查收！")
            },
            failBack(error: any) {
                fail(findMsgByError(error));
            }
        }

        sendGetBack(SendPhoneValidUrl, params, true, sendPhoneBack)
    } else {
        // 未填写账号与手机号 错误处理
        // if (!phone) {
        //     fail(findMsgByTip("NO_PHONE"))
        // }
    }
}

const genSendAngleParam = (phone) => {
    return {
        phone: phone,
        tempId: TempId,
    }
}

const genSendParam = (phone, account) => {
    return {
        phone: phone,
        tempId: TempId,
        account: account,
    }
}

// 获取验证码发送接口（天使账号手机）
const GetVerCodeByLevel = (phone, isAngle, account) => {
    if (phone) {
        let params = isAngle ? genSendAngleParam(phone) : genSendParam(phone, account);
        const sendAngleBack: DataBack = {
            successBack(res: any) {
                console.log("发送验证码res: ", res);
                success("验证码发送成功，请查收！")
            },
            failBack(error: any) {
                fail(findMsgByError(error));
            }
        }

        sendGetBack(isAngle ? SendAnglePhoneValidUrl : SendPhoneValidUrl, params, true, sendAngleBack)
    } else {
        // 未填写天使手机号 错误处理
        fail(findMsgByTip("NO_PHONE"))
    }
}

const PayMethod = {
    1: "微信支付",
    2: "支付宝",
    3: "全额湃通币",
    4: "其他方式",
}

// 数据缓存获取移除组件
const storage = {
    set(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value))
    },
    get(key) {
        return JSON.parse(sessionStorage.getItem(key))
    },
    remove(key) {
        sessionStorage.removeItem(key)
    }
}

const RemoveLoginInfo = () => {
    storage.remove("hostInfo");
    storage.remove("infoTime");
}

const InitAuth = (account) => {
    const auth: AuthPojo = {
        account: account,
        phone: null,
        anglePhone: null,
        secCode: null,
        angleSec: null,
        passCode: null,
    }
    return auth;
}

const InitAuthPojo = (pojo) => {
    const auth: AuthPojo = {
        account: pojo.hostAccount,
        phone: pojo.hostPhone,
        anglePhone: null,
        secCode: null,
        angleSec: null,
        passCode: null,
    }
    return auth;
}

const InitSelector = (keyMap, id, refId, push, adv, openType) => {
    const selector: Selector = {
        keyMap: keyMap,
        id: id,
        refId: refId,
        push: push,
        adv: adv,
        openType: openType,
    }
    return selector;
}

const InitData = (pojoStr, index, pageLimit, pageNo, account, keyMap, id, refId, push, adv, openType) => {
    const data: FindDataPojo = {
        pojoStr: pojoStr,
        index: index,
        pageLimit: pageLimit,
        pageNo: pageNo,
        authPojo: InitAuth(account),
        selector: InitSelector(keyMap, id, refId, push, adv, openType),
    }
    return data;
}

const validNum = /(^-?[1-9]([0-9]*)$|^-?[0-9]$)/;

const searchTypes = {0: "关键词", 1: "数据id", 2: "作者id"};

const pushTypes = {3: "全部", 1: "已推送"};

const advTypes = {3: "全部", 1: "已推送"};

const openTypes = {3: "全部", 0: "已下架", 1: "已上架"};

const subItemStatus = {1: "待审核", 2: "已通过", 3: "已驳回"};

const itemTypeLabel = {
    1: "发明专利",
    2: "新型实用专利",
    3: "外观设计专利",
    4: "软件著作权",
}

const itemType = [
    {label: "发明专利", value: 1},
    {label: "新型实用专利", value: 2},
    {label: "外观设计专利", value: 3},
    {label: "软件著作权", value: 4},
];

const expertEdu = [
    {label: "未知", value: 0},
    {label: "职业院校", value: 1},
    {label: "学士学位", value: 2},
    {label: "硕士学位", value: 3},
    {label: "博士学位", value: 4},
    {label: "博士后", value: 5},
];

const itemSrc = [
    {label: "高等院校", value: 1},
    {label: "科研院所", value: 2},
    {label: "企业", value: 3},
];

const expertTitle = [
    {label: '正高级工程师', value: '1'},
    {label: '高级工程师', value: '2'},
    {label: '工程师', value: '3'},
    {label: '正教授', value: '4'},
    {label: '教授', value: '5'},
    {label: '副教授', value: '6'},
    {label: '研究员', value: '7'},
    {label: '副研究员', value: '8'},
    {label: '主任医师', value: '9'},
    {label: '副主任医师', value: '10'},
    {label: '正高级教师', value: '11'},
    {label: '高级教师', value: '12'},
    {label: '主任技师', value: '13'},
    {label: '副主任技师', value: '14'},
    {label: '其他', value: '15'},
];

const itemStage = [
    {label: "研制阶段", value: "研制阶段"},
    {label: "小试阶段", value: "小试阶段"},
    {label: "中试阶段", value: "中试阶段"},
    {label: "量产阶段", value: "量产阶段"},
    {label: "其他", value: "其他"},
];

const itemCop = [
    {label: "技术转让", value: 1},
    {label: "委托开发", value: 2},
    {label: "合作开发", value: 3},
    {label: "专利许可", value: 4},
    {label: "技术入股", value: 5},
    {label: "其他", value: 6},
];

const itemAreas = [
    // "生物科技", "医药和医疗器械", "新材料及其应用", "新一代电子信息", "先进制造与自动化", "现代农业", "航空航天技术",
    // "绿色化工技术", "新能源与高效节能", "核应用技术", "环境保护与资源利用", "现代交通技术", "城市建设和社会发展", "海洋开发",
    // "文化体育与娱乐", "食品饮料", "轻工纺织", "芯片与集成电路", "人工智能与大数据", "其他"
    {label: '生物科技', value: '生物科技', checked: false, disabled: false},
    {label: '医药和医疗器械', value: '医药和医疗器械', checked: false, disabled: false},
    {label: '新材料及其应用', value: '新材料及其应用', checked: false, disabled: false},
    {label: '新一代电子信息', value: '新一代电子信息', checked: false, disabled: false},
    {label: '先进制造与自动化', value: '先进制造与自动化', checked: false, disabled: false},
    {label: '现代农业', value: '现代农业', checked: false, disabled: false},
    {label: '航空航天技术', value: '航空航天技术', checked: false, disabled: false},
    {label: '绿色化工技术', value: '绿色化工技术', checked: false, disabled: false},
    {label: '新能源与高效节能', value: '新能源与高效节能', checked: false, disabled: false},
    {label: '核应用技术', value: '核应用技术', checked: false, disabled: false},
    {label: '环境保护与资源利用', value: '环境保护与资源利用', checked: false, disabled: false},
    {label: '现代交通技术', value: '现代交通技术', checked: false, disabled: false},
    {label: '城市建设和社会发展', value: '城市建设和社会发展', checked: false, disabled: false},
    {label: '海洋开发', value: '海洋开发', checked: false, disabled: false},
    {label: '文化体育与娱乐', value: '文化体育与娱乐', checked: false, disabled: false},
    {label: '食品饮料', value: '食品饮料', checked: false, disabled: false},
    {label: '轻工纺织', value: '轻工纺织', checked: false, disabled: false},
    {label: '芯片与集成电路', value: '芯片与集成电路', checked: false, disabled: false},
    {label: '人工智能与大数据', value: '人工智能与大数据', checked: false, disabled: false},
    {label: '其他', value: '其他', checked: false, disabled: false},
];

const expertAreas = [
    {label: '数学与统计学类', value: '数学与统计学类', checked: false, disabled: false},
    {label: '物理学与力学类', value: '物理学与力学类', checked: false, disabled: false},
    {label: '化学与化工类', value: '化学与化工类', checked: false, disabled: false},
    {label: '地理地质学与矿业类', value: '地理地质学与矿业类', checked: false, disabled: false},
    {label: '水利与海洋科学类', value: '水利与海洋科学类', checked: false, disabled: false},
    {label: '生物医学与生物工程类', value: '生物医学与生物工程类', checked: false, disabled: false},
    {label: '工业工程与机械类', value: '工业工程与机械类', checked: false, disabled: false},
    {label: '仪器与材料类', value: '仪器与材料类', checked: false, disabled: false},
    {label: '能源动力类', value: '能源动力类', checked: false, disabled: false},
    {label: '电子信息与计算机类', value: '电子信息与计算机类', checked: false, disabled: false},
    {label: '电气与自动化类', value: '电气与自动化类', checked: false, disabled: false},
    {label: '土木建筑与测绘类', value: '土木建筑与测绘类', checked: false, disabled: false},
    {label: '轻工与纺织类', value: '轻工与纺织类', checked: false, disabled: false},
    {label: '交通运输与物流管理类', value: '交通运输与物流管理类', checked: false, disabled: false},
    {label: '航空航天与核工程类', value: '航空航天与核工程类', checked: false, disabled: false},
    {label: '农业与林业工程类', value: '农业与林业工程类', checked: false, disabled: false},
    {label: '环境科学与工程类', value: '环境科学与工程类', checked: false, disabled: false},
    {label: '食品科学与工程类', value: '食品科学与工程类', checked: false, disabled: false},
    {label: '安全科学与工程类', value: '安全科学与工程类', checked: false, disabled: false},
    {label: '中医学与制药学类', value: '中医学与制药学类', checked: false, disabled: false},
    {label: '管理科学与工程类类', value: '管理科学与工程类类', checked: false, disabled: false},
    {label: '电子商务类', value: '电子商务类', checked: false, disabled: false},
    {label: '心理学类', value: '心理学类', checked: false, disabled: false},
    {label: '天文学类', value: '天文学类', checked: false, disabled: false},
    {label: '大气科学类', value: '大气科学类', checked: false, disabled: false},
    {label: '其他', value: '其他', checked: false, disabled: false},
]

// 科研成果/技术需求类型type
const HandleItemType = (type) => {
    switch (type) {
        case 1: {
            return "发明专利";
        }
        case 2: {
            return "新型实用专利";
        }
        case 3: {
            return "外观设计专利";
        }
        case 4: {
            return "软件著作权";
        }
        case 5: {
            return "商标注册";
        }
    }
}

// 来源
const HandleSrcType = (srcType) => {
    switch (srcType) {
        case 1: {
            return "高等院校";
        }
        case 2: {
            return "科研院所";
        }
        case 3: {
            return "企业";
        }
    }
}

// 科研成果/技术需求合作方式copWay
const HandleCopWay = (way) => {
    switch (way) {
        case 1: {
            return "技术转让";
        }
        case 2: {
            return "委托开发";
        }
        case 3: {
            return "合作开发";
        }
        case 4: {
            return "专利许可";
        }
        case 5: {
            return "技术入股";
        }
        case 6: {
            return "其他";
        }
    }
}

const HandleExpertEdu = (edu) => {
    switch (edu) {
        case 1: {
            return "职业院校";
        }
        case 2: {
            return "学士学位";
        }
        case 3: {
            return "硕士学位";
        }
        case 4: {
            return "博士学位";
        }
        case 5: {
            return "博士后";
        }
        default: {
            return "未知";
        }
    }
}

// input onChange
const HandleInput = (e, pojo, data, tag, isNum, page) => {
    let val = e.target.value;
    data[tag] = isNum ? Number.parseInt(val) : val;
    page.setState({
        pojoTmp: pojo,
    })
}

const HandleDateInput = (e, pojo, data, tag, type, page) => {
    let val = e.target.value;
    let date = data[tag];
    let year = type === 0 ? val : date.split("-")[0];
    let month = type === 1 ? val : date.split("-")[1];
    let day = type === 2 ? val : date.split("-")[2];
    let newDate = year + "-" + month + "-" + day;
    data[tag] = newDate;
    page.setState({
        pojoTmp: pojo,
    })
}


// 解析年月日
const handleDate = {
    year(data, tag) {
        if (data) {
            return data[tag].split("-")[0];
        }
    },
    month(data, tag) {
        if (data) {
            return data[tag].split("-")[1];
        }
    },
    day(data, tag) {
        if (data) {
            return data[tag].split("-")[2];
        }
    }
}

// 科研成果/技术需求类型type
const HandleExpertTitle = (expertTitle) => {
    switch (expertTitle) {
        case 1: {
            return "正高级工程师";
        }
        case 2: {
            return "高级工程师";
        }
        case 3: {
            return "工程师";
        }
        case 4: {
            return "正教授";
        }
        case 5: {
            return "教授";
        }
        case 6: {
            return "副教授";
        }
        case 7: {
            return "研究员";
        }
        case 8: {
            return "副研究员";
        }
        case 9: {
            return "主任医师";
        }
        case 10: {
            return "副主任医师";
        }
        case 11: {
            return "正高级教师";
        }
        case 12: {
            return "高级教师";
        }
        case 13: {
            return "主任技师";
        }
        case 14: {
            return "副主任技师";
        }
        case 15: {
            return "其他";
        }
    }
}

// 地理位置
const AreaMap = [
    {
        province: '上海',
        cities: ['上海市'],
    },
    {
        province: '北京',
        cities: ['北京市'],
    },
    {
        province: '天津',
        cities: ['天津市'],
    },
    {
        province: '重庆',
        cities: ['重庆市'],
    },
    {
        province: '江苏省',
        cities: ['苏州市', '徐州市', '盐城市', '无锡市', '南京市', '南通市', '连云港市', '常州市', '镇江市', '扬州市', '淮安市', '泰州市', '宿迁市'],
    },
    {
        province: '浙江省',
        cities: ['温州市', '宁波市', '杭州市', '台州市', '嘉兴市', '金华市', '湖州市', '绍兴市', '舟山市', '丽水市', '衢州市'],
    },
    {
        province: '广东省',
        cities: ['东莞市', '广州市', '中山市', '深圳市', '惠州市', '江门市', '珠海市', '汕头市', '佛山市', '湛江市', '河源市',
            '肇庆市', '清远市', '潮州市', '韶关市', '揭阳市', '阳江市', '梅州市', '云浮市', '茂名市', '汕尾市'],
    },
    {
        province: '四川省',
        cities: ['成都市', '绵阳市', '广元市', '达州市', '南充市', '德阳市', '广安市', '阿坝州', '巴中市', '遂宁市', '内江市'
            , '凉山州', '攀枝花市', '乐山市', '自贡市', '泸州市', '雅安市', '宜宾市', '资阳市', '眉山市', '甘孜州'],
    },
    {
        province: '福建省',
        cities: ['漳州市', '厦门市', '泉州市', '福州市', '莆田市', '宁德市', '三明市', '南平市', '龙岩市'],
    },
    {
        province: '山东省',
        cities: ['济南市', '青岛市', '临沂市', '济宁市', '菏泽市', '烟台市', '淄博市', '泰安市', '潍坊市', '日照市', '威海市'
            , '滨州市', '东营市', '聊城市', '德州市', '莱芜市', '枣庄市'],
    },
    {
        province: '辽宁省',
        cities: ['大连市', '沈阳市', '丹东市', '辽阳市', '葫芦岛市', '锦州市', '朝阳市', '营口市', '鞍山市', '抚顺市', '阜新市', '盘锦市', '本溪市', '铁岭市'],
    },
    {
        province: '吉林省',
        cities: ['吉林市', '长春市', '白山市', '延边州', '白城市', '松原市', '辽源市', '通化市', '四平市'],
    },
    {
        province: '安徽省',
        cities: ['芜湖市', '合肥市', '六安市', '宿州市', '阜阳市', '安庆市', '马鞍山市', '蚌埠市', '淮北市', '淮南市', '宣城市'
            , '黄山市', '铜陵市', '亳州市', '池州市', '巢湖市', '滁州市'],
    },
    {
        province: '海南省',
        cities: ['三亚市', '海口市', '琼海市', '文昌市', '东方市', '昌江县', '陵水县', '乐东县', '保亭县', '五指山市', '澄迈县'
            , '万宁市', '儋州市', '临高县', '白沙县', '定安县', '琼中县', '屯昌县'],
    },
    {
        province: '黑龙江省',
        cities: ['齐齐哈尔市', '哈尔滨市', '大庆市', '佳木斯市', '双鸭山市', '牡丹江市', '鸡西市', '黑河市', '绥化市', '鹤岗市', '伊春市', '大兴安岭地区', '七台河市'],
    },
    {
        province: '河北省',
        cities: ['石家庄市', '唐山市', '保定市', '邯郸市', '邢台市', '河北区', '沧州市', '秦皇岛市', '张家口市', '衡水市', '廊坊市', '承德市'],
    },
    {
        province: '河南省',
        cities: ['郑州市', '南阳市', '新乡市', '安阳市', '洛阳市', '信阳市', '平顶山市', '周口市', '商丘市', '开封市', '焦作市'
            , '驻马店市', '濮阳市', '三门峡市', '漯河市', '许昌市', '鹤壁市', '济源市'],
    },
    {
        province: '湖北省',
        cities: ['武汉市', '宜昌市', '襄樊市', '荆州市', '恩施州', '黄冈市', '孝感市', '十堰市', '咸宁市', '黄石市', '仙桃市'
            , '天门市', '随州市', '荆门市', '潜江市', '鄂州市', '神农架林区'],
    },
    {
        province: '山西省',
        cities: ['南昌市', '赣州市', '上饶市', '吉安市', '九江市', '新余市', '抚州市', '宜春市', '景德镇市', '萍乡市', '鹰潭市'],
    },
    {
        province: '陕西省',
        cities: ['西安市', '咸阳市', '宝鸡市', '汉中市', '渭南市', '安康市', '榆林市', '商洛市', '延安市', '铜川市'],
    },
    {
        province: '江西省',
        cities: ['南昌市', '赣州市', '上饶市', '吉安市', '九江市', '新余市', '抚州市', '宜春市', '景德镇市', '萍乡市', '鹰潭市'],
    },
    {
        province: '湖南省',
        cities: ['长沙市', '邵阳市', '常德市', '衡阳市', '株洲市', '湘潭市', '永州市', '岳阳市', '怀化市', '郴州市', '娄底市', '益阳市', '张家界市', '湘西州'],
    },
    {
        province: '云南省',
        cities: ['昆明市', '红河州', '大理州', '文山州', '德宏州', '曲靖市', '昭通市', '楚雄州', '保山市', '玉溪市', '丽江地区'
            , '临沧地区', '思茅地区', '西双版纳州', '怒江州', '迪庆州'],
    },
    {
        province: '贵州省',
        cities: ['贵阳市', '黔东南州', '黔南州', '遵义市', '黔西南州', '毕节地区', '铜仁地区', '安顺市', '六盘水市'],
    },
    {
        province: '甘肃省',
        cities: ['兰州市', '天水市', '庆阳市', '武威市', '酒泉市', '张掖市', '陇南地区', '白银市', '定西地区', '平凉市', '嘉峪关市', '临夏回族自治州', '金昌市', '甘南州'],
    },
    {
        province: '青海省',
        cities: ['西宁市', '海西州', '海东地区', '海北州', '果洛州', '玉树州', '黄南藏族自治州'],
    },
    {
        province: '广西壮族自治区',
        cities: ['贵港市', '玉林市', '北海市', '南宁市', '柳州市', '桂林市', '梧州市', '钦州市', '来宾市', '河池市', '百色市', '贺州市', '崇左市', '防城港市'],
    },
    {
        province: '新疆自治区',
        cities: ['乌鲁木齐市', '伊犁州', '昌吉州', '石河子市', '哈密地区', '阿克苏地区', '巴音郭楞州', '喀什地区', '塔城地区', '克拉玛依市', '和田地区'
            , '阿勒泰州', '吐鲁番地区', '阿拉尔市', '博尔塔拉州', '五家渠市', '克孜勒苏州', '图木舒克市'],
    },
    {
        province: '西藏自治区',
        cities: ['拉萨市', '山南地区', '林芝地区', '日喀则地区', '阿里地区', '昌都地区', '那曲地区'],
    },
    {
        province: '内蒙古自治区',
        cities: ['赤峰市', '包头市', '通辽市', '呼和浩特市', '鄂尔多斯市', '乌海市', '呼伦贝尔市', '兴安盟', '巴彦淖尔盟', '乌兰察布盟', '锡林郭勒盟', '阿拉善盟'],
    },
    {
        province: '宁夏回族自治区',
        cities: ['银川市', '吴忠市', '中卫市', '石嘴山市', '固原市'],
    },
]

const PolicyMap = [
    {
        province: '上海',
        cities: ['市级政策', '黄浦区', '徐汇区', '长宁区', '静安区', '普陀区', '虹口区', '杨浦区', '闵行区', '宝山区', '嘉定区',
            '浦东新区', '金山区', '松江区', '青浦区', '奉贤区', '崇明区'],
    },
    {
        province: '北京',
        cities: ['市级政策', '东城区', '西城区', '崇文区', '宣武区', '朝阳区', '丰台区', '石景山区', '海淀区', '门头沟区', '房山区',
            '通州区', '顺义区', '昌平区', '大兴区', '怀柔区', '平谷区'],
    },
    {
        province: '天津',
        cities: ['市级政策', '和平区', '河东区', '河西区', '南开区', '河北区', '红桥区', '滨海新区', '东丽区', '西青区', '津南区',
            '北辰区', '武清区', '宝坻区', '宁河区', '静海区', '蓟州区'],
    },
    {
        province: '重庆',
        cities: ['市级政策', '万州区', '黔江区', '涪陵区', '渝中区', '大渡口区', '江北区', '沙坪坝区', '九龙坡区', '南岸区', '北碚区',
            '渝北区', '巴南区', '长寿区', '江津区', '合川区', '永川区', '南川区', '綦江区', '大足区', '铜梁区', '璧山区'],
    },
    {
        province: '江苏省',
        cities: ['省级政策', '苏州市', '徐州市', '盐城市', '无锡市', '南京市', '南通市', '连云港市', '常州市', '镇江市', '扬州市', '淮安市', '泰州市', '宿迁市'],
    },
    {
        province: '浙江省',
        cities: ['省级政策', '温州市', '宁波市', '杭州市', '台州市', '嘉兴市', '金华市', '湖州市', '绍兴市', '舟山市', '丽水市', '衢州市'],
    },
    {
        province: '广东省',
        cities: ['省级政策', '东莞市', '广州市', '中山市', '深圳市', '惠州市', '江门市', '珠海市', '汕头市', '佛山市', '湛江市', '河源市',
            '肇庆市', '清远市', '潮州市', '韶关市', '揭阳市', '阳江市', '梅州市', '云浮市', '茂名市', '汕尾市'],
    },
    {
        province: '四川省',
        cities: ['省级政策', '成都市', '绵阳市', '广元市', '达州市', '南充市', '德阳市', '广安市', '阿坝州', '巴中市', '遂宁市', '内江市'
            , '凉山州', '攀枝花市', '乐山市', '自贡市', '泸州市', '雅安市', '宜宾市', '资阳市', '眉山市', '甘孜州'],
    },
    {
        province: '福建省',
        cities: ['省级政策', '漳州市', '厦门市', '泉州市', '福州市', '莆田市', '宁德市', '三明市', '南平市', '龙岩市'],
    },
    {
        province: '山东省',
        cities: ['省级政策', '济南市', '青岛市', '临沂市', '济宁市', '菏泽市', '烟台市', '淄博市', '泰安市', '潍坊市', '日照市', '威海市'
            , '滨州市', '东营市', '聊城市', '德州市', '莱芜市', '枣庄市'],
    },
    {
        province: '辽宁省',
        cities: ['省级政策', '大连市', '沈阳市', '丹东市', '辽阳市', '葫芦岛市', '锦州市', '朝阳市', '营口市', '鞍山市', '抚顺市', '阜新市', '盘锦市', '本溪市', '铁岭市'],
    },
    {
        province: '吉林省',
        cities: ['省级政策', '吉林市', '长春市', '白山市', '延边州', '白城市', '松原市', '辽源市', '通化市', '四平市'],
    },
    {
        province: '安徽省',
        cities: ['省级政策', '芜湖市', '合肥市', '六安市', '宿州市', '阜阳市', '安庆市', '马鞍山市', '蚌埠市', '淮北市', '淮南市', '宣城市'
            , '黄山市', '铜陵市', '亳州市', '池州市', '巢湖市', '滁州市'],
    },
    {
        province: '海南省',
        cities: ['省级政策', '三亚市', '海口市', '琼海市', '文昌市', '东方市', '昌江县', '陵水县', '乐东县', '保亭县', '五指山市', '澄迈县'
            , '万宁市', '儋州市', '临高县', '白沙县', '定安县', '琼中县', '屯昌县'],
    },
    {
        province: '黑龙江省',
        cities: ['省级政策', '齐齐哈尔市', '哈尔滨市', '大庆市', '佳木斯市', '双鸭山市', '牡丹江市', '鸡西市', '黑河市', '绥化市', '鹤岗市', '伊春市', '大兴安岭地区', '七台河市'],
    },
    {
        province: '河北省',
        cities: ['省级政策', '石家庄市', '唐山市', '保定市', '邯郸市', '邢台市', '河北区', '沧州市', '秦皇岛市', '张家口市', '衡水市', '廊坊市', '承德市'],
    },
    {
        province: '河南省',
        cities: ['省级政策', '郑州市', '南阳市', '新乡市', '安阳市', '洛阳市', '信阳市', '平顶山市', '周口市', '商丘市', '开封市', '焦作市'
            , '驻马店市', '濮阳市', '三门峡市', '漯河市', '许昌市', '鹤壁市', '济源市'],
    },
    {
        province: '湖北省',
        cities: ['省级政策', '武汉市', '宜昌市', '襄樊市', '荆州市', '恩施州', '黄冈市', '孝感市', '十堰市', '咸宁市', '黄石市', '仙桃市'
            , '天门市', '随州市', '荆门市', '潜江市', '鄂州市', '神农架林区'],
    },
    {
        province: '山西省',
        cities: ['省级政策', '南昌市', '赣州市', '上饶市', '吉安市', '九江市', '新余市', '抚州市', '宜春市', '景德镇市', '萍乡市', '鹰潭市'],
    },
    {
        province: '陕西省',
        cities: ['省级政策', '西安市', '咸阳市', '宝鸡市', '汉中市', '渭南市', '安康市', '榆林市', '商洛市', '延安市', '铜川市'],
    },
    {
        province: '江西省',
        cities: ['省级政策', '南昌市', '赣州市', '上饶市', '吉安市', '九江市', '新余市', '抚州市', '宜春市', '景德镇市', '萍乡市', '鹰潭市'],
    },
    {
        province: '湖南省',
        cities: ['省级政策', '长沙市', '邵阳市', '常德市', '衡阳市', '株洲市', '湘潭市', '永州市', '岳阳市', '怀化市', '郴州市', '娄底市', '益阳市', '张家界市', '湘西州'],
    },
    {
        province: '云南省',
        cities: ['省级政策', '昆明市', '红河州', '大理州', '文山州', '德宏州', '曲靖市', '昭通市', '楚雄州', '保山市', '玉溪市', '丽江地区'
            , '临沧地区', '思茅地区', '西双版纳州', '怒江州', '迪庆州'],
    },
    {
        province: '贵州省',
        cities: ['省级政策', '贵阳市', '黔东南州', '黔南州', '遵义市', '黔西南州', '毕节地区', '铜仁地区', '安顺市', '六盘水市'],
    },
    {
        province: '甘肃省',
        cities: ['省级政策', '兰州市', '天水市', '庆阳市', '武威市', '酒泉市', '张掖市', '陇南地区', '白银市', '定西地区', '平凉市', '嘉峪关市', '临夏回族自治州', '金昌市', '甘南州'],
    },
    {
        province: '青海省',
        cities: ['省级政策', '西宁市', '海西州', '海东地区', '海北州', '果洛州', '玉树州', '黄南藏族自治州'],
    },
    {
        province: '广西壮族自治区',
        cities: ['省级政策', '贵港市', '玉林市', '北海市', '南宁市', '柳州市', '桂林市', '梧州市', '钦州市', '来宾市', '河池市', '百色市', '贺州市', '崇左市', '防城港市'],
    },
    {
        province: '新疆自治区',
        cities: ['省级政策', '乌鲁木齐市', '伊犁州', '昌吉州', '石河子市', '哈密地区', '阿克苏地区', '巴音郭楞州', '喀什地区', '塔城地区', '克拉玛依市', '和田地区'
            , '阿勒泰州', '吐鲁番地区', '阿拉尔市', '博尔塔拉州', '五家渠市', '克孜勒苏州', '图木舒克市'],
    },
    {
        province: '西藏自治区',
        cities: ['省级政策', '拉萨市', '山南地区', '林芝地区', '日喀则地区', '阿里地区', '昌都地区', '那曲地区'],
    },
    {
        province: '内蒙古自治区',
        cities: ['省级政策', '赤峰市', '包头市', '通辽市', '呼和浩特市', '鄂尔多斯市', '乌海市', '呼伦贝尔市', '兴安盟', '巴彦淖尔盟', '乌兰察布盟', '锡林郭勒盟', '阿拉善盟'],
    },
    {
        province: '宁夏回族自治区',
        cities: ['省级政策', '银川市', '吴忠市', '中卫市', '石嘴山市', '固原市'],
    },
]

// 图片格式list
const picTypes = "image/jpg, image/jpeg, image/png";

const LogOpType = {
    1: "通行许可",
    2: "新增",
    3: "更新",
    4: "无效化操作",
    5: "首页推送",
    6: "广告推送",
    7: "通过",
    8: "驳回",
    9: "创建",
    10: "余额增加",
    11: "余额减少",
    12: "余额锁定",
    13: "余额锁定",
    14: "有效化操作",
    15: "删除",
    16: "取消",
    17: "完成",
    18: "取消首页推送",
    19: "取消广告推送",
}

const ItemType = {
    1: "管理员账号",
    2: "技术需求",
    3: "专家人才",
    4: "科技成果",
    5: "科技资讯",
    6: "科技政策",
    7: "个人认证",
    8: "企业认证",
    9: "专家认证",
    10: "湃通币账户",
    11: "需求提交",
    12: "成果提交",
    13: "专家预约",
    14: "用户账号",
    15: "核心参数",
    16: "订单操作",
}

// map转json
const mapToJson = (map) => {
    let obj= Object.create(null);
    map.forEach((value, key) => {
        obj[key] = value
    })
    return obj;
}

// json转map
const jsonToMap = (json) => {
    let map = new Map();
    for (let k of Object.keys(json)) {
        map.set(k, json[k]);
    }
    return map;
}

const scrollToAnchor = (anchorName) => {
    if (anchorName) {
        let anchorElement = document.getElementById(anchorName);
        if (anchorElement) {
            anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
        }
    }
}

const genProcessBack = (myBack, hasData, failBack = commFail()) => {
    const back: DataBack = {
        successBack(res: any) {
            if (hasData) {
                myBack.callDataBack(res);
            } else {
                myBack.callBack();
            }
        },

        failBack(error: any) {
            failBack.callDataBack(error)
        }
    }
    return back;
}

const commFail = () => {
    const back: WinBack = {
        callDataBack(data: any) {
            if (data === loginOut) {
                RemoveLoginInfo();
                failReturn(findMsgByError(data), window.location.href);
            } else {
                fail(findMsgByError(data))
            }
        }
    }

    return back;
}

const initUserSelector = () => {
    const selector: UserSelector = {
        vip: -1,
        per: -1,
        comp: -1,
        exp: -1,
    }

    return selector;
}

// pojoStr初始化
const initOutcomePojoStr = () => {
    return {
        clickNum: 0,
        copWay: '',
        futureCt: '',
        isAd: false,
        isOpen: true,
        isPush: false,
        keyMap: '',
        outcomeAreas: '',
        outcomeBanner: '',
        outcomeDes: '',
        outcomeGeo: '',
        outcomeId: '',
        outcomeName: '',
        outcomePrice: '',
        outcomeRoot: '',
        outcomeStage: '',
        outcomeType: '',
        patentNo: '',
        picSrc: '',
        refId: '',
        relDate: moment().format('YYYY-MM-DD'),
        saveNum: 0,
        srcType: '',
        usageCt: '',
    };
}

// pojoStr初始化
const initDemandPojoStr = () => {
    return {
        accepterRequest: '',
        clickNum: 0,
        compDate: moment().format('YYYY-MM-DD'),
        copWay: '',
        isAd: false,
        isOpen: true,
        isPush: false,
        demandAreas: '',
        demandBanner: '',
        demandCt: '',
        demandDes: '',
        demandGeo: '',
        demandId: '',
        demandName: '',
        demandPrice: '',
        demandRoot: '',
        demandType: '',
        extraInfo: '',
        keyMap: '',
        refId: '',
        relDate: moment().format('YYYY-MM-DD'),
        saveNum: 0,
        srcType: '',
    };
}

const HandleAuthType = (authType) => {
    switch (authType) {
        case 1: {
            return "个人认证";
        }
        case 2: {
            return "企业认证";
        }
        case 3: {
            return "专家认证";
        }
    }
}

const genAuth = (angle, angleSec, sec, pass, authPojo) => {
    authPojo.anglePhone = angle;
    authPojo.secCode = sec;
    authPojo.angleSec = angleSec;
    authPojo.passCode = pass;
    return authPojo;
}

const PtcRate = 100;

// 预约开放时间节点list
const SchTime = {
    1: '08:00',
    2: '08:30',
    3: '09:00',
    4: '09:30',
    5: '10:00',
    6: '10:30',
    7: '11:00',
    8: '11:30',
    9: '12:00',
    10: '12:30',
    11: '13:00',
    12: '13:30',
    13: '14:00',
    14: '14:30',
    15: '15:00',
    16: '15:30',
    17: '16:00',
    18: '16:30',
    19: '17:00',
    20: '17:30',
    21: '18:00',
    22: '18:30',
    23: '19:00',
    24: '19:30',
    25: '20:00',
}

const initExpertPojoStr = () => {
    return {
        clickNum: null,
        expertAreas: '',
        expertBanner: '',
        expertCt: '',
        expertDes: '',
        expertEdu: '',
        expertGeo: '',
        expertHor: '',
        expertId: '',
        expertJob: '',
        expertName: '',
        expertPrice: '',
        expertRoot: '',
        expertSch: '',
        expertTarget: null,
        expertTitle: null,
        isAd: false,
        isPush: false,
        keyMap: '',
        refId: '',
        saveNum: '',
        srcType: '',
    };
}

const initFindDataPojo = (authPojo) => {
    return {
        pojoStr: null,
        index: null,
        authPojo: authPojo,
        pageLimit: null,
        pageNo: null,
        selector: null,
        // disable: false,
        pics: null,
    }
}

export {
    LoginRoot,
    menuList,
    MenuGroup,
    InputTest,
    regPhone,
    GetVerCode,
    GetVerCodeByLevel,
    storage,
    itemType,
    expertEdu,
    itemSrc,
    expertTitle,
    itemStage,
    itemCop,
    itemAreas,
    expertAreas,
    InitAuth,
    InitAuthPojo,
    InitSelector,
    InitData,
    HandleItemType,
    HandleSrcType,
    HandleCopWay,
    HandleExpertTitle,
    HandleExpertEdu,
    HandleInput,
    HandleDateInput,
    validNum,
    searchTypes,
    pushTypes,
    advTypes,
    openTypes,
    subItemStatus,
    AreaMap,
    PolicyMap,
    picTypes,
    LogOpType,
    ItemType,
    RemoveLoginInfo,
    mapToJson,
    jsonToMap,
    genProcessBack,
    scrollToAnchor,
    initOutcomePojoStr,
    initDemandPojoStr,
    initUserSelector,
    genAuth,
    PayMethod,
    HandleAuthType,
    PtcRate,
    itemTypeLabel,
    SchTime,
    initExpertPojoStr,
    initFindDataPojo,
}