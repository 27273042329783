import React, {Component, Fragment} from "react";
import "./order.css";
import OrderOperator from "./OrderOperator";
import Partitioner from "../../tool/Partitioner";
import {genProcessBack, InitAuthPojo, PayMethod, storage} from "../../tool/Const";
import Search from "../../tool/Search";
import LunchWin from "../../tool/Tools";
import AuthWin from "../../tool/AuthWin";
import RejectWin from "../check/RejectWin";
import {delWin} from "../../tool/Luncher";
import {successReturn} from "../../tool/http/ErrorMgr";
import EMPTY from "../../assets/img/record_empty.svg";

const awaitStyle = {
    orderId: '20%',
    userId: '14%',
    realPrice: '8%',
    rmbPrice: '8%',
    paiPrice: '8%',
    disPrice: '8%',
    method: '8%',
    subTime: '12%',
    fun: '14%',
}

const otherStyle = {
    orderId: '24%',
    userId: '18%',
    realPrice: '9%',
    rmbPrice: '9%',
    paiPrice: '9%',
    disPrice: '9%',
    method: '9%',
    subTime: '13%',
    fun: '0%',
}

const OrderStatus = [
    {name: "待审核", key: 1},
    {name: "成功", key: 2},
    {name: "失败", key: 3},
    {name: "取消", key: 4},
    {name: "待支付ptc", key: 5},
    {name: "管理员", key: 6},
    {name: "未成功", key: 0},
];

export default class OrderParent extends Component<LayoutProps, OrderState> {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            selected: false,
        }
    }

    initDataPojo = (index, status, pageLimit) => {
        this.setState({
            options: this.initOptions(index, status, pageLimit, 1),
            loaded: true,
            partitioner: {
                totalSize: 0,
                showData: [],
                loadComplete: false,
            },
        })
    }

    initOptions = (index, status, pageLimit, pageNo) => {
        let userInfo = storage.get("hostInfo");
        const data: GetOrderPojo = {
            index: index,
            status: status,
            pageLimit: pageLimit,
            pageNo: pageNo,
            auth: InitAuthPojo(userInfo),
        }
        return data;
    }

    init = (index, status, pageLimit) => {
        this.initDataPojo(index, status, pageLimit);
        let url = window.location.href;
        let param = url.split("?id=");
        if (param.length !== 1) {
            let id = param[1];
            this.setState({
                selectId: id,
            })
        }
    }

    // 框架
    genLayout = (ct, loadSetting, options, partitioner) => {
        let operator = new OrderOperator();
        return (
            <div className={"page-ct"}>
                <div className={"data-selector"} id={"selector"}>
                    {this.genSelector()}
                </div>
                <div className={"data-ct"}>
                    {ct}
                </div>
                <div className={"data-partition"}
                     style={{display: partitioner.showData.length > 0 ? "" : "none"}}
                >
                    <Partitioner parent={this} loadSetting={loadSetting}
                                 options={options} sizeFun={operator.checkOrderSize} dataFun={operator.checkOrdersByStatus}/>
                </div>
            </div>
        )
    }

    // 搜索块
    genSelector = () => {
        let selector = this.state.options;
        console.log("genSelector: ", selector)
        let ui = [];
        let options = [];
        OrderStatus.forEach((t, index) => {
            options.push(
                <option value={t.key} key={index}>
                    {t.name}
                </option>
            )
        })
        ui.push(
            <div className={"log-select"} key={"a"}>
                <span style={{marginRight: "14px"}}>{"订单状态筛选"}</span>
                <select className={"search-input"} style={{cursor: "pointer"}}
                        defaultValue={null}
                        onChange={(e) => {
                            this.sendSelector(e, "status")
                        }}
                >
                    {options}
                </select>
            </div>
        )
        ui.push(
            <div className={"log-select"} key={"b"}>
                <span style={{marginRight: "14px"}}>{"查找订单ID"}</span>
                <Search onChange={this.setText} onClick={this.searchInfo} inputSize={30} hold={"请输入订单ID"}/>
            </div>
        )
        return ui;
    }

    // select筛选
    sendSelector = (e, tag) => {
        let val = e.target.value;
        if (val) {
            let options = this.state.options;
            options[tag] = Number.parseInt(val);
            document.getElementById('search').value = '';
            this.setState({
                options: Object.assign({}, options),
            })
        }
    }

    // 搜索文本
    setText = (e) => {
        let val = e.target.value;
        console.log(val);
        this.setState({
            text: val,
        })
    }

    // 执行搜索
    searchInfo = () => {
        let text = this.state.text;
        if (text) {
            console.log("搜索id：", text);

            let options = this.state.options;
            options.orderId = text;

            let operator = new OrderOperator();

            let _this = this;
            const myBack: WinBack = {
                callDataBack(data: any) {
                    let partitioner = _this.state.partitioner;
                    let list = [];
                    list.push(data);
                    partitioner.totalSize = 1;
                    partitioner.showData = list;
                    _this.setState({
                        partitioner: partitioner,
                    })
                }
            }

            operator.checkOrderById(genProcessBack(myBack, true), options)
        }
    }

    loadCt = (partitioner, options, hasFun) => {
        let ui = [];
        let list = [];
        let showData = partitioner.showData;
        let len = showData.length;

        let style = hasFun ? awaitStyle : otherStyle;
        let funH = hasFun ? "操作" : null;

        if (len > 0) {
            showData.forEach((item, index) => {
                let orderId = item['orderId'];
                let userId = item['userId'];
                let realPrice = item['realPrice'];
                let rmbPrice = item['rmbPrice'];
                let paiPrice = item['paiPrice'];
                let disPrice = item['disPrice'];
                let method = PayMethod[item["method"]];
                let subTime = item['subTime'];
                let funT = this.switchFunCt(index, options);
                list.push(
                    <div className={"data-item"} key={item + index}
                         style={{marginBottom: index === (len - 1) ? "0" : ""}}
                         id={"line-ct:" + index}
                    >
                        {this.loadLayout(style, orderId, userId, realPrice, rmbPrice, paiPrice, disPrice, method, subTime, funT)}
                    </div>
                )
            })
        } else {
            list.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        ui.push(
            <Fragment>
                <div className={"data-header"}>
                    {this.loadLayout(style, "订单id", "用户id", "应付金额", "应付人民币", "应付湃通币", "折扣金额", "支付方式", "订单时间", funH)}
                </div>
                <div className={"data-form"}>
                    {list}
                </div>
            </Fragment>
        )
        return ui;
    }

    // 基本布局
    loadLayout = (style, orderId, userId, realPrice, rmbPrice, paiPrice, disPrice, method, subTime, fun) => {
        return (
            <Fragment>
                <div className={"table-line-item"} style={{width: style.orderId}} id={"line-ct:orderId"} key={"orderId"}>
                    {orderId}
                </div>
                <div className={"table-line-item"} style={{width: style.userId}} id={"line-ct:userId"} key={"userId"}>
                    {userId}
                </div>
                <div className={"table-line-item"} style={{width: style.realPrice}} id={"line-ct:realPrice"} key={"realPrice"}>
                    {realPrice}
                </div>
                <div className={"table-line-item"} style={{width: style.rmbPrice}} id={"line-ct:rmbPrice"} key={"rmbPrice"}>
                    {rmbPrice}
                </div>
                <div className={"table-line-item"} style={{width: style.paiPrice}} id={"line-ct:paiPrice"} key={"paiPrice"}>
                    {paiPrice}
                </div>
                <div className={"table-line-item"} style={{width: style.disPrice}} id={"line-ct:disPrice"} key={"disPrice"}>
                    {disPrice}
                </div>
                <div className={"table-line-item"} style={{width: style.method}} id={"line-ct:method"} key={"method"}>
                    {method}
                </div>
                <div className={"table-line-item"} style={{width: style.subTime}} id={"line-ct:subTime"} key={"subTime"}>
                    {subTime}
                </div>
                {
                    fun ?
                        <div className={"table-line-item"} id={"line-ct:fun"} style={{width: style.fun}} key={"fun"}>
                            {fun}
                        </div>
                        : null
                }
            </Fragment>
        )
    }

    // 确认订单状态
    confirmOrder = (status, index, options) => {
        let partitioner = this.state.partitioner;
        let orderPojo = partitioner.showData[index];
        let pojo = this.initRequestPojo(options.index, status, orderPojo, "", options.auth);
        this.setState({
                requestPojo: pojo,
            },
            () => {
                this.launchWin(status);
            });
    }

    launchWin = (status) => {
        if (status === 3) {
            let pojo = this.state.requestPojo;
            LunchWin(<RejectWin parentId={"menu"} winId={"reject"} level={3}
                                pojo={pojo} id={pojo.orderPojo.orderId}
                                tag={"notice"}
                                handleFun={this.processRejectItem}
                                submitFun={this.submitRejectItem}
            />, "menu", "reject");
        } else {
            this.launchAuthWin("menu", "order", 3, this.confirmOrderPre);
        }
    }

    initRequestPojo = (index, status, orderPojo, notice, auth) => {
        const pojo: OrderRequestPojo = {
            index: index,
            status: status,
            orderPojo: orderPojo,
            notice: notice,
            authPojo: auth,
        }
        return pojo;
    }

    launchAuthWin = (id, winId, level, backFun) => {
        let userInfo = storage.get("hostInfo");
        let phone = userInfo.hostPhone;
        LunchWin(<AuthWin level={level} parentId={id} phone={phone} winId={winId} backFun={backFun} account={userInfo.hostAccount}/>, id, winId);
    }

    confirmOrderPre = (angle, angleSec, sec, pass, myBack) => {
        let pojo = this.state.requestPojo;
        pojo.authPojo = this.genAuthPojo(pojo.authPojo, angle, angleSec, sec, pass);
        this.setState({
            requestPojo: pojo,
        },
            () => {
                myBack.callBack();
                this.confirmOrderFinal();
            })
    }

    // 生成authPojo
    genAuthPojo = (authPojo, angle, angleSec, sec, pass) => {
        authPojo.anglePhone = angle;
        authPojo.secCode = sec;
        authPojo.angleSec = angleSec;
        authPojo.passCode = pass;
        return authPojo;
    }

    // 确认订单状态
    confirmOrderFinal = () => {
        let operator = new OrderOperator();

        let requestPojo = this.state.requestPojo;
        let status = requestPojo.status;
        let msg = status === 2 ? "订单已通过" : "订单已取消";
        const myBack: WinBack = {
            callBack() {
                successReturn(msg, window.location.href);
            }
        }

        operator.confirmOrder(genProcessBack(myBack, false), this.state.requestPojo);
    }

    // 驳回确认
    submitRejectItem = (parentId, winId, level, pojo) => {
        let notice = pojo.notice;
        if (notice) {
            delWin(parentId, winId);
            let winIdN = "order-reject";
            let host = pojo.authPojo;
            LunchWin(<AuthWin level={level} parentId={parentId} winId={winIdN}
                              account={host.account} phone={host.phone}
                              backFun={this.processRejectItem} />, parentId, winIdN);
        }
    }

    // 驳回最终步骤
    processRejectItem = (angle, angleSec, sec, pass, myBack) => {
        let pojo = this.state.requestPojo;
        this.genAuthPojo(pojo.authPojo, angle, angleSec, sec, pass);
        console.log("reject pojo: ", pojo);

        let operator = new OrderOperator();

        let _this = this;
        const rejectBack: WinBack = {
            callBack() {
                myBack.callBack();
                successReturn("订单已驳回", window.location.href);
            }
        }

        operator.confirmOrder(genProcessBack(rejectBack, false), pojo);
    }
}