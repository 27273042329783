import React, {Component} from "react";
import "./page.css";
import {CaretDownOutlined, CaretRightFilled, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {menuList, RemoveLoginInfo, storage} from "../tool/Const";
import {error, HostLogoutUrl, sendGetRequest} from "../tool/http/Request";
import {connectionError, fail, findMsgByError} from "../tool/http/ErrorMgr";
import LunchWin from "../tool/Tools";
import PassUpdater from "./component/PassUpdater";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // 加载左侧菜单栏
    loadingFunMenu = (menuIndexGroup) => {
        let menuIndex = menuIndexGroup.index;
        let menuSubIndex = menuIndexGroup.subIndex;
        let select = menuIndexGroup.select;
        let close = menuIndexGroup.close;
        let tags = [];
        menuList.forEach((item, index) => {
            let subMenu = item.subTitles;
            let title = item.title;
            let subTitles = [];
            let subLen = subMenu.length;
            let hasSub = subLen > 0;
            let subHgt = parseInt((subLen * 46) + "") + "px"
            if (hasSub) {
                subMenu.forEach((itemSub, indexSub) => {
                    subTitles.push(
                        <div className={"layout-menu-item-sub"}
                             key={itemSub + indexSub}
                             style={menuIndex === index ? menuSubIndex === itemSub.index ? {
                                 background: "rgba(97, 184, 251, 0.2)",
                                 fontWeight: "bold"
                             } : {} : {opacity: "0"}}
                             onClick={() => {
                                 this.props.parent.handleClickSubMenu(itemSub.url, itemSub.index, menuSubIndex, menuIndexGroup)
                             }}>
                            <span>{itemSub.title}</span>
                        </div>
                    )
                })
            }
            tags.push(
                [
                    <div className={"layout-menu-item"}
                         key={item + index}
                         style={select === index ? {background: hasSub ? "" : "rgba(165, 206, 255, 0.3)", fontWeight: "bold"} : {}}
                         onClick={() => {
                             this.props.parent.handleClickMenu(index, menuIndex, hasSub, item.url, select, menuSubIndex, close, title)
                         }}
                    >
                        <span>
                            {item.title}
                        </span>
                        {hasSub ? menuIndex === index && !close ?
                            <CaretDownOutlined style={{paddingLeft: "50px", marginRight: "20px"}}/> :
                            <CaretRightFilled style={{paddingLeft: "50px", marginRight: "20px"}}/> : null}
                    </div>,
                    <div className={"layout-menu-sub-item"}
                         key={index}
                         style={menuIndex === index && !close ? {height: subHgt} : {height: "0", width: "0", overflow: "hidden", opacity: "0"}}
                    >
                        {subTitles}
                    </div>
                ]
            )
        })
        return tags;
    }
    
    // 登出
    logout = () => {
        let userInfo = storage.get("hostInfo");
        let params = {
            auth: {account: userInfo.hostAccount},
            account: userInfo.hostAccount,
        };
        sendGetRequest(HostLogoutUrl, params, true).then(res => {
            console.log("登出res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    RemoveLoginInfo();
                } else {
                    fail(findMsgByError(res[error]));
                }
                window.location.reload();
            }
        })
    }

    componentDidMount() {

    }

    render() {
        let hiddenMenu = this.props.hiddenMenu;
        let menuIndexGroup = this.props.menuIndexGroup;
        let hasLogin = this.props.hasLogin;

        return (
            <div className={"layout-menu"} style={{width: hiddenMenu ? "18px" : ""}} id={"menu-page"}>
                <div className={"layout-menu-ct"}
                     style={hiddenMenu ? {opacity: "0"} : {transitionDelay: "150ms"}}
                >
                    <div className={"layout-menu-title"}>
                        <span>{"功能控制台"}</span>
                        {
                            hasLogin ?
                                <div className={"logout-item"}
                                     onClick={() => {
                                         this.logout()
                                     }}
                                >
                                    {"登出"}
                                </div>
                                : null
                        }
                    </div>
                    <div className={"change-pass-line"}>
                        <div className={"change-pass"}
                             onClick={() => {
                                 LunchWin(<PassUpdater parentId={"menu"} winId={"pass"}/>, "menu", "pass")
                             }}
                        >
                            {"修改登录密码"}
                        </div>
                    </div>
                    <div className={"layout-menu-items-wrap"}>
                        {this.loadingFunMenu(menuIndexGroup)}

                        <div style={{width: "100%", height: "40px"}}/>
                    </div>
                </div>
                <div className={"layout-menu-btn-wrap"}>
                    <div className={"layout-menu-btn"}
                         onClick={() => {
                             this.props.parent.setState({hiddenMenu: !hiddenMenu})
                         }}
                    >
                        {
                            hiddenMenu ?
                                <RightOutlined style={{transform: "translateX(-21px)"}}/>
                                :
                                <LeftOutlined style={{transform: "translateX(-21px)"}}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Menu;