import React from "react";
import "./order.css";
import OrderParent from "./OrderParent";

const loadSetting = {
    loadingBatch: 20,
    classes: {
        outer: "data-partition-ct",
    }
}

class OrderOther extends OrderParent {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // 0=AWAIT;1=PAID;2=SUCCESS;3=FAIL;4=CANCEL;5=MEED_COIN;6=AUTH
    switchFunCt = (index, options) => {
        switch (options.status) {
            case 0: {
                return (
                    <div className={"data-item-fun-open"} style={{background: "#dcbf40"}} id={"line-fun-cancel"}
                         onClick={() => {this.confirmOrder(4, index, options)}}
                    >
                        {"取消"}
                    </div>
                )
            }
            case 1: {
                return (
                    <div className={"data-item-fun-open"} style={{background: "#dcbf40"}} id={"line-fun-cancel"}
                         onClick={() => {this.confirmOrder(4, index, options)}}
                    >
                        {"取消"}
                    </div>
                )
            }
            case 2: return null;
            case 3: return null;
            case 4: return null;
            case 5: return null;
            case 6: return null;
            default: return null;
        }
    }

    componentDidMount() {
        this.init(5, 1, loadSetting.loadingBatch);
    }

    render() {
        let options = this.state.options;
        let partitioner = this.state.partitioner;
        let loaded = this.state.loaded;

        let hasFun = loaded ? (options.status === 0 || options.status === 1) : false;

        return (
            <div className={"page-wrap"}>
                {
                    loaded ?
                        this.genLayout(this.loadCt(partitioner, options, hasFun), loadSetting, options, partitioner)
                        : null
                }
            </div>
        );
    }
}

export default OrderOther;