import React, {Fragment} from "react";
import "./check.css";
import CheckParent from "./CheckParent";
import {HandleItemType, initDemandPojoStr, initOutcomePojoStr, subItemStatus} from "../../tool/Const";
import DemandFrame from "../component/DemandFrame";
import EMPTY from "../../assets/img/record_empty.svg";

export default class CheckDemand extends CheckParent {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    loadCt = (data) => {
        let ui = [];
        let list = [];
        let len = data.length;
        if (len > 0) {
            data.forEach((item, index) => {
                let name = item['name'];
                let userId = item['userId'];
                let id = item['subIndex'];
                let type = HandleItemType(item['srcType']);
                let area = this.loadArea(item['areas']);
                let date = item['subTime'];
                list.push(
                    <div className={"data-item"} key={item + index}
                         id={"line-ct:" + index}
                         onClick={(e) => {
                             this.launchDemandFrame(e, item)
                         }}
                    >
                        {this.loadLayout(name, userId, id, type, area, date)}
                    </div>
                )
            })
        } else {
            list.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        ui.push(
            <div>
                <div className={"data-header"}>
                    {this.loadLayout("需求名称", "用户ID", "ID", "类型", "行业", "提交日期")}
                </div>
                {list}
            </div>
        )
        return ui;
    }

    launchDemandFrame = (e, data) => {
        if (e.target.id.includes("line-ct")) {
            let status = Number.parseInt(data['subStatus']);
            let id = data['subIndex'];
            if (id) {
                this.setState({
                        selectId: id,
                        status: status,
                    },
                    () => {
                        window.location.href = "/checkDemand?id=" + id
                    })
            }
        }
    }

    genDemandPojo = (subItemPojo) => {
        let pojo = initDemandPojoStr();
        let subPojo = subItemPojo.pojoStr;
        pojo.accepterRequest = subPojo['accepterRequest'];
        pojo.demandAreas = subPojo['areas'];
        pojo.copWay = subPojo['copWay'];
        pojo.demandCt = subPojo['demandCt'];
        pojo.demandPrice = subPojo['demandPrice'];
        pojo.demandType = subPojo['demandType'];
        pojo.demandDes = subPojo['des'];
        pojo.extraInfo = subPojo['extraInfo'];
        pojo.demandGeo = subPojo['geo'];
        pojo.demandName = subPojo['name'];
        pojo.demandRoot = subPojo['root'];
        pojo.srcType = subPojo['srcType'];
        pojo.relDate = subPojo['subTime'].split(" ")[0];
        pojo.refId = subPojo['userId'];
        pojo.status = subPojo['subStatus'];
        subItemPojo.pojoStr = pojo;
        return subItemPojo;
    }

    componentDidMount() {
        this.init("9919", 1);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 判断selectId的变化执行findDataById接口获取id数据
        if (prevState.selectId !== this.state.selectId) {
            this.handleSelectId("checkDemand");
        }
    }

    render() {
        let subItemPojo = this.state.subItemPojo;
        let data = this.state.data;
        let loaded = this.state.loaded;
        let selectId = this.state.selectId;
        let dataLoaded = this.state.dataLoaded;

        console.log(subItemPojo);

        let typeList = [
            {ct: subItemStatus, key: "status", txt: "状态"}
        ];

        let searchList = {
            1: "数据id",
        };

        return (
            <div className={"page-wrap"}>
                {selectId == null ?
                    <div>
                        {loaded ?
                            this.genLayout(typeList, searchList, "checkDemand", this.loadCt(data))
                            : null
                        }
                    </div>
                    :
                    dataLoaded ? <DemandFrame pojo={this.genDemandPojo(subItemPojo)} isAdd={false} setPojo={null} isSub={true}/> : null
                }
            </div>
        );
    }
}