import React, {Component} from "react";
import "../data/data.css";
import Search from "../../tool/Search";
import {
    genAuth,
    genProcessBack, itemCop, itemSrc,
    itemType,
    itemTypeLabel,
    RemoveLoginInfo,
    scrollToAnchor,
    storage
} from "../../tool/Const";
import {fail, failReturn, findMsgByError, loginOut, successReturn} from "../../tool/http/ErrorMgr";
import {
    addNewVip,
    delayVip,
    findUserSubscript,
    findVipById,
    findVipList,
    sendGetBack,
    terminateVip
} from "../../tool/http/Request";
import {ArrowLeftOutlined, CloseOutlined, PlusOutlined} from "@ant-design/icons";
import LunchWin from "../../tool/Tools";
import AuthWin from "../../tool/AuthWin";
import {delWin} from "../../tool/Luncher";
import "../../tool/tool.css";
import VipDelayHandler from "../component/VipDelayHandler";

const VipCond = [{name: "有效时间内", valid: true}, {name: "已过期", valid: false}];

class VipMgr extends Component<null, VipState> {
    constructor(props) {
        super(props);
        this.state = {
            hostInfo: "",
            loaded: false,
            vipData: [],
            opVip: "",
            inputId: "",
            personal: false,
            valid: true,
            processRequest: "",
            openAdd: false,
            openSubscript: false,
            subscript: null,
        }
    }

    componentDidMount() {
        let host = storage.get("hostInfo");
        let level = host.authLevel;
        if (level < 2) {
            failReturn("您的权限不能查看此页面", "/")
            return;
        }
        let hostPhone = host.hostPhone;
        host.authPojo = {
            phone: hostPhone,
            account: host.hostAccount,
        };

        let dom = this;
        const successBack: WinBack = {
            callDataBack(res) {
                let lst = dom.loadDataList(res);
                dom.setState({
                    hostInfo: host,
                    vipData: lst,
                    loaded: true,
                    opVip: {
                        userId: "",
                        days: 0,
                        vipLevel: 1,
                        toTime: "",
                    }
                })
            }
        }

        sendGetBack(findVipList, {
            valid: this.state.valid,
            auth: host.authPojo
        }, true, this.genVipBack(host, successBack));
    }

    genVipBack = (host, dataBack) => {
        console.log(dataBack)
        const vipBack: DataBack = {
            successBack(res: any) {
                dataBack.callDataBack(res)
            },

            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        return vipBack;
    }

    callCondUpdate = () => {
        let dom = this;
        let host = this.state.hostInfo;
        let successBack: WinBack = {
            callDataBack(res) {
                let lst = dom.loadDataList(res);
                dom.setState({
                    vipData: lst,
                    personal: false,
                })
            }
        }

        sendGetBack(findVipList, {
            valid: this.state.valid,
            auth: host.authPojo,
        }, true, this.genVipBack(host, successBack));
    }

    processTaskBack = (msg, back) => {
        const successBack: WinBack = {
            callBack() {
                back.callBack();
                successReturn(msg, window.location.href)
            }
        }
        return successBack
    }

    launchRequest = (vipPojo, url, successMsg, element, winId) => {
        let id = "menu";
        this.setState({
            opVip: vipPojo,
            processRequest: {url: url, successMsg: successMsg},
        }, () => {
            LunchWin(element, id, winId)
        })
    }

    processTask = (angle, angleSec, sec, pass, myBack) => {
        let auth = genAuth(angle, angleSec, sec, pass, this.state.hostInfo.authPojo);
        let vipPojo = this.state.opVip;
        let request = this.state.processRequest;
        sendGetBack(request.url, {
            vip: vipPojo,
            auth: auth
        }, true, genProcessBack(this.processTaskBack(request.successMsg, myBack), false))
    }

    genAuthWin = (winId) => {
        let host = this.state.hostInfo;
        let id = "menu";
        return <AuthWin level={2} parentId={id} phone={host.hostPhone} winId={winId}
                        backFun={this.processTask} account={host.hostAccount}/>
    }

    setDays = (e) => {
        let val = parseInt(e.target.value);
        if (val) {
            let vip = this.state.opVip;
            vip.days = val;
            this.setState({
                opVip: vip,
            })
        }
    }

    handleDelay = (winId) => {
        let vip = this.state.opVip;
        if (vip["days"] && vip["days"] !== 0) {
            delWin("menu", winId);
            LunchWin(this.genAuthWin("vip-auth"), "menu", "vip-auth")
        }
    }

    openSubscript = (userId) => {
        let dom = this;
        let subscriptBack: WinBack = {
            callDataBack(data: any) {
                dom.setState({
                    openSubscript: true,
                    subscript: data,
                })
            }
        }

        let failWin: WinBack = {
            callDataBack(data: any) {
                if (data === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(data), window.location.href);
                } else {
                    if (data === "NOT_MATCH_ERROR") {
                        fail("用户未使用订阅功能")
                    } else {
                        fail(findMsgByError(data))
                    }
                }
            }
        }

        sendGetBack(findUserSubscript, {
            auth: this.state.hostInfo.authPojo,
            userId: userId
        }, true, genProcessBack(subscriptBack, true, failWin))
    }

    loadDataList = (vips) => {
        let valid = this.state.valid;
        let len = vips.length;
        if (len === 0) {
            return null;
        }
        let lst = [];
        let winId = "vip-auth";
        let delayId = "vip-delay";
        vips.forEach((vip, i) => {
            let userId = vip["userId"];
            let opLst = [];
            opLst.push(
                <button className={"host-button"} style={{background: "#1ac131"}} key={"start-vip"} onClick={() => {
                    this.launchRequest(vip, delayVip, valid ? "延长VIP成功" : "赠予VIP特权成功",
                        <VipDelayHandler setDays={this.setDays} winId={delayId}
                                         handleDelay={this.handleDelay}/>, delayId)
                }}>{valid ? "延长VIP" : "赠予VIP特权"}</button>
            )
            if (valid) {
                opLst.push(
                    <button className={"host-button"} style={{background: "#dc2929", marginLeft: "14px"}}
                            key={"stop-vip"}
                            onClick={() => {
                                this.launchRequest(vip, terminateVip, "停用用户VIP成功", this.genAuthWin(winId), winId)
                            }}>{"终止VIP"}</button>
                )
            }

            let subscript = <button className={"host-button"} style={{background: "#808b96"}} key={"start-vip"}
                                    onClick={() => {
                                        this.openSubscript(userId)
                                    }}>
                {"查看订阅"}
            </button>;

            lst.push(<div className={"log-line"} key={i} id={i + "data"}
                          style={{
                              border: "2px solid rgba(125, 163, 205, 0.6)",
                              margin: i === 0 ? "0 0 8px 0" : i === (len - 1) ? "8px 0 0 0" : "8px 0"
                          }}>
                {this.loadingDataLine(userId, vip["vipLevel"], vip["toTime"], <div>{opLst}</div>, subscript)}
            </div>)
        })

        return lst;
    }

    loadingDataLine = (userId, level, toTime, op, subs) => {
        return [
            <div className={"vip-account-item"} key={"v-account"}>
                {userId}
            </div>,
            <div className={"vip-level-item"} key={"v-level"}>
                {level}
            </div>,
            <div className={"vip-expire-item"} key={"v-expire"}>
                {toTime}
            </div>,
            <div className={"vip-subs-item"} key={"v-subs"}>
                {subs}
            </div>,
            <div className={"vip-op-item"} key={"v-op"}>
                {op}
            </div>,
        ];
    }

    setDropDown = (e) => {
        let value = e.target.value;
        if (value) {
            let cur = this.state.valid;
            let v = value === "true";
            if (cur !== v) {
                this.setState({
                    valid: value === "true",
                }, () => {
                    this.callCondUpdate()
                })
            }
        }
    }

    setInputId = (e) => {
        let value = e.target.value;
        if (value) {
            this.setState({
                inputId: value,
            })
        }
    }

    searchUserVip = () => {
        let dom = this;
        let id = dom.state.inputId;
        let host = dom.state.hostInfo;
        if (id) {
            const successBack: WinBack = {
                callDataBack(res) {
                    let raw = [];
                    raw.push(res);
                    console.log(raw)
                    let lst = dom.loadDataList(raw);
                    dom.setState({
                        vipData: lst,
                        personal: true,
                    })
                }
            }

            sendGetBack(findVipById, {userId: id, auth: host.authPojo}, true, this.genVipBack(host, successBack))
        }
    }

    loadingSelector = () => {
        let lst = [];
        let options = [];
        VipCond.forEach((t, index) => {
            options.push(
                <option value={t.valid} key={index}>
                    {t.name}
                </option>
            )
        })
        lst.push(
            <div className={"log-select"} key={"a"}>
                <span style={{marginRight: "14px"}}>{"VIP状态筛选"}</span>
                <select className={"search-input"} style={{cursor: "pointer"}} defaultValue={this.state.valid}
                        onChange={(e) => {
                            this.setDropDown(e)
                        }}>
                    {options}
                </select>
            </div>
        )
        lst.push(
            <div className={"log-select"} key={"b"}>
                <span style={{marginRight: "14px"}}>{"查找VIP用户"}</span>
                <Search onChange={this.setInputId} onClick={this.searchUserVip} inputSize={22} hold={"请输入用户ID"}/>
            </div>
        )
        return lst;
    }

    removeAdd = () => {
        let vipData = this.state.vipData;
        let lst = [];
        let len = vipData.length;
        for (let i = 0; i < len - 1; i++) {
            lst.push(vipData[i])
        }
        this.setState({
            openAdd: false,
            vipData: lst,
        }, () => {
            scrollToAnchor(0 + "data")
        })
    }

    fillNewVip = (e, tag) => {
        let val = e.target.value;
        if (tag === "days") {
            val = parseInt(val);
        }
        let vip = this.state.opVip;
        vip[tag] = val;
        console.log(vip)
        this.setState({
            opVip: vip,
        })
    }

    submitNewVip = () => {
        let vip = this.state.opVip;
        if (vip.userId && vip["days"] && vip["days"] !== 0 && vip.level !== 0) {
            console.log(this.state.opVip)
            let winId = "vip-auth"
            this.launchRequest(vip, addNewVip, "开通用户VIP成功", this.genAuthWin(winId), winId)
        }
    }

    addUserVipLine = (openAdd, vipData) => {
        if (!openAdd) {
            let account = <div style={{display: "flex", alignItems: "center"}} key={"user-id"}>
                <input className={"host-input"} style={{width: "150px", marginRight: "10px"}} placeholder={"请输入账号ID"}
                       maxLength={22} onChange={(e) => {
                    this.fillNewVip(e, "userId")
                }}/>
            </div>;

            let days = <div style={{display: "flex", alignItems: "center"}} key={"user-days"}>
                <span>{"VIP期限"}</span>
                <input className={"host-input"} style={{width: "40px", margin: "0 8px"}}
                       maxLength={3} onChange={(e) => {
                    this.fillNewVip(e, "days")
                }}/>
                <span>{"天"}</span>
            </div>;

            let op = <div key={"user-op"}>
                <button className={"host-button"} style={{background: "#16c133", margin: "8px"}} onClick={() => {
                    this.submitNewVip()
                }}>
                    {"确认"}
                </button>
                <button className={"host-button"} style={{background: "#e7aa00", margin: "8px"}} onClick={() => {
                    this.removeAdd()
                }}>
                    {"取消"}
                </button>
            </div>
            let lst = [];
            vipData.forEach((item) => {
                lst.push(item)
            })
            lst.push(
                <div className={"log-line"} key={"add-user"} id={"new-vip"}
                     style={{border: "2px solid rgba(125, 163, 205, 0.4)", margin: "8px 0"}}>
                    {this.loadingDataLine(account, 1, days, op)}
                </div>
            )
            this.setState({
                openAdd: true,
                vipData: lst,
            }, () => {
                scrollToAnchor("new-vip")
            })
        }
    }

    loadTablePage = (personal, vipData, openAdd) => {
        return (
            <div style={{position: "relative", width: "100%", height: "100%"}}>
                <div style={{
                    minWidth: "940px",
                    height: "100px",
                    marginBottom: "8px",
                    padding: "20px 100px 0 100px"
                }}>
                    <div className={"log-line"} style={{
                        justifyContent: "space-between",
                    }}>
                        {personal
                            ?
                            <div className={"vip-back"} onClick={() => {
                                this.callCondUpdate()
                            }}>
                                <ArrowLeftOutlined/>
                                <span style={{marginLeft: "6px"}}>{"返回VIP用户列表"}</span>
                            </div>

                            :
                            this.loadingSelector()}
                    </div>
                    <div className={"log-line"} style={{fontWeight: "bold", background: "#3c80d4", color: "white"}}>
                        {this.loadingDataLine("用户账号ID", "VIP等级", "VIP到期时间", "操作", "订阅管理")}
                        <div className={"vip-add-item"} key={"v-add"} style={openAdd ? {cursor: "not-allowed"} : {}}
                             onClick={() => {
                                 this.addUserVipLine(openAdd, vipData)
                             }}>
                            <PlusOutlined/>
                        </div>
                    </div>
                </div>
                <div style={{minWidth: "940px", padding: "0 100px", height: "calc(100% - 150px)"}}>
                    <div className={"vip-table"}>
                        {vipData}
                    </div>
                </div>
            </div>
        )
    }

    backToTable = () => {
        this.setState({
            openSubscript: false,
            subscript: null,
        })
    }

    loadSubscriptInfo = (title, data) => {
        return (
            <div>
                <div className={"user-subs-status-title"}>
                    {title}
                    {!data ? <span className={"user-subs-status-tag"}>{"未填写"}</span> : null}
                </div>
                {data
                    ?
                    <div className={"user-subs-ct"}>
                        {this.loadSubscriptCt(JSON.parse(data))}
                    </div>
                    :
                    null
                }

            </div>
        )
    }

    loadSubscriptCt = (data) => {
        let lst = [];
        console.log(data)
        let type = data["type"];
        console.log(data["type"])
        let src = data["srcList"];
        let stage = data["stages"];
        let areas = data["areas"];
        let cops = data["cops"];
        let geos = data["geos"];
        if (type) {
            console.log(type)
            let label = itemTypeLabel[type]
            lst.push(
                <div className={"subscript-ct-line"} key={"type"}>
                    <span className={"subscript-ct-line-tag"}>{"关注类型"}</span>
                    <span className={"subscript-ct-line-ct"}>{label}</span>
                </div>
            )
        }

        if (src) {
            lst.push(
                <div className={"subscript-ct-line"} key={"src"}>
                    <span className={"subscript-ct-line-tag"}>{"信息来源"}</span>
                    <span>{this.loadInfoList(src, itemSrc)}</span>
                </div>
            )
        }

        if (stage) {
            lst.push(
                <div className={"subscript-ct-line"} key={"stage"}>
                    <span className={"subscript-ct-line-tag"}>{"项目阶段"}</span>
                    <span>{this.loadTextLst(stage)}</span>
                </div>
            )
        }

        if (areas) {
            lst.push(
                <div className={"subscript-ct-line"} key={"area"}>
                    <span className={"subscript-ct-line-tag"}>{"关注领域"}</span>
                    <span>{this.loadTextLst(areas)}</span>
                </div>
            )
        }

        if (cops) {
            lst.push(
                <div className={"subscript-ct-line"} key={"cop"}>
                    <span className={"subscript-ct-line-tag"}>{"合作方式"}</span>
                    <span>{this.loadInfoList(cops, itemCop)}</span>
                </div>
            )
        }

        if (geos) {
            lst.push(
                <div className={"subscript-ct-line"} key={"geo"}>
                    <span className={"subscript-ct-line-tag"}>{"地理位置"}</span>
                    <span>{this.loadTextLst(geos)}</span>
                </div>
            )
        }

        return lst;
    }

    loadTextLst = (texts) => {
        let lst = [];
        texts.forEach((item, index) => {
            lst.push(
                <span className={"subscript-ct-line-ct"} style={{marginRight: "5px"}} key={index}>
                    {item}
                </span>
            )
        })
        return lst
    }

    loadInfoList = (search, target) => {
        let lst = [];
        target.forEach((item, index) => {
            if (search.indexOf(item.value) !== -1) {
                lst.push(
                    <span className={"subscript-ct-line-ct"} style={{marginRight: "5px"}} key={index}>
                        {item.label}
                    </span>
                )
            }
        })
        return lst
    }

    loadVipSubscriptPage = () => {
        let subscript = this.state.subscript;
        let outcome = subscript.outcome;
        let demand = subscript.demand;
        let expert = subscript.expert;
        let policy = subscript.policy;
        console.log(subscript)
        return (
            <div style={{position: "relative", width: "100%", height: "100%"}}>
                <div style={{minWidth: "940px", height: "60px", marginBottom: "18px", padding: "20px 30px 0 30px"}}>
                    <div className={"log-line"} style={{background: "#eeeeee", display: "flex", alignItems: "center"}}>
                        <div className={"item-back"} onClick={() => {
                            this.backToTable()
                        }}>
                            <ArrowLeftOutlined/>
                        </div>
                        <span className={"user-header-title"}>{"用户ID: " + subscript.userId}</span>
                    </div>
                </div>
                <div style={{
                    minWidth: "940px",
                    margin: "0 30px",
                    height: "calc(100% - 120px)",
                }}>
                    <div className={"user-info-outer"} style={{minWidth: "940px"}}>
                        <div className={"user-subs-status-title"}>
                            {"订阅状态"}
                            <span className={"user-subs-status-tag"} style={{
                                color: subscript.needSend ? "#1ac131" : "#dc2929"
                            }}>{subscript.needSend ? "已开启" : "已关闭"}</span>
                        </div>
                        {this.loadSubscriptInfo("政策订阅信息", policy)}
                        {this.loadSubscriptInfo("专家订阅信息", expert)}
                        {this.loadSubscriptInfo("成果订阅信息", outcome)}
                        {this.loadSubscriptInfo("需求订阅信息", demand)}
                        <div style={{height: "50px"}}/>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let loaded = this.state.loaded;
        let personal = this.state.personal;
        let vipData = this.state.vipData;
        let openAdd = this.state.openAdd;
        let openSubscript = this.state.openSubscript;
        return <div className={"page-wrap"}>
            {loaded
                ?
                openSubscript
                    ?
                    this.loadVipSubscriptPage()
                    :
                    this.loadTablePage(personal, vipData, openAdd)
                : null}
        </div>;
    }
}

export default VipMgr;