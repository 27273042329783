import React, {Component} from 'react';
import "./tool.css";
import {DashOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {RemoveLoginInfo, scrollToAnchor, validNum} from "./Const";
import {fail, failReturn, findMsgByError, loginOut} from "./http/ErrorMgr";

const getterTimes = 3;

const hiddenStyle = {
    background: "transparent",
    cursor: "default",
    color: "#3c80d4"
}

const selectStyle = {
    margin: "3px 4px 3px 4px",
    width: "34px",
    height: "34px",
    fontSize: "16px",
    background: "#1981ff",
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.8)"
}

class Partitioner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataMap: new Map(),
            selectedPage: 1,
            totalPages: 0,
            inputPage: 0,
            options: this.props.options,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.options !== this.props.options) {
            console.log("load data");
            this.state.dataMap.delete(1);
            this.loadData(1);
            this.setState({
                selectedPage: 1,
            })
        }
    }

    componentDidMount() {
        this.loadData(1);
    }

    setShowData = (data, totalData, parent, partitioner) => {
        scrollToAnchor("selector")
        partitioner.showData = data;
        partitioner.loadComplete = true;
        partitioner.totalSize = totalData;
        parent.setState({
            partitioner: partitioner,
        })
    }

    loadData = (page) => {
        // 获取数据量
        let _this = this;
        let parent = this.props.parent;
        let options = this.props.options;
        options.pageNo = page;

        const getDataSize: DataBack = {

            successBack(res: any) {
                console.log("获取数据量res: ", res);
                let totalData = res;
                let partitioner = parent.state.partitioner;

                console.log("partitioner：", partitioner);

                let isDataSizeChange = false;
                if (totalData !== partitioner.totalSize) {  // /findResourceSize
                    isDataSizeChange = true;
                }
                console.log("isDataSizeChange: ", isDataSizeChange);
                let dataMap = !isDataSizeChange ? _this.state.dataMap : new Map();
                if (dataMap.has(page)) {
                    _this.setShowData(dataMap.get(page), totalData, parent, partitioner);
                    console.log("find data from exist!")
                    return;
                }
                console.log("find data from new!")
                let batchSize = _this.props.loadSetting.loadingBatch;
                let selectedPage = _this.state.selectedPage;
                let getterSize = batchSize * getterTimes;
                _this.findResourceData(page, selectedPage, batchSize, dataMap, totalData, partitioner, parent, options);
            },

            failBack(error: any) {
                // 失败处理
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        this.props.sizeFun(getDataSize, options);
    }

    // 获取资源数据
    findResourceData = (page, selectedPage, batchSize, dataMap, totalData, partitioner, parent, options) => {
        let _this = this;
        const getData: DataBack = {

            successBack(res: any) {
                console.log("数据res: ", res);
                let dataList = res;  // /findResourceData
                let pageData = [];
                let pageNum = selectedPage;
                if (dataList.length > 0) {
                    dataList.forEach((data) => {
                        // console.log(data)
                        if (pageData.length === batchSize) {
                            dataMap.set(pageNum, pageData);
                            pageData = [];
                            pageNum += 1;
                        }
                        pageData.push(data);

                    })
                } else {
                    dataMap.set(pageNum, []);
                }
                if (pageData.length > 0) {
                    dataMap.set(pageNum, pageData);
                }
                _this.setState({
                    dataMap: dataMap,
                    totalPages: _this.calTotalPageNum(totalData, batchSize),
                })
                _this.setShowData(dataMap.get(page), totalData, parent, partitioner)
            },

            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                } else {
                    fail(findMsgByError(error))
                }
            }
        };
        this.props.dataFun(getData, options);
    }

    calTotalPageNum = (totalData, batchSize) => {
        return totalData % batchSize === 0 ? totalData / batchSize : parseInt((totalData / batchSize) + "") + 1;
    }

    loadNumBtList = (totalPages) => {
        let selectedPage = this.state.selectedPage;
        return (
            <div className={"num-bt-outer"}>
                <div className={"check-move-bt"}
                     style={selectedPage === 1 ? {
                         background: "rgb(144,144,144)",
                         cursor: "not-allowed"
                     } : {background: "rgb(147,209,255)"}}
                     onClick={() => {
                         this.switchPage(selectedPage - 1, totalPages)
                     }}>
                    <LeftOutlined/>
                </div>
                {this.loadPageBt(selectedPage, totalPages)}
                <div className={"check-move-bt"}
                     style={selectedPage === totalPages ? {
                         background: "rgb(144,144,144)",
                         cursor: "not-allowed"
                     } : {background: "rgb(147,209,255)"}}
                     onClick={() => {
                         this.switchPage(selectedPage + 1, totalPages)
                     }}>
                    <RightOutlined/>
                </div>
            </div>
        )
    }

    switchPage = (toPage, totalPages) => {
        if (toPage < 1 || toPage > totalPages) {
            return;
        }
        this.handlePageSwitch(toPage)
    }

    handlePageSwitch = (i) => {
        this.loadData(i);
        this.setState({selectedPage: i});
    }

    loadPageBt = (selectedPage, totalPages) => {
        let before = [];
        let now = [];
        let after = [];
        for (let i = 1; i <= totalPages; i++) {
            if (i < selectedPage - 2) {
                if (before.length < 2) {
                    before.push(<div className={"check-move-bt"} style={selectedPage === i ? selectStyle : {}}
                                     onClick={() => {
                                         this.handlePageSwitch(i)
                                     }}>{i}</div>)
                } else {
                    before[1] = <div className={"check-move-bt"}
                                     style={hiddenStyle}>{
                        <DashOutlined/>}</div>
                }
            } else if (i > selectedPage + 2) {
                if (after.length < 2) {
                    after.push(<div className={"check-move-bt"} style={selectedPage === i ? selectStyle : {}}
                                    onClick={() => {
                                        this.handlePageSwitch(i)
                                    }}>{i}</div>)
                } else {
                    after[0] = <div className={"check-move-bt"}
                                    style={hiddenStyle}>{
                        <DashOutlined/>}</div>
                    after[1] = <div className={"check-move-bt"} style={selectedPage === totalPages ? selectStyle : {}}
                                    onClick={() => {
                                        this.handlePageSwitch(totalPages)
                                    }}>{i}</div>
                }
            } else {
                now.push(<div className={"check-move-bt"} style={selectedPage === i ? selectStyle : {}} onClick={() => {
                    this.handlePageSwitch(i)
                }}>{i}</div>)
            }
        }
        return before.concat(now, after);
    }

    setInputPage = (e) => {
        let val = e.target.value;
        if (validNum.test(val)) {
            this.setState({
                inputPage: val,
            })
        }
    }

    toInputPage = (totalPages) => {
        let to = this.state.inputPage;
        if (to < 1 || to > totalPages) {
            return;
        }
        this.handlePageSwitch(parseInt(to))
    }

    loadInputPageBt = (totalPages) => {
        return (
            <div className={"num-input-bt-outer"}>
                <input placeholder={"页码"} className={"num-input-win"} maxLength={4} onChange={(e) => {
                    this.setInputPage(e)
                }}/>
                <button className={"num-input-win-go"} onClick={() => {
                    this.toInputPage(totalPages)
                }}>{"GO"}</button>
            </div>
        )
    }

    render() {
        let setting = this.props.loadSetting;
        let totalPages = this.state.totalPages;
        return (
            <div className={setting.classes.outer}>
                {this.loadNumBtList(totalPages)}
                {this.loadInputPageBt(totalPages)}
            </div>
        )
    }
}

export default Partitioner;