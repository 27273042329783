import React, {Component} from "react";
import "../data/data.css"
import "../../tool/tool.css"
import moment from "moment";
import Search from "../../tool/Search";
import {checkBillsByOrderId, checkBillsByUser, checkPeriodBills, sendGetBack} from "../../tool/http/Request";
import {genProcessBack, InitAuth, PayMethod, RemoveLoginInfo, storage} from "../../tool/Const";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {fail, failReturn, findMsgByError, loginOut} from "../../tool/http/ErrorMgr";

const searchTitle = [{name: "订单编号", url: checkBillsByOrderId}, {name: "用户ID", url: checkBillsByUser}];

const payInfos = [
    {name: "微信支付", key: 1},
    {name: "支付宝", key: 2},
    {name: "全额湃通币", key: 3},
    {name: "其他方式", key: 4},
    {name: "全部", key: 5}
]

const indexType = {period: 1, order: 2, user: 3}

class CurrencySummary extends Component<null, CurrencyState> {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            dateSelector: new Map(),
            month: null,
            year: null,
            search: checkBillsByOrderId,
            dataLst: [],
            allData: [],
            auth: null,
            summary: null,
            method: 5,
            searchCt: null,
            pageIndex: indexType.period,
            userAccount: null,
        }
    }

    componentDidMount() {
        let dateInfo = this.findOneYearTime();
        let year = dateInfo[0];
        let month = dateInfo[1];
        let host = storage.get("hostInfo");
        let auth = InitAuth(host.hostAccount);
        auth.phone = host.hostPhone;
        let dom = this;
        let initBack: WinBack = {
            callDataBack(data: any) {
                dom.setState({
                    dateSelector: dateInfo[2],
                    year: dateInfo[0],
                    month: dateInfo[1],
                    dataLst: data,
                    allData: data,
                    auth: auth,
                    loaded: true,
                })
            }
        }
        this.findHostBill(year, month, auth, initBack);
    }

    findHostBill = (year, month, auth, back) => {
        let period = "" + year + "-" + (month < 10 ? "0" + month : month)
        console.log(period)
        sendGetBack(checkPeriodBills, {auth: auth, period: period}, true, genProcessBack(back, true));
    }

    searchByPeriod = () => {
        let dom = this;
        let auth = this.state.auth;
        let year = this.state.year;
        let month = this.state.month;

        let searchBack: WinBack = {
            callDataBack(data: any) {
                dom.setState({
                    dataLst: data,
                    allData: data,
                    method: 5,
                })
            }
        }

        this.findHostBill(year, month, auth, searchBack)
    }

    searchByCt = () => {
        let searchCt = this.state.searchCt;
        this.setState({
            loaded: false,
        })
        if (searchCt) {
            let search = this.state.search;
            let index;
            let searchBack: WinBack;
            let dom = this;

            const failBack: WinBack = {
                callDataBack(data: any) {
                    if (data === loginOut) {
                        RemoveLoginInfo();
                    }
                    failReturn(findMsgByError(data), window.location.href);
                }
            }

            if (search === checkBillsByOrderId) {
                index = 2;
                searchBack = {
                    callDataBack(data: any) {
                        let lst = [];
                        lst.push(data);
                        dom.setState({
                            dataLst: lst,
                            allData: lst,
                            method: 5,
                            pageIndex: index,
                            loaded: true,
                        })
                    }
                }
                sendGetBack(search, {auth: this.state.auth, orderId: searchCt}, true, genProcessBack(searchBack, true, failBack))

            } else {
                index = 3;
                searchBack = {
                    callDataBack(data: any) {
                        let account = data["account"];
                        let bills = data["bills"]
                        dom.setState({
                            dataLst: bills,
                            allData: bills,
                            userAccount: account,
                            method: 5,
                            pageIndex: index,
                            loaded: true,
                        })
                    }
                }
                sendGetBack(search, {auth: this.state.auth, userId: searchCt}, true, genProcessBack(searchBack, true, failBack))
            }
        }
    }

    findOneYearTime = () => {
        let format = "YYYY-MM"
        let date = moment().utcOffset(8).format(format);
        let before = moment().add(-1, "years").format(format)
        let start = this.findDatePoints(before);
        let end = this.findDatePoints(date);
        let dateMap = new Map();
        let startY = start[0];
        let endY = end[0];
        let startM = start[1];
        let endM = end[1];
        let curYear = startY;
        let monLst = [];
        let curMon = startM;
        while (curYear !== endY || curMon !== endM + 1) {
            if (curMon < 12) {
                monLst.push(curMon);
                curMon++;
            } else {
                monLst.push(curMon);
                dateMap.set(curYear, monLst);
                curMon = 1;
                monLst = [];
                curYear++;
            }
        }
        dateMap.set(curYear, monLst);
        console.log(dateMap)
        return [endY, endM, dateMap];
    }

    findDatePoints = (date) => {
        let pair = date.split("-");
        return [parseInt(pair[0]), parseInt(pair[1])]
    }

    loadSelectorOptions = (options) => {
        let lst = [];
        options.forEach((op, i) => {
            lst.push(
                <option value={op} key={i}>
                    {op}
                </option>
            )
        })
        return lst;
    }

    switchYear = (e) => {
        let val = e.target.value;
        if (val) {
            let year = parseInt(val);
            let mon = this.state.dateSelector.get(year).sort((a, b) => a - b).reverse()[0];
            console.log(this.state.dateSelector.get(year).sort((a, b) => a - b).reverse())
            this.setState({
                year: year,
                month: mon,
            })
        }
    }

    switchMonth = (e) => {
        let val = e.target.value;
        if (val) {
            let mon = parseInt(val);
            this.setState({
                month: mon,
            })
        }
    }

    switchSearch = (e) => {
        let val = e.target.value;
        if (val) {
            this.setState({
                search: val,
            })
        }
    }

    filterAllDataByMethod = (all, method) => {
        return method === 5 ? all : all.filter(bill => bill.payMethod === method);
    }

    switchMethod = (e) => {
        let val = e.target.value;
        if (val) {
            let method = parseInt(val);
            let dataLst = this.filterAllDataByMethod(this.state.allData, method);
            console.log(dataLst)
            this.setState({
                method: method,
                dataLst: dataLst,
            })
        }
    }

    loadingPaySelector = () => {
        let options = [];
        payInfos.forEach((op, i) => {
            options.push(
                <option value={op.key} key={i}>
                    {op.name}
                </option>
            )
        })

        return (
            <div>
                <span style={{
                    marginRight: "10px",
                    letterSpacing: "2px",
                    fontSize: "14px",
                    color: "black",
                    fontWeight: "bold"
                }}>{"支付方式"}</span>
                <select className={"search-input"} value={this.state.method} key={"method"} style={{width: "110px"}}
                        onChange={(e) => {
                            this.switchMethod(e)
                        }}>
                    {options}
                </select>
            </div>
        );
    }

    loadingSearchOptions = () => {
        let options = [];
        searchTitle.forEach((op, i) => {
            options.push(
                <option value={op.url} key={i}>
                    {op.name}
                </option>
            )
        })
        return options;
    }

    loadDataSelector = (pageIndex) => {
        let dateMap = this.state.dateSelector;
        let month = this.state.month;
        let year = this.state.year;
        let years = (
            <select className={"search-input"} value={year} key={"yr"} style={{width: "80px"}}
                    onChange={(e) => {
                        this.switchYear(e)
                    }}>
                {this.loadSelectorOptions(Array.from(dateMap.keys()).sort((a, b) => a - b).reverse())}
            </select>
        )
        let mLst = dateMap.get(year).sort((a, b) => a - b).reverse();
        let months = (
            <select className={"search-input"} value={month} key={"mo"} style={{width: "60px"}}
                    onChange={(e) => {
                        this.switchMonth(e)
                    }}>
                {this.loadSelectorOptions(mLst)}
            </select>
        )

        let search = (
            <select className={"search-input"} value={this.state.search} key={"se"}
                    style={{width: "100px", marginRight: "6px"}}
                    onChange={(e) => {
                        this.switchSearch(e)
                    }}>
                {this.loadingSearchOptions()}
            </select>
        )

        let leftPart;

        if (pageIndex === indexType.period) {
            leftPart = (
                <div style={{alignItems: "center", display: "flex"}}>
                    {years}
                    <span style={{padding: "0 8px"}}>{"年"}</span>
                    {months}
                    <span style={{padding: "0 18px 0 8px"}}>{"月"}</span>
                    <button className={"search-bt"} onClick={() => {
                        this.searchByPeriod()
                    }}>{"查询"}</button>
                </div>
            )
        } else if (pageIndex === indexType.order) {
            leftPart = (
                <div style={{alignItems: "center", display: "flex", height: "50px", width: "50px", fontSize: "20px"}}>
                    <ArrowLeftOutlined style={{cursor: "pointer"}} onClick={() => {
                        window.location.reload();
                    }}/>
                </div>
            )
        } else {
            let userAccount = this.state.userAccount;
            let picBala = userAccount["coinBalance"];
            let lock = userAccount["lockAmount"];
            let userId = userAccount["userId"];
            let historyTotal = userAccount["historyTotal"];
            leftPart = (
                <div style={{alignItems: "center", display: "flex"}}>
                    <ArrowLeftOutlined style={{cursor: "pointer", fontSize: "20px", marginRight: "10px", color: "rgb(88,88,88)"}} onClick={() => {
                        window.location.reload();
                    }}/>
                    <div style={{marginRight: "20px"}}>
                        <span className={"coin-account-title"}>{"用户ID"}</span>
                        <span className={"coin-account-num"} style={{color: "black", letterSpacing: "0"}}>{userId}</span>
                    </div>
                    <div style={{marginRight: "20px"}}>
                        <span className={"coin-account-title"}>{"湃通币余额"}</span>
                        <span className={"coin-account-num"}>{picBala}</span>
                    </div>
                    <div style={{marginRight: "20px"}}>
                        <span className={"coin-account-title"}>{"冻结数量"}</span>
                        <span className={"coin-account-num"}>{lock}</span>
                    </div>
                    <div>
                        <span className={"coin-account-title"}>{"充值总计"}</span>
                        <span className={"coin-account-num"}>{historyTotal}</span>
                    </div>
                </div>
            )
        }

        return (
            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                {leftPart}
                <div style={{display: "flex", alignItems: "center"}}>
                    {search}
                    <Search inputSize={50} onChange={this.setSearchCt} onClick={this.searchByCt}/>
                </div>
            </div>
        )
    }

    setSearchCt = (e) => {
        let val = e.target.value;
        this.setState({
            searchCt: val,
        })
    }

    loadLineData = (orderId, userId, total, expectPtc, disPrice, expectRmb, realPtc, payMethod, subDate) => {
        return [
            <div className={"table-line-item"} style={{width: "24%"}} key={"order"}>
                {orderId}
            </div>,
            <div className={"table-line-item"} style={{width: "14%"}} key={"user"}>
                {userId}
            </div>,
            <div className={"table-line-item"} style={{width: "8%"}} key={"total"}>
                {total}
            </div>,
            <div className={"table-line-item"} style={{width: "9%"}} key={"eptc"}>
                {expectPtc}
            </div>,
            <div className={"table-line-item"} style={{width: "8%"}} key={"dp"}>
                {disPrice}
            </div>,
            <div className={"table-line-item"} style={{width: "8%"}} key={"er"}>
                {expectRmb}
            </div>,
            <div className={"table-line-item"} style={{width: "9%"}} key={"rptc"}>
                {realPtc}
            </div>,
            <div className={"table-line-item"} style={{width: "8%"}} key={"pm"}>
                {payMethod}
            </div>,
            <div className={"table-line-item"} style={{width: "12%"}} key={"sd"}>
                {subDate}
            </div>,
        ];
    }

    loadDataList = (dataList) => {
        let len = dataList.length;
        if (len === 0) {
            return [null, null];
        }
        let lst = [];
        let orderNum = len;
        let orderPrice = 0;
        let discount = 0;
        let rmb = 0;
        let expPtc = 0;
        let realPtc = 0;
        dataList.forEach((item, index) => {
            let total = item["total"];
            let expectPtc = item["expectPtc"];
            let disPrice = item["disPrice"];
            let expectRmb = item["expectRmb"];
            let realPtcD = item["realPtc"];
            orderPrice += total;
            discount += disPrice;
            rmb += expectRmb;
            expPtc += expectPtc;
            realPtc += realPtcD;
            lst.push(
                <div className={"bill-line"}
                     style={{
                         margin: index === 0 ? "0 0 8px 0" : index === (len - 1) ? "8px 0 0 0" : "8px 0"
                     }}
                     key={index}>
                    {this.loadLineData(item["orderId"], item["userId"], total, expectPtc, disPrice,
                        expectRmb, realPtcD, PayMethod[item["payMethod"]], item["subDate"])}
                </div>
            )
        })

        let summary: BillSummary = {
            orderNum: orderNum,
            orderPrice: orderPrice,
            discount: discount,
            rmb: rmb,
            expPtc: expPtc,
            realPtc: realPtc,
        }

        return [lst, summary];
    }

    loadSummary = (summary, index) => {
        if (!summary) {
            return null;
        }

        let lst = [];

        let title = (
            <div className={"bill-summary-title"} key={"title"}>
                {index === indexType.period ? "当月汇总" : "用户汇总"}
            </div>
        )

        let ct = (
            <div className={"bill-summary-ct"} key={"ct"}>
                <div className={"bill-summary-item"}>
                    <span className={"bill-summary-item-title"}>{"订单总数"}</span>
                    <span className={"bill-summary-item-ct"}>{summary.orderNum}</span>
                </div>
                <div className={"bill-summary-item"}>
                    <span className={"bill-summary-item-title"}>{"订单总额"}</span>
                    <span className={"bill-summary-item-ct"}>{summary.orderPrice + " ¥"}</span>
                </div>
                <div className={"bill-summary-item"}>
                    <span className={"bill-summary-item-title"}>{"优惠总额"}</span>
                    <span className={"bill-summary-item-ct"}>{summary.discount + " ¥"}</span>
                </div>
                <div className={"bill-summary-item"}>
                    <span className={"bill-summary-item-title"}>{"应收人民币"}</span>
                    <span className={"bill-summary-item-ct"}>{summary.rmb + " ¥"}</span>
                </div>
                <div className={"bill-summary-item"}>
                    <span className={"bill-summary-item-title"}>{"应收湃通币"}</span>
                    <span className={"bill-summary-item-ct"}>{summary.expPtc + " Ptc"}</span>
                </div>
                <div className={"bill-summary-item"}>
                    <span className={"bill-summary-item-title"}>{"实收湃通币"}</span>
                    <span className={"bill-summary-item-ct"}>{summary.realPtc + " Ptc"}</span>
                </div>
            </div>
        )

        lst.push(title);
        lst.push(ct);

        return lst;
    }

    render() {
        let loaded = this.state.loaded;
        let dataLst = this.state.dataLst;
        console.log(this.state.method)
        let loadedData = this.loadDataList(dataLst);
        let pageIndex = this.state.pageIndex;
        console.log(this.state.searchCt)
        return <div className={"page-wrap"}>
            {loaded ?
                <div style={{position: "relative", width: "100%", height: "100%"}}>
                    <div style={{
                        minWidth: "1250px",
                        height: "60px",
                        padding: "0 20px 0 20px",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        {this.loadDataSelector(pageIndex)}
                    </div>
                    <div style={{
                        minWidth: "1250px",
                        height: "40px",
                        background: "#ffffff",
                        display: pageIndex === indexType.order ? "none" : "flex",
                        color: "white",
                        fontSize: "14px",
                        padding: "0 20px 0 20px",
                    }}>
                        {this.loadingPaySelector()}
                    </div>
                    <div style={{
                        minWidth: "1250px",
                        height: "50px",
                        marginBottom: "8px",
                        background: "#2f7ad7",
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        fontSize: "14px",
                        padding: "0 20px 0 20px",
                    }}>
                        {this.loadLineData("订单编号", "用户ID", "订单总额", "应付湃通币",
                            "折扣金额", "应付人民币", "实付湃通币", "付款方式", "订单时间")}
                    </div>
                    <div style={{minWidth: "1250px", height: "calc(100% - 223px)", padding: "0 20px 0 20px"}}>
                        <div className={"user-table"}>
                            {loadedData[0]}
                        </div>
                    </div>
                    <div style={{
                        minWidth: "1250px",
                        height: "60px",
                        marginTop: "5px",
                        display: pageIndex === indexType.order ? "none" : "flex",
                        alignItems: "center",
                        background: "#d7ebfa",
                        padding: "0 40px 0 0"
                    }}>
                        {this.loadSummary(loadedData[1], pageIndex)}
                    </div>
                </div>
                : null}
        </div>
    }
}

export default CurrencySummary;