import React, {Component} from "react";
import "./component.css";
import {ResourceHeader} from "../../tool/http/Request";
import LunchWin from "../../tool/Tools";
import AuthWin from "../../tool/AuthWin";
import {
    genProcessBack,
    HandleCopWay,
    HandleExpertEdu,
    HandleExpertTitle,
    HandleItemType,
    HandleSrcType,
    jsonToMap,
    mapToJson,
    picTypes,
    RemoveLoginInfo,
    storage
} from "../../tool/Const";
import DataOperator from "./DataOperator";
import {fail, failReturn, findMsgByError, loginOut, successReturn} from "../../tool/http/ErrorMgr";
import {ArrowLeftOutlined, PlusSquareOutlined} from "@ant-design/icons";
import RejectWin from "../check/RejectWin";
import CheckOperator from "../check/CheckOperator";
import {delWin} from "../../tool/Luncher";

const dataRequiredList = [
    {
        type: 'outcome',
        required: ['outcomeName', 'relDate', 'outcomeType', 'srcType', 'patentNo', 'outcomeGeo', 'outcomeRoot',
            'outcomeStage', 'copWay', 'outcomePrice', 'clickNum', 'saveNum', 'outcomeAreas', 'outcomeBanner', 'picSrc', 'outcomeDes', 'usageCt']
    },
    {
        type: 'demand',
        required: ['demandName', 'relDate', 'compDate', 'demandType', 'srcType', 'demandGeo', 'demandRoot',
            'copWay', 'demandPrice', 'clickNum', 'saveNum', 'demandAreas', 'demandBanner', 'demandDes', 'demandCt']
    },
    {
        type: 'expert',
        required: ['expertName', 'srcType', 'expertTitle', 'expertJob', 'expertGeo', 'expertRoot', 'expertSch', 'expertPrice',
            'clickNum', 'saveNum', 'expertAreas', 'expertBanner', 'expertDes', 'expertCt']
    },
    {
        type: 'policy',
        required: ['policyName', 'policyDate', 'policyRoot', 'policyCtr', 'geo', 'clickNum', 'saveNum', 'policyDes', 'policyBanner', 'bigData']
    },
    {
        type: 'news',
        required: ['newsName', 'newsDate', 'newsRoot', 'srcType', 'clickNum', 'saveNum', 'newsDes', 'newsBanner', 'bigData']
    }
]

export default class FrameParent extends Component<FrameProps, FrameState> {
    constructor(props) {
        super(props);
        this.state = {}
    }

    // areas初始化
    initCheckByArea = (tag) => {
        let pojo = this.props.pojo;
        let areas = this.state.areas;
        let data = pojo.pojoStr;
        let area = data[tag];
        let areaList = area.split(";");
        areaList.forEach((item) => {
            areas.filter(it => it.label === item ? it.checked = true : null)
        });

        let checkedList = areas.filter(it => it.checked);
        let num = checkedList.length;
        let disabledList = areas.filter(it => it.checked === false);
        if (num === 4) {
            disabledList.filter(it => it.disabled = true);
        } else {
            disabledList.filter(it => it.disabled = false);
        }

        this.setState({
            areas: areas,
            areasTmp: JSON.parse(JSON.stringify(areas)),
            areasLoad: true,
        })
    }

    // 加载picSrc
    loadPicSrc = (data, imgUrl, itemId) => {
        let ui = []
        let pics = data['picSrc'].split(";");
        for (let i = 0; i < 3; i++) {
            let pic = ResourceHeader + data[itemId] + "/" + pics[i];
            let imgSrc = imgUrl['picSrc'][i] ? imgUrl['picSrc'][i] : (pics[i] ? pic : null);
            ui.push(
                imgSrc ?
                    <div className={"img-wrap"} key={"picSrc" + i}>
                        <img className={"img-style contain"} src={imgSrc} alt={""}/>
                    </div>
                    : null
            )
        }
        return ui;
    }

    // 加载select
    loadSelect = (data) => {
        let selects = [];
        selects.push(
            <option value={null} key={"ini"}>
                {"未选择"}
            </option>
        )
        data.forEach((p) => {
            selects.push(
                <option value={p.value} key={p.value}>
                    {p.label}
                </option>
            )
        })
        return selects;
    }

    // 加载areas checkbox
    loadBoxes = (boxes, isEdit, data, areasTag) => {
        // console.log("领域数据：", boxes);
        let box = [];
        boxes.forEach((p, i) => {
            box.push(
                <div key={p + i} id={p + i}
                     style={{
                         padding: "10px 18px 10px 0",
                         minWidth: "100px",
                         float: "left",
                         height: "30px",
                         display: "flex",
                         alignItems: "center"
                     }}
                >
                    <input className={"search-input"} type={"checkbox"}
                           style={{width: "16px", height: "16px", marginRight: "8px"}}
                           key={p + i} id={p + i}
                           checked={p.checked}
                           disabled={!isEdit ? true : p.disabled}
                           onChange={() => {
                               this.onChangeCheck(p, boxes, 4, data, areasTag)
                           }}
                    />
                    <span className={"outcome-item-title"}>{p.label}</span>
                </div>
            )
        })
        return box;
    }

    // 修改checkbox
    onChangeCheck = (item, items, max, data, areasTag) => {
        console.log("点击对象：", item);
        console.log("点击对象checked：", item.checked);
        console.log("全部对象：", items);
        let val = item.checked
        let selItem = items.filter(it => it.label === item.label);
        console.log("selItem：", selItem);
        selItem[0].checked = !val;
        console.log("selItem修改后：", selItem);
        let checkedList = items.filter(it => it.checked);
        let num = checkedList.length;
        let disabledList = items.filter(it => it.checked === false);

        console.log("checkedList: ", checkedList);
        console.log("disabledList: ", disabledList);
        console.log(num);
        if (num === max) {
            disabledList.filter(it => it.disabled = true);
        } else {
            disabledList.filter(it => it.disabled = false);
        }
        this.setState({
                areasTmp: items,
            },
            () => {
                this.handleItemAreas(data, this.state.areasTmp, areasTag);
            })
    }

    // 处理input
    handleInput = (e, pojo, data, tag, isNum) => {
        let val = e.target.value;

        console.log(tag, "-输入内容：", val)

        data[tag] = isNum ? Number.parseInt(val) : val;
        this.setState({
            pojoTmp: pojo,
        })
    }

    // 处理日期
    handleDateInput = (e, pojo, data, tag, type) => {
        let val = e.target.value;
        let date = data[tag];
        let year = type === 0 ? val : date.split("-")[0];
        let month = type === 1 ? val : date.split("-")[1];
        let day = type === 2 ? val : date.split("-")[2];
        data[tag] = year + "-" + month + "-" + day;
        this.setState({
            pojoTmp: pojo,
        })
    }

    // 解析年月日
    handleDate = {
        year(data, tag) {
            if (data[tag]) {
                return data[tag].split("-")[0];
            }
        },
        month(data, tag) {
            if (data[tag]) {
                return data[tag].split("-")[1];
            }
        },
        day(data, tag) {
            if (data[tag]) {
                return data[tag].split("-")[2];
            }
        }
    }

    handleSubDate = {
        year(data, tag) {
            if (data[tag]) {
                return data[tag].split(" ")[0].split("-")[0];
            }
        },
        month(data, tag) {
            if (data[tag]) {
                return data[tag].split(" ")[0].split("-")[1];
            }
        },
        day(data, tag) {
            if (data[tag]) {
                return data[tag].split(" ")[0].split("-")[2];
            }
        }
    }

    // 设置地理位置
    setInitGeo = (geo, tag) => {
        let pojoTmp = this.state.pojoTmp;
        let data = pojoTmp.pojoStr;
        data[tag] = geo;
        this.setState({
            pojoTmp: pojoTmp,
        })
    }

    // 上传图片
    handleImgUpload = (e, maxWidth, maxHeight, imgSrc, imgUrl, tag, num, pojoTmp) => {
        // console.log(e);
        let file = e.target.files[0];
        // console.log(file);

        if (file) {
            this.imageUploadCallBack(file, maxWidth, maxHeight).then(res => {
                // console.log("上传图片res：", res);
                let img = res.data.link;  // 包含（data:image/png;base64,）
                let imgToUrl = img.split(',')[1];

                let data = pojoTmp.pojoStr;
                let target = data[tag];

                if (num === 1) {
                    imgSrc[tag] = imgToUrl;
                    imgUrl[tag] = img;
                    if (!data[tag]) {
                        data[tag] = "banner.jpg"
                    }
                } else {
                    imgSrc[tag].push(imgToUrl);
                    imgUrl[tag].push(img);

                    // 判断是否需要更改pojoStr里的picSrc

                    let picSrcList = data[tag].split(';');
                    let len = data[tag] ? picSrcList.length : 0;
                    if (len === 0) {
                        console.log("新增pic");
                        picSrcList.push("pic_1.jpg");
                        data[tag] = "pic_1.jpg";
                    } else {
                        let newLen = imgSrc[tag].length;
                        if (newLen > len) {
                            console.log("需要新增pic");
                            let diff = newLen - len;
                            for (let j = 1; j <= diff; j++) {
                                let index = len + j;
                                console.log("需要新增index: ", index)
                                picSrcList.push("pic_" + index + ".jpg");
                                data[tag] = data[tag] + ";" + "pic_" + index + ".jpg";
                            }
                            ;
                        }
                    }
                }

                this.setState({
                        pojoTmp: pojoTmp,
                        imgSrc: imgSrc,
                        imgUrl: imgUrl,
                    },
                    () => {
                        console.log("imgSrc: ", imgSrc);
                        console.log("imgUrl: ", imgUrl);
                    }
                )
            })

        }
    }

    handleImageBigData = (e, maxWidth, maxHeight, pojoTmp, item, imgSrc, imgUrl) => {
        console.log("数据：", pojoTmp)

        let file = e.target.files[0];
        if (file) {
            this.imageUploadCallBack(file, maxWidth, maxHeight).then(res => {
                let img = res.data.link;  // 包含（data:image/png;base64,）
                let imgToUrl = img.split(',')[1];

                console.log("添加id：", item)

                let data = pojoTmp.pojoStr;
                let bigData = data['bigData'];
                let picsMap = bigData['pics'];
                // let picsMap = new Map();
                picsMap.set(item + ".jpg", imgToUrl);

                let picsSrc = this.state.picsSrc;
                picsSrc.set(item + ".jpg", img);

                bigData.pics = picsMap;

                this.setState({
                    pojoTmp: pojoTmp,
                    picsSrc: picsSrc,
                })

            })
        }
    }

    // 上传图片回调
    imageUploadCallBack = (file, maxWidth, maxHeight) => new Promise(
        (resolve) => {
            // console.log(file)
            let names = file.name.split(".");
            // let type = file.type.split("/")[1];
            let type = names.length > 1 ? names[1] : "jpeg";
            if (type === "jpg") {
                type = "jpeg";
            }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            let img = new Image();
            reader.onload = function (e) {
                img.src = this.result.toString()
            }
            // console.log(img)
            img.onload = function () {
                // let canvas = document.createElement('canvas');
                // let context = canvas.getContext('2d');
                //
                // // 图片原始尺寸
                // let originWidth = this.width;
                // let originHeight = this.height;
                // // 最大尺寸限制，可通过设置宽高来实现图片压缩程度
                // // 目标尺寸
                // let targetWidth = originWidth,
                //     targetHeight = originHeight;
                // // 图片尺寸超过尺寸的限制
                // if (originWidth > maxWidth || originHeight > maxHeight) {
                //     if (originWidth / originHeight > maxWidth / maxHeight) {
                //         // 更宽，按照宽度限定尺寸
                //         targetWidth = maxWidth;
                //         targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                //     } else {
                //         targetHeight = maxHeight;
                //         targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                //     }
                // }
                // // canvas对图片进行缩放
                // canvas.width = targetWidth;
                // canvas.height = targetHeight;
                // // 清除画布
                // context.clearRect(0, 0, targetWidth, targetHeight);
                // // 图片压缩
                // context.drawImage(img, 0, 0, targetWidth, targetHeight);

                //压缩后的图片转base64 url
                /*canvas.toDataURL(mimeType, qualityArgument),mimeType 默认值是'image/png';
                  * qualityArgument表示导出的图片质量，只有导出为jpeg和webp格式的时候此参数才有效，默认值是0.92*/

                let newUrl = "";
                if (type === "svg") {
                    newUrl = "data:image/svg+xml;" + img.src.split(";")[1]
                } else {
                    newUrl = "data:image/" + type + ";" + img.src.split(";")[1]
                    // newUrl = canvas.toDataURL(
                    //     'image/' + type, 0.92
                    // );//base64 格式
                }

                resolve({
                    data: {
                        link: newUrl,
                        type: type,
                    }
                })

                // console.log(newUrl)
                // console.log(type)
            }
        }
    )

    // 推送首页
    pushDataPre = (tag, value) => {
        let dataPojo = this.state.pojoTmp;
        let pojoStr = dataPojo.pojoStr;
        pojoStr[tag] = !value;
        this.launchAuthWin("menu", "push", 3, this.pushData);
    }

    // 推送广告
    advDataPre = (tag, value) => {
        let dataPojo = this.state.pojoTmp;
        let pojoStr = dataPojo.pojoStr;
        pojoStr[tag] = !value;
        this.launchAuthWin("menu", "adv", 3, this.advData);
    }

    // launch authWin
    launchAuthWin = (id, winId, level, backFun) => {
        let userInfo = storage.get("hostInfo");
        let phone = userInfo.hostPhone;
        LunchWin(<AuthWin level={level} parentId={id} phone={phone} winId={winId} backFun={backFun}
                          account={userInfo.hostAccount}/>, id, winId);
    }

    // 生成authPojo
    genAuthPojo = (angle, angleSec, sec, pass) => {
        let authPojo = this.state.pojoTmp.authPojo;
        authPojo.anglePhone = angle;
        authPojo.secCode = sec;
        authPojo.angleSec = angleSec;
        authPojo.passCode = pass;
        return authPojo;
    }

    // 推送首页调用方法
    pushData = (angle, angleSec, sec, pass, myback) => {
        new DataOperator().pushData(genProcessBack(this.resultBack(myback, "推送首页修改成功"), false), this.genPojo(angle, angleSec, sec, pass));
    }

    // 推送广告调用方法
    advData = (angle, angleSec, sec, pass, myback) => {
        new DataOperator().advData(genProcessBack(this.resultBack(myback, "推送广告修改成功"), false), this.genPojo(angle, angleSec, sec, pass));
    }

    genPojo = (angle, angleSec, sec, pass) => {
        let dataPojo = this.state.pojoTmp;
        dataPojo.authPojo = this.genAuthPojo(angle, angleSec, sec, pass)
        dataPojo.pojoStr = JSON.stringify(dataPojo.pojoStr);
        return dataPojo;
    }

    resultBack = (myBack, info) => {
        const back: WinBack = {
            callBack() {
                myBack.callBack();
                successReturn(info, window.location.href);
            }
        }
        return back;
    }

    // 取消fun
    cancelData = () => {
        this.setState({
            pojoTmp: JSON.parse(JSON.stringify(this.props.pojo)),
            areasTmp: JSON.parse(JSON.stringify(this.state.areas)),
            isEdit: false
        })
    }

    updateDataPre = (type, pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag) => {
        let isFilled = this.handleFilled(pojoTmp, type);
        if (isFilled) {
            this.updateData(this.updateDataPojo(pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag, false));
        } else {
            fail("请填写完整必填项")
        }
    }

    // 保存fun
    updateData = (dataPojo) => {
        // let operator = new DataOperator(this.updateDataPojo(pojoTmp, imgSrc, tagO, tagT, pics, areasTmp, areasTag));
        dataPojo.pojoStr = JSON.stringify(dataPojo.pojoStr);

        // console.log("编辑保存的dataPojo：", dataPojo);

        let operator = new DataOperator();

        const updateDataBack: DataBack = {
            successBack(res: any) {
                successReturn("更新成功", window.location.href);
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        operator.updateData(updateDataBack, dataPojo);
    }

    // 更新pojo
    updateDataPojo = (pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag, isAdd) => {
        console.log("保存的pojoTmp：", pojoTmp);
        console.log("保存的imgSrc：", imgSrc);
        let data = pojoTmp.pojoStr;

        pics = this.handlePics(data, imgSrc, tagO, tagT, pics);
        // data = this.handleItemAreas(data, areasTmp, areasTag);
        data.keyMap = this.fillKeyMap(pojoTmp);

        if (isAdd) {
            pojoTmp.pojoStr = JSON.stringify(data);
        }

        pojoTmp.pics = mapToJson(pics);

        return pojoTmp;
    }

    fillKeyMap = (pojo) => {
        let data = pojo.pojoStr;
        switch (pojo.index) {
            case "1344": {
                return data.relDate + HandleItemType(data.outcomeType) + HandleSrcType(data.srcType) + data.patentNo + data.outcomeGeo + data.outcomeRoot
                    + data.outcomeStage + HandleCopWay(data.copWay) + data.outcomePrice + data.outcomeAreas + data.outcomeName + this.handleKeyMapByName(data.keyMap, data.outcomeName);
            }
            case "9919": {
                return data.relDate + data.compDate + HandleItemType(data.demandType) + HandleSrcType(data.srcType) + data.demandGeo
                    + data.demandRoot + HandleCopWay(data.copWay) + data.demandPrice + data.demandAreas + data.demandName + this.handleKeyMapByName(data.keyMap, data.demandName);
            }
            case "2577": {
                return HandleSrcType(data.srcType) + HandleExpertTitle(data.expertTitle) + data.expertHor + data.expertJob + data.expertGeo
                    + data.expertRoot + data.expertSch + data.expertTarget + HandleExpertEdu(data.expertEdu) + data.expertAreas + data.expertPrice
                    + data.expertName + this.handleKeyMapByName(data.keyMap, data.expertName);
            }
            case "3478": {
                return data.policyDate + data.policyRoot + data.policyCtr + data.policyPri + data.policyCity + data.policyName + this.handleKeyMapByName(data.keyMap, data.policyName);
            }
            case "3566": {
                return data.newsDate + data.newsRoot + HandleSrcType(data.srcType) + data.newsName + this.handleKeyMapByName(data.keyMap, data.newsName);
            }
        }
    }

    handleKeyMapByName = (keyMap, name) => {
        if (keyMap) {
            let val = keyMap.split(name);
            let len = val.length;
            if (len > 1) {
                return val[1];
            } else {
                return keyMap;
            }
        } else {
            return keyMap;
        }
    }

    // 处理基础pics
    handlePics = (data, imgSrc, tagO, tagT, pics) => {
        if (imgSrc[tagO]) {
            console.log("banner已修改");
            let key = data[tagO] ? data[tagO] : "banner.jpg";
            let value = imgSrc[tagO];
            pics.set(key, value);
        }
        let len = tagT ? imgSrc[tagT].length : 0;
        if (len > 0) {
            console.log("picSrc已修改");
            let keys = data[tagT].split(";");
            let values = imgSrc[tagT];
            for (let i = 0; i < len; i++) {
                pics.set(keys[i], values[i])
            }
        }
        return pics;
    }

    // 处理areas转换成item对应areas
    handleItemAreas = (data, areasTmp, areasTag) => {
        let string = '';
        let list = areasTmp.filter(it => it.checked);
        console.log(list);
        let areasLen = list.length;
        list.forEach((item, index) => {
            string = string + item.label + (index + 1 === areasLen ? "" : ";");
        })
        data[areasTag] = string;
        return data;
    }

    addDataPre = (type, pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag) => {
        let isFilled = this.handleFilled(pojoTmp, type);
        if (isFilled) {
            this.addData(pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag);
        } else {
            fail("请填写完整必填项")
        }
    }

    // 新增fun
    addData = (pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag) => {
        let operator = new DataOperator();

        const addDataBack: DataBack = {
            successBack(res: any) {
                successReturn("新增成功", window.location.href);
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    storage.set('expertData', pojoTmp);
                    console.log(1111)
                    console.log(pojoTmp)
                    fail(findMsgByError(error));

                    window.location.reload();
                }
            }
        }

        operator.addData(addDataBack, this.updateDataPojo(pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag, true));
    }

    // 根据id获取统计数据
    getStatistics = (itemId) => {
        const host: HostInfo = storage.get("hostInfo");
        let pojo = this.props.pojo;
        let params = {
            auth: {
                account: host.hostAccount,
            },
            id: pojo.pojoStr[itemId],
        };

        let operator = new DataOperator(pojo);

        const getStatisticsBack: DataBack = {
            successBack(res: any) {

            },
            failBack(error: any) {

            }
        }

        operator.findStatisticsById(params, getStatisticsBack);
    }

    // bigData初始化
    initBigData = () => {
        let pojoTmp = this.props.pojo;
        let data = pojoTmp.pojoStr;
        let bigData = data['bigData'];
        if (bigData) {
            // 修改bigData流程
            let orders = bigData['orders'];
            let pics = bigData['pics'];
            let texts = bigData['texts'];

            console.log("bigData orders: ", orders);
            console.log("bigData pics: ", pics);
            console.log("bigData texts: ", texts);

            // 处理pics
            let picsMap = new Map();
            let picsList = orders.filter(it => it.includes("p"));
            console.log(picsList);
            picsList.forEach((pic) => {
                picsMap.set(pic + ".jpg", pic + ".jpg");
            })
            bigData['pics'] = picsMap;

            // 处理texts
            bigData['texts'] = jsonToMap(bigData['texts']);

            this.setState({
                // orders: orders,
                // ordersTmp: ordersTmp,
                // texts: jsonToMap(texts),
                // textsTmp: jsonToMap(textsTmp),
                // pic: pic,
                // picTmp: pic,
                // pic: jsonToMap(pic),
                // picTmp: jsonToMap(picTmp),

                pojoTmp: pojoTmp,
                picsSrc: new Map(),
                bigDataLoaded: true,
            })
        } else {
            // 新增bigData流程
            data['bigData'] = {
                orders: [],
                pics: new Map(),
                texts: new Map(),
            }

            this.setState({
                pojoTmp: pojoTmp,
                picsSrc: new Map(),
                bigDataLoaded: true,
            })
        }
    }

    // 加载bigData texts
    loadBigData = (isAdd, isEdit, pojoTmp, itemId, ordersTmp, textsTmp, picTmp, imgSrc, imgUrl) => {
        let ui = [];
        console.log("data: ", pojoTmp);

        let data = pojoTmp.pojoStr;
        let bigData = data.bigData;
        let orders = bigData.orders;
        // let pics = bigData.pics;
        // let texts = bigData.texts;

        // console.log("加载顺序：", ordersTmp);
        // console.log("加载texts：", textsTmp);
        // console.log("加载pic：", picTmp);

        if (orders) {
            orders.forEach((item, index) => {
                // let data = pojoTmp.pojoStr;
                // let bigData = data['bigData'];
                let pics = bigData['pics'];
                let texts = bigData['texts'];

                let picsList = orders.filter(it => it.includes("p"));
                let textsList = orders.filter(it => it.includes("t"));
                // console.log("picsList: ", picsList, picsList.at(-1));
                // console.log("textsList: ", textsList);

                let picsSrc = this.state.picsSrc;
                // console.log("bigData 图片src 缓存：", picsSrc);

                let isPic = item.substring(0, 1) === "p";

                let picById = ResourceHeader + data[itemId] + "/" + pics.get(item + ".jpg");
                let editAddImg = (pics.get(item + ".jpg") === null || pics.get(item + ".jpg") === item + ".jpg") ? picById : pics.get(item + ".jpg");
                let editImg = picsSrc.has(item + ".jpg") ? picsSrc.get(item + ".jpg") : editAddImg;
                let imgSrc = isAdd ? picsSrc.get(item + ".jpg") : editImg;

                let content = texts.get(item);

                // let content = isPic ? picTmp.get(item) : texts.get(item);
                // let pic = ResourceHeader + data[itemId] + "/" + picTmp.get(item);
                // let imageSrc = picSrc ? picSrc.get(item) : pic;
                ui.push(
                    isPic ?
                        <div className={"big-data-para-item"}>
                            <span className={"big-data-para"}>{"图片" + item}</span>
                            {
                                imgSrc ?
                                    <div className={"img-wrap"}>
                                        <img className={"img-style contain"} src={imgSrc} alt={""}/>
                                    </div>
                                    : null
                            }
                            <div className={"img-upload-fun"} style={{marginLeft: '10px'}}>
                                <label htmlFor={"pic_" + item} className={"img-upload-btn"}
                                       style={{pointerEvents: isEdit ? "" : "none"}}
                                >
                                    {"点击上传"}
                                </label>
                                <input type={"file"} name={"file"} id={"pic_" + item}
                                       className={"input-file"}
                                       accept={picTypes}
                                       onChange={(e) => {
                                           this.handleImageBigData(e, 1400, 1400, pojoTmp, item, imgSrc, imgUrl)
                                       }}
                                />
                            </div>
                            {
                                picsList.at(-1) === item ?
                                <div className={"img-upload-fun"} style={{marginLeft: '10px'}}
                                     onClick={() => {
                                         this.delBigData(pojoTmp, isEdit, true, false, item)
                                     }}
                                >
                                    <button>
                                        {"删除"}
                                    </button>
                                </div>
                                : null
                            }

                        </div>
                        :
                        <div className={"big-data-para-item"}>
                            <span className={"big-data-para"}>{"段落" + item}</span>
                            <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                      style={{height: "200px", minWidth: "600px", fontSize: "14px", padding: "10px"}}
                                      key={"para" + item}
                                      value={content}
                                      readOnly={!isEdit}
                                      onChange={(e) => {
                                          this.handleTextsInput(e, pojoTmp, item)
                                      }}
                            />
                            {
                                textsList.at(-1) === item ?
                                    <div className={"img-upload-fun"} style={{marginLeft: '10px'}}
                                         onClick={() => {
                                             this.delBigData(pojoTmp, isEdit, false, true, item)
                                         }}
                                    >
                                        <button>
                                            {"删除"}
                                        </button>
                                    </div>
                                    : null
                            }
                        </div>
                )
            })
        } else {

        }
        return ui;
    }

    handleTextsInput = (e, pojoTmp, key) => {
        let val = e.target.value;

        let data = pojoTmp.pojoStr;
        let texts = data.bigData.texts;
        texts.set(key, val);

        this.setState({
            pojoTmp: pojoTmp,
            // textsTmp: texts,
        })
    }

    loadBigDataPart = (isAdd, isEdit, pojoTmp, itemId, ordersTmp, textsTmp, picTmp, imgSrc, imgUrl) => {
        return (
            <div className={"outcome-edit-line"} style={{minHeight: "300px", flexDirection: "column"}}>
                <span className={"edit-item-title flex-start"} style={{padding: "20px 0 20px"}}>
                    <span className={"required-icon"}>{"*"}</span>
                    {"正文内容（非VIP和未解锁只可查看段落1以上部分）"}
                </span>
                {this.loadBigData(isAdd, isEdit, pojoTmp, itemId, ordersTmp, textsTmp, picTmp, imgSrc, imgUrl)}
                <div className={"big-data-para-add"}
                     onClick={() => {
                         this.setState({
                             showAdd: true,
                         })
                     }}
                >
                    <PlusSquareOutlined className={"big-data-para-add-icon"}/>
                    <span>{"添加素材"}</span>
                </div>
                <div className={"big-data-para-add"}
                     style={{display: this.state.showAdd ? "" : "none", marginLeft: '20px'}}>
                    <div className={"big-data-para-add"}
                         onClick={() => {
                             this.addBigData(pojoTmp, true, false)
                         }}
                    >
                        <PlusSquareOutlined className={"big-data-para-add-icon"}/>
                        <span>{"添加图片"}</span>
                    </div>
                    <div className={"big-data-para-add"}
                         style={{marginLeft: '10px'}}
                         onClick={() => {
                             this.addBigData(pojoTmp, false, true)
                         }}
                    >
                        <PlusSquareOutlined className={"big-data-para-add-icon"}/>
                        <span>{"添加段落"}</span>
                    </div>
                </div>
            </div>
        )
    }

    addBigData = (pojoTmp, isAddPic, isAddText) => {
        console.log(pojoTmp)

        let data = pojoTmp.pojoStr;
        let bigData = data['bigData'];
        let orders = bigData.orders;
        let pics = bigData.pics;
        let texts = bigData.texts;

        if (isAddPic) {
            console.log(pics)

            let size = pics.size;
            let add = "p" + (size + 1);
            let value = add + ".jpg";
            orders.push(add);
            pics.set(value, "");
            this.setState({
                pojoTmp: pojoTmp,
                showAdd: false,
            })

            // let size = picTmp ? picTmp.size : 0;
            // let add = "p" + (size + 1);
            // let value = add + ".jpg";
            // picTmp.set(add, value);
            // ordersTmp.push(add);
            // this.setState({
            //     ordersTmp: ordersTmp,
            //     picTmp: picTmp,
            //     showAdd: false,
            // })
        }
        if (isAddText) {
            let size = texts.size;
            let add = "t" + (size + 1);
            orders.push(add);
            texts.set(add, "");
            this.setState({
                pojoTmp: pojoTmp,
                showAdd: false,
            })
            // let size = textsTmp ? textsTmp.size : 0;
            // let add = "t" + (size + 1);
            // textsTmp.set(add, "");
            // ordersTmp.push(add);
            // this.setState({
            //     ordersTmp: ordersTmp,
            //     textsTmp: textsTmp,
            //     showAdd: false,
            // })
        }
    }

    delBigData = (pojoTmp, isEdit, isDelPic, isDelText, item) => {
        let data = pojoTmp.pojoStr;
        let bigData = data['bigData'];
        let orders = bigData.orders;
        let pics = bigData.pics;
        let texts = bigData.texts;

        if (isEdit) {
            if (isDelPic) {
                console.log("待删除：", item);
                let len = orders.length;
                for (let i = 0; i < len; i++) {
                    if (orders[i] === item) {
                        orders.splice(i, 1);
                    }
                }
                // let size = pics.size;
                // let add = "p" + (size + 1);
                // let value = add + ".jpg";
                // orders.push(add);
                pics.delete(item + ".jpg");

                let picsSrc = this.state.picsSrc;
                picsSrc.delete(item + ".jpg");

                this.setState({
                    pojoTmp: pojoTmp,
                    picsSrc: picsSrc,
                    showAdd: false,
                })
            }

            if (isDelText) {
                let len = orders.length;
                for (let i = 0; i < len; i++) {
                    if (orders[i] === item) {
                        orders.splice(i, 1);
                    }
                }
                texts.delete(item);
                this.setState({
                    pojoTmp: pojoTmp,
                    showAdd: false,
                })
            }
        }
    }

    loadHeader = (type, url, pojoTmp, isAdd, isEdit, pics, imgSrc, tagO, tagT, areasTmp, areasTag, isSub) => {
        let data = pojoTmp.pojoStr;
        return (
            <div className={"edit-header"}>
                <div className={"item-back"}
                     onClick={() => {window.location.href = url}}
                >
                    <ArrowLeftOutlined/>
                </div>
                <div className={"item-header"}>
                    {isSub ?
                        this.loadSubHeader(data, isEdit, pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag)
                        : this.loadEditHeader(type, data, pojoTmp, isAdd, isEdit, pics, imgSrc, tagO, tagT, areasTmp, areasTag)
                    }
                </div>
            </div>
        )
    }

    loadSubHeader = (data, isEdit, pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag) => {
        return (
            data['status'] === 1 ?
                isEdit ?
                    [<button className={"item-header-item"}
                             style={{backgroundColor: '#09af6d'}}
                             onClick={() => {
                                 this.acceptItem(pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag)
                             }}
                    >
                        {"提交"}
                    </button>,
                        <button className={"item-header-item"}
                                style={{backgroundColor: '#5b5b5b'}}
                                onClick={() => {
                                    this.cancelData()
                                }}>
                            {"取消"}
                        </button>]
                    :
                    [<button className={"item-header-item"}
                             style={{backgroundColor: '#09af6d'}}
                             onClick={() => {
                                 this.setState({isEdit: true})
                             }}
                    >
                        {"通过"}
                    </button>,
                        <button className={"item-header-item"}
                                style={{backgroundColor: '#09af6d'}}
                                onClick={() => {
                                    LunchWin(<RejectWin parentId={"menu"} winId={"reject"}
                                                        level={2}
                                                        pojo={pojoTmp} id={pojoTmp.subIndex}
                                                        tag={"reason"}
                                                        handleFun={this.processRejectItem}
                                                        submitFun={this.submitRejectItem}
                                    />, "menu", "reject")
                                }}
                        >
                            {"驳回"}
                        </button>]
                : null
        )
    }

    submitRejectItem = (parentId, winId, level, pojo) => {
        let reason = pojo.reason;
        if (reason) {
            delWin(parentId, winId);
            let winIdN = "auth-reject";
            let host = pojo.auth;
            LunchWin(<AuthWin level={level} parentId={parentId} winId={winIdN}
                              account={host.account} phone={host.phone}
                              backFun={this.processRejectItem}/>, parentId, winIdN);
        }
    }

    processRejectItem = (angle, angleSec, sec, pass, myBack) => {
        let pojo = this.state.pojoTmp;
        this.fillAuth(angle, angleSec, sec, pass, pojo.auth);
        console.log("final reject: ", pojo);

        let operator = new CheckOperator();

        let _this = this;
        const rejectItem: DataBack = {
            successBack(res: any) {
                console.log("驳回res: ", res);
                myBack.callBack();
                let url = window.location.href;
                let toUrl = url.split("?id=")[0];
                successReturn("用户提交已驳回", toUrl);
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        operator.rejectItem(pojo, rejectItem);
    }

    loadEditHeader = (type, data, pojoTmp, isAdd, isEdit, pics, imgSrc, tagO, tagT, areasTmp, areasTag) => {
        return (
            isAdd ?
                <button className={"item-header-item"}
                        style={{backgroundColor: '#09af6d'}}
                        onClick={() => {
                            this.addDataPre(type, pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag)
                        }}
                >
                    {"新增"}
                </button>
                :
                [
                    this.loadHeaderEditPart(type, pojoTmp, isEdit, pics, imgSrc, tagO, tagT, areasTmp, areasTag),
                    <button className={"item-header-item"}
                            style={{backgroundColor: data['isPush'] ? 'rgb(212, 26, 26)' : 'rgb(9, 146, 64)'}}
                            onClick={() => {
                                this.pushDataPre("isPush", data['isPush'])
                            }}
                    >
                        {data['isPush'] ? "下架首页" : "推送首页"}
                    </button>,
                    <button className={"item-header-item"}
                            style={{backgroundColor: data['isAd'] ? 'rgb(212, 26, 26)' : 'rgb(9, 146, 64)'}}
                            onClick={() => {
                                this.advDataPre("isAd", data['isAd'])
                            }}
                    >
                        {data['isAd'] ? "下架广告" : "推送广告"}
                    </button>
                ]
        )
    }

    loadHeaderEditPart = (type, pojoTmp, isEdit, pics, imgSrc, tagO, tagT, areasTmp, areasTag) => {
        console.log("11111", isEdit)
        return (
            isEdit ?
                [<button className={"item-header-item"}
                         style={{backgroundColor: '#09af6d'}}
                         onClick={() => {
                             this.updateDataPre(type, pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag);
                             // this.updateData(this.updateDataPojo(pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag, false))
                             // this.updateData(this.updateDataPojo(pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag))
                         }}
                >
                    {"保存"}
                </button>,
                    <button className={"item-header-item"}
                            style={{backgroundColor: '#5b5b5b'}}
                            onClick={() => {
                                window.location.reload()
                                // this.cancelData()
                            }}>
                        {"取消"}
                    </button>]
                :
                <button className={"item-header-item"}
                        style={{backgroundColor: '#3c80d4'}}
                        onClick={() => {
                            this.setState({isEdit: true})
                        }}>
                    {"编辑"}
                </button>
        )
    }

    acceptItem = (pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag) => {
        let pojo = this.updateDataPojo(pojoTmp, pics, imgSrc, tagO, tagT, areasTmp, areasTag, false);
        this.setState({
            pojoTmp: pojo,
        })
        let parentId = "menu";
        let winId = "accept";
        let host = pojoTmp.auth;
        LunchWin(<AuthWin level={2} parentId={parentId} winId={winId}
                          account={host.account} phone={host.phone}
                          backFun={this.processAcceptItem}/>, parentId, winId);
    }

    processAcceptItem = (angle, angleSec, sec, pass, myBack) => {
        let pojo = this.state.pojoTmp;
        pojo.authPojo = this.fillAuth(angle, angleSec, sec, pass, pojo.auth);
        pojo.pojoStr = JSON.stringify(pojo.pojoStr);
        console.log("final accept: ", pojo);

        let operator = new DataOperator();

        let _this = this;
        const addDataBack: DataBack = {
            successBack(res: any) {
                console.log("新增res: ", res);
                pojo.itemId = res;
                _this.acceptItemFinal(pojo, myBack);
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        operator.addData(addDataBack, pojo)
    }

    acceptItemFinal = (dataPojo, myBack) => {
        let pojo = {
            auth: dataPojo.auth,
            subIndex: dataPojo.subIndex,
            userId: dataPojo.userId,
            itemId: dataPojo.itemId,
            index: dataPojo.index,
        }
        let operator = new CheckOperator();

        let _this = this;
        const acceptItem: DataBack = {
            successBack(res: any) {
                myBack.callBack();
                let url = window.location.href;
                let toUrl = url.split("?id=")[0];
                successReturn("用户提交通过", toUrl);
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        operator.acceptItem(pojo, acceptItem);
    }

    fillAuth = (angle, angleSec, sec, pass, authPojo) => {
        authPojo.anglePhone = angle;
        authPojo.secCode = sec;
        authPojo.angleSec = angleSec;
        authPojo.passCode = pass;
        return authPojo;
    }

    handleKeyMap = (keyMap, name) => {
        if (keyMap && name) {
            return keyMap.split(name)[1];
        } else {
            return keyMap;
        }
    }

    filter;
    handleFilled = (pojo, type) => {
        let data = pojo.pojoStr;

        let list = dataRequiredList.filter(it => it.type === type);
        let requiredList = list[0].required;
        console.log(requiredList);

        let len = requiredList.length;
        for (let i = 0; i < len; i++) {
            console.log(requiredList[i]);
            let isFilled = data[requiredList[i]] !== "" && data[requiredList[i]] !== null && data[requiredList[i]] !== undefined ;
            console.log(data[requiredList[i]], isFilled);
            if (!isFilled) {
                return false;
            }
        }

        // let isNaN = this.filter;
        // let isEmpty = filledList.filter(it => it === "");
        // console.log(isNaN);
        // console.log(isEmpty);
        // let isNaNLen = isNaN.length;
        // let isEmptyLen = isEmpty.length;
        // if (filledList.includes(NaN)) {
        //     console.log("nan")
        // }

        return true;
    }

    loadEditItem = (wrapWidth, isRequired, title, content, remarks) => {
        return (
            <div className={"outcome-edit-line"} style={{minHeight: "50px"}}>
                <div className={"outcome-edit-item"} style={{width: wrapWidth}}>
                    {this.loadEditItemTitle(isRequired, title)}
                    {content}
                    {remarks}
                </div>
            </div>
        )
    }

    loadEditItemTitle = (isRequired, title) => {
        return (
            <span className={"edit-item-title"}>
                {isRequired ? <span className={"required-icon"}>{"*"}</span> : null}
                {title}
            </span>
        )
    }

    loadEditItemInput = (inputWidth, isEdit, readOnly, maxLength, pojoTmp, data, tag, isNum) => {
        return (
            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                   style={{width: inputWidth}}
                   key={tag}
                   value={data[tag]}
                   maxLength={maxLength}
                   readOnly={readOnly}
                   onChange={(e) => {
                       this.handleInput(e, pojoTmp, data, tag, isNum)
                   }}
            />
        )
    }

    loadEditItemSelect = (selectWidth, isEdit, disabled, pojoTmp, data, tag, isNum, selectCt) => {
        return (
            <select className={isEdit ? "search-input" : "search-input-unactivated"}
                    style={{width: selectWidth}}
                    key={tag}
                    value={data[tag]}
                    disabled={disabled}
                    onChange={(e) => {
                        this.handleInput(e, pojoTmp, data, tag, isNum)
                    }}
            >
                {this.loadSelect(selectCt)}
            </select>
        )
    }

}
