import React, {Fragment} from "react";
import "./check.css";
import {genProcessBack, HandleAuthType, InitAuthPojo, storage} from "../../tool/Const";
import EMPTY from "../../assets/img/record_empty.svg";
import CheckParent from "./CheckParent";
import CheckOperator from "./CheckOperator";
import {ArrowLeftOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import Search from "../../tool/Search";
import LunchWin from "../../tool/Tools";
import PicWin from "../../tool/PicWin";
import CommitWin from "./CommitWin";
import AuthWin from "../../tool/AuthWin";
import {delWin} from "../../tool/Luncher";
import {successReturn} from "../../tool/http/ErrorMgr";
import ExpertFrame from "../component/ExpertFrame";
import moment from "moment";

const AuthType = [
    {name: "个人认证", key: 1},
    {name: "企业认证", key: 2},
    {name: "专家认证", key: 3},
];

class CheckCert extends CheckParent {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    loadingSelector = () => {
        let lst = [];
        let options = [];
        AuthType.forEach((t, index) => {
            options.push(
                <option value={t.key} key={index}>
                    {t.name}
                </option>
            )
        })
        lst.push(
            <div className={"log-select"} key={"a"}>
                <span style={{marginRight: "14px"}}>{"认证状态筛选"}</span>
                <select className={"search-input"} style={{cursor: "pointer"}} defaultValue={null}
                        onChange={(e) => {
                            this.sendSelector(e)
                        }}
                >
                    {options}
                </select>
            </div>
        )
        lst.push(
            <div className={"log-select"} key={"b"}>
                <span style={{marginRight: "14px"}}>{"查找用户ID"}</span>
                <Search onChange={this.setText} onClick={this.searchInfo} inputSize={22} hold={"请输入用户ID"}/>
            </div>
        )
        return lst;
    }

    loadSelectByUser = () => {
        let lst = [];
        lst.push(
            <div className={"log-select"} key={"a"}>
                <div className={"item-back"} onClick={() => {window.location.href = "/checkCertification"}}>
                    <ArrowLeftOutlined />
                </div>
            </div>
        )
        lst.push(
            <div className={"log-select"} key={"b"}>
                <span style={{marginRight: "14px"}}>{"查找用户ID"}</span>
                <Search onChange={this.setText} onClick={this.searchInfo} inputSize={22} hold={"请输入用户ID"}/>
            </div>
        )
        return lst;
    }

    loadCt = (data) => {
        let ui = [];
        let list = [];
        let len = data.length;
        if (len > 0) {
            data.forEach((item, index) => {
                let userId = item['userId'];
                let userReal = item['userReal'];
                let authType = HandleAuthType(item['authType']);
                let status = item['status'] === 0 ? "待审核" : (item['status'] === 1 ? "已通过" : "已驳回");
                let perValid = item['perValid'] ? <CheckOutlined/> : <CloseOutlined/>;
                let subTime = item['subTime'];
                let funId = item['authType'] === 3 ? "line-fun-expert" : "line-fun-pass";
                let fun = [<div className={"data-item-fun-open"} style={{background: "#099240"}} id={funId}>
                             {"通过"}
                          </div>,
                          <div className={"data-item-fun-open"} style={{marginLeft: '10px', background: "#d41a1a"}} id={"line-fun-reject"}>
                             {"驳回"}
                          </div>];
                list.push(
                    <div className={"data-item"} key={item + index}
                         id={"line-ct:" + index}
                         onClick={(e) => {
                             this.launchPicWin(e, item)
                         }}
                    >
                        {this.loadLayout(userId, userReal, authType, status, perValid, subTime, fun)}
                    </div>
                )
            })
        } else {
            list.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        ui.push(
            <div>
                <div className={"data-header"}>
                    {this.loadLayout("用户ID", "真实姓名", "认证类型", "认证进度", "个人", "提交日期", "操作")}
                </div>
                {list}
            </div>
        )
        return ui;
    }

    loadCtByUser = (data) => {
        let ui = [];
        let list = [];
        let len = data.length;
        if (len > 0) {
            data.forEach((item, index) => {
                let userId = item['userId'];
                let userReal = item['userReal'];
                let authType = HandleAuthType(item['authType']);
                let status = item['status'] === 0 ? "待审核" : (item['status'] === 1 ? "已通过" : "已驳回");
                let perValid = item['perValid'] ? <CheckOutlined/> : <CloseOutlined/>;
                let subTime = item['subTime'];
                let fun = "无操作";
                list.push(
                    <div className={"data-item"} key={item + index}
                         id={"line-ct:" + index}
                         onClick={(e) => {
                             this.launchPicWin(e, item)
                         }}
                    >
                        {this.loadLayout(userId, userReal, authType, status, perValid, subTime, fun)}
                    </div>
                )
            })
        }
        ui.push(
            <div>
                <div className={"data-header"}>
                    {this.loadLayout("用户ID", "真实姓名", "认证类型", "认证进度", "个人", "提交日期", "操作")}
                </div>
                {list}
            </div>
        )
        return ui;
    }

    loadLayout = (userId, userReal, authType, status, perValid, subTime, fun) => {
        return (
            <Fragment>
                <div className={"data-item-name"} id={"line-ct:userId"} key={"userId"}>
                    {userId}
                </div>
                <div className={"data-item-id"} id={"line-ct:userReal"} key={"userReal"}>
                    {userReal}
                </div>
                <div className={"data-item-type"} id={"line-ct:type"} key={"type"}>
                    {authType}
                </div>
                <div className={"data-item-type"} id={"line-ct:status"} key={"status"}>
                    {status}
                </div>
                <div className={"data-item-type"} id={"line-ct:perValid"} key={"perValid"}>
                    {perValid}
                </div>
                <div className={"data-item-date"} id={"line-ct:date"} key={"date"}>
                    {subTime}
                </div>
                <div className={"data-item-fun"} style={{width: '20%'}} id={"line-ct:fun"} key={"fun"}>
                    {fun}
                </div>
            </Fragment>
        )
    }

    launchPicWin = (e, data) => {
        let pojo = this.state.authCommitPojo;
        if (e.target.id.includes("line-ct")) {
            let id = data['userId'];
            console.log(id);
            if (id) {
                let hostInfo = storage.get("hostInfo");
                let params = {
                    userId: id,
                    auth: InitAuthPojo(hostInfo),
                }

                this.findResource(params);
            }
        } else if (e.target.id.includes("line-fun-pass")) {
            pojo['authType'] = data['authType'];
            pojo['authorizationPojo'] = data;
            this.setState({
                authCommitPojo: pojo,
            })
            console.log("通过");
            let id = "menu";
            let winId = "pass";
            LunchWin(<CommitWin class={"commit-win"} parentId={id} winId={winId}
                                pojo={pojo}
                                funArea={this.funArea}
                                sendConfirm={this.confirmBtn}
                     />, id, winId);
        } else if (e.target.id.includes("line-fun-expert")) {
            let id = "menu";
            let winId = "expert";

            let findDataPojo = {
                clickNum: null,
                expertAreas: '',
                expertBanner: '',
                expertCt: '',
                expertDes: '',
                expertEdu: '',
                expertGeo: '',
                expertHor: '',
                expertId: '',
                expertJob: '',
                expertName: '',
                expertPrice: '',
                expertRoot: '',
                expertSch: '',
                expertTarget: null,
                expertTitle: null,
                isAd: false,
                isPush: false,
                keyMap: '',
                refId: '',
                saveNum: '',
                srcType: '',
            }

            LunchWin(
                <div className={"commit-win"}>
                    {/*<ExpertFrame pojo={Object.assign({}, findDataPojo)} isAdd={true} isSub={false}/>*/}
                </div>
                , id, winId);
        } else {
            pojo['authType'] = data['authType'];
            pojo['authorizationPojo'] = data;
            this.setState({
                authCommitPojo: pojo,
            })
            console.log("驳回");
            let id = "menu";
            let winId = "reject";
            LunchWin(<CommitWin class={"commit-win"} parentId={id} winId={winId}
                                pojo={pojo}
                                funArea={this.rejectArea}
                                sendConfirm={this.rejectBtn}
            />, id, winId);
        }
    }

    funArea = () => {
        let pojo = this.state.authCommitPojo;
        let data = pojo.authorizationPojo;
        let authType = pojo.authType;
        switch (authType) {
            case 1: {
                return (
                    <Fragment>
                        <div className={"commit-ct-item"}>
                            <span style={{width: '80px'}}>{"用户ID"}</span>
                            <span style={{ width: "240px"}}>{data['userId']}</span>
                        </div>
                        <div className={"commit-ct-item"}>
                            <span style={{width: '80px'}}>{"真实姓名"}</span>
                            <span style={{ width: "240px"}}>{data['userReal']}</span>
                        </div>
                    </Fragment>
                )
            }
            case 2: {
                return (
                    <Fragment>
                        <div className={"commit-ct-item"}>
                            <span style={{width: '80px'}}>{"用户ID"}</span>
                            <span style={{ width: "240px"}}>{data['userId']}</span>
                        </div>
                        <div className={"commit-ct-item"}>
                            <span style={{width: '100px'}}>{"认证企业名称"}</span>
                            <input className={"search-input"} style={{ width: "240px"}}
                                   key={"userComp"}
                                   onChange={(e) => {this.handleInput(e, pojo, "userComp", false)}}
                            />
                        </div>
                    </Fragment>
                )
            }
            case 3: {
                return (
                    <Fragment>
                        <div className={"commit-ct-item"}>
                            <span style={{width: '80px'}}>{"用户ID"}</span>
                            <span style={{ width: "240px"}}>{data['userId']}</span>
                        </div>
                        <div className={"commit-ct-item"}>
                            <span style={{width: '140px'}}>{"认证专家预约价格"}</span>
                            <input className={"search-input"} style={{ width: "200px"}}
                                   key={"price"}
                                   onChange={(e) => {this.handleInput(e, pojo, "price", true)}}
                            />
                        </div>
                    </Fragment>
                )
            }
        }
    }

    rejectArea = () => {
        let pojo = this.state.authCommitPojo;
        let data = pojo.authorizationPojo;
        return (
            <Fragment>
                <div className={"commit-ct-item"}>
                    <span style={{width: '80px'}}>{"用户ID"}</span>
                    <span style={{ width: "240px"}}>{data['userId']}</span>
                </div>
                <div className={"commit-ct-item"}>
                    <span style={{width: '80px'}}>{"驳回理由"}</span>
                    <input className={"search-input"} style={{ width: "240px"}}
                           key={"price"}
                           onChange={(e) => {this.handleInput(e, pojo, "notice", false)}}
                    />
                </div>
            </Fragment>
        )
    }

    handleInput = (e, pojo, tag, isNum) => {
        let val = isNum ? Number.parseInt(e.target.value) : e.target.value;
        if (val) {
            pojo[tag] = val;
        }
    }

    confirmBtn = (parentId, winId, pojo) => {
        let data = pojo.authorizationPojo;
        let authPojo = pojo.authPojo;
        let winIdN = "handle";
        delWin(parentId, winId);
        data['status'] = 1;
        LunchWin(<AuthWin level={3} parentId={parentId} winId={winIdN}
                          account={authPojo.account} phone={authPojo.phone}
                          backFun={this.processConfirm} />, parentId, winIdN);
    }

    rejectBtn = (parentId, winId, pojo) => {
        let data = pojo.authorizationPojo;
        let authPojo = pojo.authPojo;
        let winIdN = "handle";
        if (pojo.notice) {
            delWin(parentId, winId);
            data['status'] = 2;
            LunchWin(<AuthWin level={3} parentId={parentId} winId={winIdN}
                              account={authPojo.account} phone={authPojo.phone}
                              backFun={this.processConfirm} />, parentId, winIdN);
        }
    }

    processConfirm = (angle, angleSec, sec, pass, myBack) => {
        let pojo = this.state.authCommitPojo;
        let authPojo = pojo.authPojo;
        this.fillAuth(angle, angleSec, sec, pass, authPojo);
        let data = pojo.authorizationPojo;
        let status = data['status'];
        let tip = status === 1 ? "认证审核已通过" : "认证审核已驳回";

        let operator = new CheckOperator();

        const commitBack: WinBack = {
            callBack() {
                myBack.callBack();
                successReturn(tip, window.location.href);
            }
        }

        operator.commitAuth(pojo, genProcessBack(commitBack, false))
    }

    fillAuth = (angle, angleSec, sec, pass, authPojo) => {
        authPojo.anglePhone = angle;
        authPojo.secCode = sec;
        authPojo.angleSec = angleSec;
        authPojo.passCode = pass;
    }

    // 根据userId获取资源
    findResource = (params) => {
        let operator = new CheckOperator();

        const myBack: WinBack = {
            callDataBack(data: any) {
                LunchWin(<PicWin pics={data}/>, "menu", "pic");
            }
        }

        operator.checkUserAuthMaterials(params, genProcessBack(myBack, true));
    }

    // select Onchange调用方法
    sendSelector = (e) => {
        let val = Number.parseInt(e.target.value);
        if (val) {
            this.findAwaitAuthList(val);
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 判断selectId的变化执行findDataById接口获取id数据
        if (prevState.selectId !== this.state.selectId) {
            this.findAuthByUser(this.state.selectId);
        }
    }

    init = () => {
        let hostInfo = storage.get("hostInfo");
        this.setState({
            authCommitPojo: this.initAuthCommitPojo(hostInfo),
        })

        let url = window.location.href;
        let param = url.split("?id=");
        if (param.length !== 1) {
            let id = param[1];
            this.setState({
                selectId: id,
            })
        } else {
            this.findAwaitAuthList(1)
        }
    }

    initAuthCommitPojo = (hostInfo) => {
        const pojo: AuthCommitPojo = {
            notice: null,
            price: null,
            userComp: null,
            authorizationPojo: null,
            authPojo: InitAuthPojo(hostInfo),
            authType: null,
        }
        return pojo;
    }

    // 根据authType获取对应数据
    findAwaitAuthList = (authType) => {
        let operator = new CheckOperator();

        let hostInfo = storage.get("hostInfo");
        let params = {
            authType: authType,
            auth: InitAuthPojo(hostInfo),
        }

        let _this = this;
        const myBack: WinBack = {
            callDataBack(data: any) {
                _this.setState({
                    data: data,
                    loaded: true,
                })
            }
        }

        operator.findAwaitAuthList(params, genProcessBack(myBack, true))
    }

    // 根据userId获取相关认证
    findAuthByUser = (userId) => {
        let operator = new CheckOperator();

        let hostInfo = storage.get("hostInfo");
        let params = {
            userId: userId,
            auth: InitAuthPojo(hostInfo),
        }

        let _this = this;
        const myBack: WinBack = {
            callDataBack(data: any) {
                _this.setState({
                    data: data,
                    loaded: true,
                })
            }
        }

        operator.checkAuthsByUser(params, genProcessBack(myBack, true));
    }

    render() {
        let data = this.state.data;
        let loaded = this.state.loaded;
        let selectId = this.state.selectId;

        console.log("authCommitPojo: ", this.state.authCommitPojo);

        return (
            <div className={"page-wrap"}>
                {selectId == null ?
                    loaded ?
                        <div className={"page-ct"}>
                            <div className={"data-selector"} id={"selector"}>
                                {this.loadingSelector()}
                            </div>
                            <div className={"data-ct"}>
                                {this.loadCt(data)}
                            </div>
                        </div>
                        : null
                    :
                    loaded ?
                        <div className={"page-ct"}>
                            <div className={"data-selector"} id={"selector"}>
                                {this.loadSelectByUser()}
                            </div>
                            <div className={"data-ct"}>
                                {this.loadCtByUser(data)}
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}

export default CheckCert;