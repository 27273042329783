import React, {Fragment} from "react";
import "./component.css";
import FrameParent from "./FrameParent";
import {ArrowLeftOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {ResourceHeader} from "../../tool/http/Request";
import {itemSrc, itemType, mapToJson, picTypes} from "../../tool/Const";

class NewsFrame extends FrameParent {
    constructor(props) {
        super(props);
        this.state = {
            pojoTmp: JSON.parse(JSON.stringify(this.props.pojo)),  // findDataPojo缓存
            isEdit: false,  // 是否编辑

            pics: new Map(),
            imgSrc: {
                outcomeBanner: '',
                picSrc: [],
            },
            imgUrl: {
                outcomeBanner: '',
                picSrc: [],
            },

            texts: new Map(),
            textsTmp: new Map(),

            selectItem: -1,
            openGeo: false,
            showCity: -1,
        }
    }

    loadEditFrame = (pojoTmp, isAdd, isEdit, ordersTmp, textsTmp, picTmp, imgUrl, imgSrc) => {
        let data = pojoTmp.pojoStr;

        let img = isAdd ? null : ResourceHeader + data['newsId'] + "/" + data['newsBanner'];
        let imgBanner = imgUrl['newsBanner'] ? imgUrl['newsBanner'] : img;
        return (
            <div className={"policy-edit-part-wrap"}>
                <div className={"policy-edit-part"}>
                    {this.loadEditItem('650px', true, '资讯名称',
                        this.loadEditItemInput('540px', isEdit, !isAdd, 30, pojoTmp, data, 'newsName', false))}
                    <div className={"outcome-edit-line"} style={{minHeight: "50px"}}>
                        <div className={"outcome-edit-item"} style={{minWidth: "350px"}}>
                            {this.loadEditItemTitle(true, "发布日期")}
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "60px", marginRight: "5px"}}
                                   key={"year"}
                                   value={this.handleDate.year(data, 'newsDate')}
                                   placeholder={"年份"}
                                   maxLength={4}
                                   readOnly={!isEdit}
                                   onChange={(e) => {this.handleDateInput(e, pojoTmp, data, 'newsDate', 0)}}
                            />
                            <span className={"outcome-item-title"}>{"年"}</span>
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "40px", marginRight: "5px"}}
                                   key={"month"}
                                   value={this.handleDate.month(data, 'newsDate')}
                                   placeholder={"月份"}
                                   maxLength={2}
                                   readOnly={!isEdit}
                                   onChange={(e) => {this.handleDateInput(e, pojoTmp, data, 'newsDate', 1)}}
                            />
                            <span className={"outcome-item-title"}>{"月"}</span>
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "40px", marginRight: "5px"}}
                                   key={"day"}
                                   value={this.handleDate.day(data, 'newsDate')}
                                   placeholder={"日份"}
                                   maxLength={2}
                                   readOnly={!isEdit}
                                   onChange={(e) => {this.handleDateInput(e, pojoTmp, data, 'newsDate', 2)}}
                            />
                            <span className={"outcome-item-title"}>{"日"}</span>
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "50px"}}>
                        <div className={"outcome-edit-item"} style={{width: "400px"}}>
                            {this.loadEditItemTitle(false, "精华推荐")}
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "20px"}}
                                   type={"checkbox"}
                                   key={"isFocus"}
                                   checked={data["isFocus"]}
                                   disabled={!isEdit}
                                   onChange={() => {this.onChangeCheckbox(pojoTmp, data, 'isFocus')}}
                            />
                        </div>
                    </div>
                    {this.loadEditItem('400px', true, '资讯发布者',
                        this.loadEditItemInput('280px', isEdit, !isEdit, 20, pojoTmp, data, 'newsRoot', false))}
                    {this.loadEditItem('240px', true, "资讯来源",
                        this.loadEditItemSelect('160px', isEdit, !isEdit, pojoTmp, data, 'srcType', true, itemSrc))}
                    {this.loadEditItem('300px', true, '初始点击量',
                        this.loadEditItemInput('80px', isEdit, !isEdit, 10, pojoTmp, data, 'clickNum', false))}
                    {this.loadEditItem('300px', true, '初始收藏量',
                        this.loadEditItemInput('80px', isEdit, !isEdit, 10, pojoTmp, data, 'saveNum', false))}
                    <div className={"outcome-edit-line"} style={{minHeight: "200px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"资讯描述"}
                        </span>
                        <div>
                            <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                      style={{height: "200px", minWidth: "600px", fontSize: "14px", padding: "10px"}}
                                      key={"newsDes"}
                                      value={data['newsDes']}
                                      onChange={(e) => {this.handleInput(e, pojoTmp, data, 'newsDes', false)}}
                            />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "200px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"资讯封面"}
                        </span>
                        {
                            data['newsBanner'] ?
                                <div className={"img-wrap"}>
                                    <img className={"img-style contain"} src={imgBanner} alt={""}/>
                                </div>
                                : null
                        }
                        <div className={"img-upload-fun"}>
                            <label htmlFor={"banner"} className={"img-upload-btn"}
                                   style={{pointerEvents: isEdit ? "" : "none"}}
                            >
                                {"点击上传"}
                            </label>
                            <input type={"file"} name={"file"} id={"banner"}
                                   className={"input-file"}
                                   accept={picTypes}
                                   onChange={(e) => {
                                       this.handleImgUpload(e, 1400, 1400, imgSrc, imgUrl, "newsBanner", 1, pojoTmp)
                                   }}
                            />
                        </div>
                    </div>
                    {this.loadBigDataPart(isAdd, isEdit, pojoTmp, "newsId", ordersTmp, textsTmp, picTmp, imgSrc, imgUrl)}
                    <div className={"outcome-edit-line"} style={{minHeight: "150px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            {"资讯关键词"}
                            <span className={"outcome-item-title-tip"}>
                                {"（备注：不需要输入名称、发布日期、发布者、来源）"}
                            </span>
                        </span>
                        <div>
                            <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                      style={{height: "100px", minWidth: "600px", fontSize: "14px", padding: "10px"}}
                                      key={"keyMap"}
                                      value={this.handleKeyMap(data['keyMap'], data['newsName'])}
                                      readOnly={!isEdit}
                                      onChange={(e) => {this.handleInput(e, pojoTmp, data, 'keyMap', false)}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChangeCheckbox = (pojo, data, tag) => {
        data[tag] = !data[tag];
        this.setState({
            pojoTmp: pojo,
        })
    }

    // 更新pojo（policy, news）
    updateDataPojo = (pojoTmp, pics, imgSrc, imgTag, tagT, areasTmp, areasTag, isAdd) => {
        let data = pojoTmp.pojoStr;

        data.keyMap = this.fillKeyMap(pojoTmp);

        // 处理banner和picSrc
        pics = this.handlePics(data, imgSrc, imgTag, null, pics);
        pojoTmp.pics = mapToJson(pics);


        // 处理bigData
        let bigData = data.bigData;
        let bodyPics = bigData.pics;

        String.prototype.endWith = function(endStr) {
            let d = this.length - endStr.length;
            return (
                d >= 0 && this.lastIndexOf(endStr) === d
            )
        }

        bodyPics.forEach(function(value, key) {
            let isEmpty = value.endWith(".jpg");
            if (isEmpty) {
                bodyPics.delete(key);
            }
        })

        bigData.pics = mapToJson(bigData.pics);
        bigData.texts = mapToJson(bigData.texts);

        if (isAdd) {
            pojoTmp.pojoStr = JSON.stringify(data);
        }

        return pojoTmp;
    }

    componentDidMount() {
        this.initBigData();
    }

    render() {
        let pojo = this.props.pojo;
        let isAdd = this.props.isAdd;

        let pojoTmp = this.state.pojoTmp;
        let isEdit = isAdd ? true : this.state.isEdit;

        let pics = this.state.pics;
        let imgSrc = this.state.imgSrc;
        let imgUrl = this.state.imgUrl;

        let ordersTmp = this.state.ordersTmp;
        let textsTmp = this.state.textsTmp;
        let picTmp = this.state.picTmp;
        let bigDataLoaded = this.state.bigDataLoaded;

        console.log("缓存pojo：", pojoTmp);

        return (
            <div className={"page-inner"}>
                {this.loadHeader("news", "/dataNews", pojoTmp, isAdd, isEdit, pics, imgSrc, "newsBanner", null, null, null, false)}
                {bigDataLoaded ? this.loadEditFrame(pojoTmp, isAdd, isEdit, ordersTmp, textsTmp, picTmp, imgUrl, imgSrc) : null}
            </div>
        );
    }
}

export default NewsFrame;