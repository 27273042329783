import {
    CheckOrderByOrderIdUrl,
    CheckOrdersByStatusUrl,
    CheckOrderSizeByIdAndIndexUrl, CheckServiceByOrderUrl, ConfirmOrderUrl, FinishAppointUrl, genPojo,
    sendGetBack, sendPostBack
} from "../../tool/http/Request";

export default class OrderOperator {
    checkOrderSize(back: DataBack, pojo: GetOrderPojo) {
        sendGetBack(CheckOrderSizeByIdAndIndexUrl, pojo, true, back);
    }

    checkOrdersByStatus(back: DataBack, pojo: GetOrderPojo) {
        sendGetBack(CheckOrdersByStatusUrl, pojo, true, back);
    }

    checkOrderById(back: DataBack, pojo: GetOrderPojo) {
        sendGetBack(CheckOrderByOrderIdUrl, pojo, true, back);
    }

    confirmOrder(back: DataBack, pojo: OrderRequestPojo) {
        sendPostBack(genPojo(ConfirmOrderUrl, pojo), true, back);
    }

    getService(back: DataBack, pojo: GetOrderPojo) {
        sendGetBack(CheckServiceByOrderUrl, pojo, true, back);
    }

    finishAppoint(back: DataBack, params) {
        sendGetBack(FinishAppointUrl, params, true, back);
    }
}