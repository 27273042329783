import React, {Component} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Content} from "antd/lib/layout/layout";
import {menuList} from "./tool/Const";
import Test from "./page/Test";

class PageRoutes extends Component {

    // 菜单栏对应url
    loadPath = () => {
        let routeList = [];
        menuList.forEach((item, index) => {
            let subMenu = item.subTitles;
            let subLen = subMenu.length;
            let hasSub = subLen > 0;
            if (hasSub) {
                subMenu.forEach((itemSub, indexSub) => {
                    routeList.push(
                        <Route exact={true} path={itemSub.url} element={itemSub.ct} key={itemSub.title}/>
                    )
                })
            } else {
                routeList.push(
                    <Route exact={true} path={item.url} element={item.ct} key={item.title}/>
                )
            }
        })
        return routeList;
    }

    render() {
        return (
            <Router>
                <Content>
                    {/*跳转时不重复显示主页exact*/}
                    <Routes>
                        <Route exact={true} path={"/test"} element={<Test/>}/>
                        {this.loadPath()}
                    </Routes>
                </Content>
            </Router>
        )
    }
}

export default PageRoutes;