import React, {Component} from "react";

class Test2 extends Component {
    constructor(props, auth, sonFrame) {
        super(props);
        this.state = {
            auth: auth,
            wind: "1",
            frame: sonFrame
        }
    }

    testIt(w) {
        console.log("uuuu")
    }

    // render() {
    //
    // }
}

export default Test2;