import {CloseOutlined} from "@ant-design/icons";
import {delWin} from "../../tool/Luncher";
import React, {useState} from "react";
import {minusUserPtc, plusUserPtc} from "../../tool/http/Request";
import {PtcRate} from "../../tool/Const";
import LunchWin from "../../tool/Tools";

const ptcOp = {add: {type: 1, url: plusUserPtc, name: "增加"}, minus: {type: 2, url: minusUserPtc, name: "扣除"}}

const notices = ["平台福利发放", "账号资金修正", "充值金额调整", "账号资金异常", "系统故障修复"];

export default function PtcWin({props}) {
    const [type, setType] = useState(1);

    const [amount, setAmount] = useState(0);

    const [notice, setNotice] = useState("平台福利发放");

    let {root, win, ptc, total} = props;

    let setOpType = (e) => {
        let val = e.target.value;
        if (val) {
            setType(parseInt(val))
        }
    }

    let loadNoticeOptions = () => {
        let lst = []
        notices.forEach((msg, index) => {
            lst.push(
                <option value={msg} key={index}>
                    {msg}
                </option>
            )
        })
        return lst;
    }

    let loadOptions = () => {
        return (
            [
                <option value={ptcOp.add.type} key={"add"}>
                    {ptcOp.add.name}
                </option>,
                <option value={ptcOp.minus.type} key={"minus"}>
                    {ptcOp.minus.name}
                </option>
            ]
        )
    }

    let setAmountChange = (e) => {
        let val = e.target.value;
        let num = parseInt(val);
        setAmount(num ? num : 0)
    }

    let submit = () => {
        if (type && notice && amount !== 0) {
            console.log(amount)
            console.log(total)
            if (type === ptcOp.minus.type && amount * PtcRate > total) {
                return;
            }
            let url = type === ptcOp.minus.type ? ptcOp.minus.url : ptcOp.add.url;
            ptc(
                {amount: amount, type: type, notice: notice, url: url}
            )
        }
    }

    return (
        <div className={"ptc-adj-win"}>
            <div className={"auth-close"}>
                <CloseOutlined style={{fontSize: "22px", marginRight: "10px"}} onClick={() => {
                    delWin(root, win)
                }}/>
            </div>
            <div className={"vip-delay-win-ct"} style={{height: "210px"}}>
                <div style={{
                    height: "50px",
                    width: "84%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <div>
                        <span className={"vip-win-des"} style={{marginRight: "10px"}}>{"操作类型"}</span>
                        <select value={type} className={"search-input"} style={{width: "80px"}}
                                onChange={(e) => {
                                    setOpType(e)
                                }}>
                            {loadOptions()}
                        </select>
                    </div>
                    <div>
                        <span className={"vip-win-des"}>{"调整金额"}</span>
                        <input className={"search-input"} style={{width: "80px", margin: "0 10px"}} maxLength={8}
                               placeholder={"整数RMB"}
                               onChange={(e) => {
                                   setAmountChange(e)
                               }}/>
                    </div>
                </div>
                <div style={{height: "24px", width: "84%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span style={{fontSize: "12px", color: "red", fontWeight: "bold"}}>{"湃通币转换额: " + (amount * PtcRate) + " ptc"}</span>
                </div>
                <div style={{height: "54px", width: "84%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span className={"vip-win-des"}>{"调整原因"}</span>
                    <select className={"search-input"} style={{width: "256px", margin: "0 10px"}} value={notice}
                           onChange={(e) => {
                               setNotice(e.target.value)
                           }}>
                        {loadNoticeOptions()}
                    </select>
                </div>
                <div style={{marginTop: "10px"}}>
                    <button className={"search-bt"} onClick={() => {
                        submit()
                    }}>{"确认"}</button>
                </div>
            </div>
        </div>
    );
}