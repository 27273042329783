import React, {Component} from "react";
import "./tool.css";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        let len = this.props.inputSize;
        let hold = this.props.hold;

        return (
            <div className={"search-tool"}>
                <div>
                    <input className={"search-input"} id={"search"}
                           placeholder={hold ? hold : ""} maxLength={len}
                           onChange={(e) => {this.props.onChange(e)}}/>
                </div>
                <div>
                    <button className={"search-bt"} onClick={() => {this.props.onClick()}}>{"搜索"}</button>
                </div>
            </div>
        )
    }
}

export default Search;