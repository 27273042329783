import {
    AddSourceDataUrl,
    AdvSourceDataUrl,
    DelDataUrl,
    FindDataByIdUrl,
    FindDataSizeUrl,
    FindDataUrl,
    FindStatisticsByIdUrl,
    genPojo,
    PushSourceDataUrl,
    sendGetBack,
    sendPostBack,
    SwitchDataShowUrl,
    UpdateSourceDataUrl
} from "../../tool/http/Request";

class DataOperator {
    findData = (back: DataBack, mgrRequest: FindDataPojo) => {
        sendPostBack(genPojo(FindDataUrl, mgrRequest), true, back);
    }

    findDataSize = (back: DataBack, mgrRequest: FindDataPojo) => {
        sendPostBack(genPojo(FindDataSizeUrl, mgrRequest), true, back);
    }

    addData = (back: DataBack, mgrRequest: FindDataPojo) => {
        sendPostBack(genPojo(AddSourceDataUrl, mgrRequest), true, back);
    }

    updateData = (back: DataBack, mgrRequest: FindDataPojo) => {
        sendPostBack(genPojo(UpdateSourceDataUrl, mgrRequest), true, back);
    }

    advData = (back: DataBack, mgrRequest: FindDataPojo) => {
        sendPostBack(genPojo(AdvSourceDataUrl, mgrRequest), true, back);
    }

    pushData = (back: DataBack, mgrRequest: FindDataPojo) => {
        sendPostBack(genPojo(PushSourceDataUrl, mgrRequest), true, back);
    }

    switchData = (back: DataBack, mgrRequest: FindDataPojo) => {
        sendPostBack(genPojo(SwitchDataShowUrl, mgrRequest), true, back);
    }

    delData = (back: DataBack, mgrRequest: FindDataPojo) => {
        sendPostBack(genPojo(DelDataUrl, mgrRequest), true, back);
    }

    findDataById = (back: DataBack, mgrRequest: FindDataPojo) => {
        sendPostBack(genPojo(FindDataByIdUrl, mgrRequest), true, back);
    }

    findStatisticsById = (params, back: DataBack) => {
        sendGetBack(FindStatisticsByIdUrl, params, true, back)
    }
}

export default DataOperator;