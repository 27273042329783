import React, {Component} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {delWin} from "../../tool/Luncher";
import "./component.css";
import "../../tool/tool.css";
import {genProcessBack, InitAuth, RemoveLoginInfo, storage} from "../../tool/Const";
import LunchWin from "../../tool/Tools";
import AuthWin from "../../tool/AuthWin";
import {genPojo, sendGetBack, sendPostBack, updateHostLevel, UpdateHostPwdUrl} from "../../tool/http/Request";
import {fail} from "../../tool/http/ErrorMgr";

const inputs = [
    {name: "当前登录密码", key: "old", holder: "请输入当前密码", tag: "oldPass"},
    {name: "更新登录密码", key: "new", holder: "请输入新的密码", tag: "newPass"}
];

class PassUpdater extends Component<null, PassState> {
    constructor(props) {
        super(props);
        this.state = {
            hostBase: null,
            newPass: null,
            oldPass: null,
            loaded: false,
        }
    }

    componentDidMount() {
        let hostInfo = storage.get("hostInfo")
        let account = hostInfo.hostAccount;
        let phone = hostInfo.hostPhone;
        const auth: AuthPojo = InitAuth(account);
        auth.phone = phone;
        const hostBase: HostBase = {
            hostAccount: account,
            hostPhone: phone,
            hostPwd: null,
            authPojo: auth,
        }
        this.setState({
            hostBase: hostBase,
            loaded: true,
        })
    }

    handleInput = (e, tag) => {
        let val = e.target.value;
        if(val) {
            let state = this.state;
            state[tag] = val;
            this.setState(state)
        }
    }

    loadInputs = () => {
        let lst = [];
        inputs.forEach((item) => {
            lst.push(
                <div className={"pass-update-line"}>
                    <span>{item.name}</span>
                    <input className={"search-input"} style={{marginLeft: "20px", width: "240px"}}
                           placeholder={item.holder} key={item.key} onChange={(e) => {
                        this.handleInput(e, item.tag)
                    }}/>
                </div>
            )
        })
        return lst;
    }

    submitPassAuth = (parentId, winId, host) => {
        if (this.state.newPass && this.state.oldPass) {
            delWin(parentId, winId)
            let winIdN = "auth-pass";
            LunchWin(<AuthWin level={4} parentId={parentId} phone={host.hostPhone} winId={winIdN}
                              backFun={this.processPassChange} account={host.hostAccount}/>, parentId, winIdN);
        }
    }

    processPassChange = (angle, angleSec, sec, pass, myBack) => {
        let host = this.state.hostBase;
        this.fillAuth(angle, angleSec, sec, pass, host);
        let request = {
            pwd: this.state.newPass,
            hostAccount: host.hostAccount,
            hostPhone: host.hostPhone,
            hostPwd: this.state.oldPass,
            authPojo: host.authPojo,
        }
        const successBack: WinBack = {
            callBack() {
                myBack.callBack();
                RemoveLoginInfo();
                window.location.reload();
            }
        }
        sendPostBack(genPojo(UpdateHostPwdUrl, request), true, genProcessBack(successBack, false));
    }

    fillAuth = (angle, angleSec, sec, pass, host) => {
        let authPojo = host.authPojo;
        authPojo.anglePhone = angle;
        authPojo.secCode = sec;
        authPojo.angleSec = angleSec;
        authPojo.passCode = pass;
    }

    render() {
        let parentId = this.props.parentId;
        let winId = this.props.winId;
        let host = this.state.hostBase;
        let loaded = this.state.loaded;
        console.log(host)
        return <div className={"pass-update-win"}>
            <div className={"auth-close"}>
                <CloseOutlined style={{fontSize: "22px", marginRight: "10px"}} onClick={() => {
                    delWin(parentId, winId)
                }}/>
            </div>
            {loaded ?
                <div className={"pass-update-ct"}>
                    <div style={{height: "20px"}}/>
                    {this.loadInputs()}
                    <div className={"pass-update-line"}>
                        <button className={"search-bt"} onClick={() => {this.submitPassAuth(parentId, winId, host)}}>
                            {"确认"}
                        </button>
                    </div>
                </div>
                :
                null
            }
        </div>

    }
}

export default PassUpdater;