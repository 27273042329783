import React from "react";
import "./component.css";
import "../../tool/tool.css";
import {
    AreaMap, HandleCopWay,
    HandleItemType,
    HandleSrcType,
    itemAreas,
    itemCop,
    itemSrc,
    itemStage,
    itemType,
    picTypes
} from "../../tool/Const";
import GeoFilter from "../../tool/GeoFilter";
import {ResourceHeader} from "../../tool/http/Request";
import FrameParent from "./FrameParent";

class OutcomeFrame extends FrameParent {
    constructor(props) {
        super(props);
        this.state = {
            pojoTmp: JSON.parse(JSON.stringify(this.props.pojo)),  // findDataPojo缓存
            isEdit: false,  // 是否编辑
            areas: JSON.parse(JSON.stringify(itemAreas)),  // 行业选择
            areasTmp: '',  // 行业选择缓存
            areasLoad: false,  // 行业加载完成

            pics: new Map(),
            imgSrc: {
                outcomeBanner: '',
                picSrc: [],
            },
            imgUrl: {
                outcomeBanner: '',
                picSrc: [],
            },

            selectItem: -1,
            openGeo: false,
            showCity: -1,
        }
    }

    loadEdit = (pojoTmp, isAdd, isEdit, areasTmp, areasLoad, imgUrl, imgSrc, isSub) => {
        let data = pojoTmp.pojoStr;
        let img = ResourceHeader + data['outcomeId'] + "/" + data['outcomeBanner'];
        let imgBanner = imgUrl['outcomeBanner'] ? imgUrl['outcomeBanner'] : img;
        return (
            <div className={"policy-edit-part-wrap"}>
                <div className={"policy-edit-part"}>
                    {this.loadEditItem('650px', true, '成果名称',
                        this.loadEditItemInput('540px', isEdit, !isAdd, 30, pojoTmp, data, 'outcomeName', false))}
                    <div className={"outcome-edit-line"} style={{minHeight: "50px"}}>
                        <div className={"outcome-edit-item"} style={{minWidth: "450px"}}>
                            {this.loadEditItemTitle(true, "发布日期")}
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "60px", marginRight: "5px"}}
                                   key={"year"}
                                   value={this.handleDate.year(data, 'relDate')}
                                   maxLength={4}
                                   readOnly={!isEdit}
                                   onChange={(e) => {this.handleDateInput(e, pojoTmp, data, 'relDate', 0)}}
                            />
                            <span className={"outcome-item-title"}>{"年"}</span>
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "50px", marginRight: "5px"}}
                                   key={"month"}
                                   value={this.handleDate.month(data, 'relDate')}
                                   maxLength={2}
                                   readOnly={!isEdit}
                                   onChange={(e) => {this.handleDateInput(e, pojoTmp, data, 'relDate', 1)}}
                            />
                            <span className={"outcome-item-title"}>{"月"}</span>
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "50px", marginRight: "5px"}}
                                   key={"day"}
                                   value={this.handleDate.day(data, 'relDate')}
                                   maxLength={2}
                                   readOnly={!isEdit}
                                   onChange={(e) => {this.handleDateInput(e, pojoTmp, data, 'relDate', 2)}}
                            />
                            <span className={"outcome-item-title"}>{"日"}</span>
                        </div>
                    </div>
                    {this.loadEditItem('240px', true, "成果种类",
                        this.loadEditItemSelect('160px', isEdit, !isEdit, pojoTmp, data, 'outcomeType', true, itemType))}
                    {this.loadEditItem('240px', true, "成果来源",
                        this.loadEditItemSelect('160px', isEdit, !isEdit, pojoTmp, data, 'srcType', true, itemSrc))}
                    {this.loadEditItem('auto', true, '专利号',
                        this.loadEditItemInput('200px', isEdit, !isEdit, 20, pojoTmp, data, 'patentNo', false))}
                    <div className={"outcome-edit-line"} style={{minHeight: "50px"}}>
                        <div className={"outcome-edit-item"}>
                            {this.loadEditItemTitle(true, "地理位置")}
                            <GeoFilter options={data['outcomeGeo']}
                                       parent={this}
                                       areaClass={isEdit ? "filter-area" : "filter-area-unactivated"}
                                       map={AreaMap}
                                       isSub={true}
                                       mainType={null}
                                       isEdit={isEdit}
                                       setGeo={this.setGeo}
                            />
                        </div>
                    </div>
                    {this.loadEditItem('400px', true, '成果发布者',
                        this.loadEditItemInput('200px', isEdit, !isEdit, 30, pojoTmp, data, 'outcomeRoot', false))}
                    {this.loadEditItem('240px', true, "成果阶段",
                        this.loadEditItemSelect('140px', isEdit, !isEdit, pojoTmp, data, 'outcomeStage', false, itemStage))}
                    {this.loadEditItem('240px', true, "合作方式",
                        this.loadEditItemSelect('140px', isEdit, !isEdit, pojoTmp, data, 'copWay', true, itemCop))}
                    {this.loadEditItem('500px', true, '成果价格',
                        this.loadEditItemInput('140px', isEdit, !isEdit, 20, pojoTmp, data, 'outcomePrice', false),
                        <span className={"outcome-item-title-tip"}>{"（价格如果是面议，请输入数字0）"}</span>)}
                    {this.loadEditItem('300px', true, '初始点击量',
                        this.loadEditItemInput('80px', isEdit, !isEdit, 10, pojoTmp, data, 'clickNum', false))}
                    {this.loadEditItem('300px', true, '初始收藏量',
                        this.loadEditItemInput('80px', isEdit, !isEdit, 10, pojoTmp, data, 'saveNum', false))}
                    {this.loadEditItem('400px', false, '关联用户id',
                        this.loadEditItemInput('280px', isEdit, !isAdd, 22, pojoTmp, data, 'refId', false))}
                    <div className={"outcome-edit-line"} style={{minHeight: "100px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 0"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"成果领域"}
                        </span>
                        <div>
                            {areasLoad ? this.loadBoxes(areasTmp, isEdit, data, "outcomeAreas") : null}
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "120px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            <span className={"required-icon"}>{"*"}</span>
                             {"成果封面"}
                        </span>
                        {
                            data['outcomeBanner'] ?
                                <div className={"img-wrap"}>
                                    <img className={"img-style contain"} src={imgBanner} alt={""}/>
                                </div>
                                : null
                        }
                        <div className={"img-upload-fun"}>
                            <label htmlFor={"banner"} className={"img-upload-btn"}
                                   style={{pointerEvents: isEdit ? "" : "none"}}
                            >
                                {"点击上传"}
                            </label>
                            <input type={"file"} name={"file"} id={"banner"}
                                   className={"input-file"}
                                   accept={picTypes}
                                   onChange={(e) => {
                                       this.handleImgUpload(e, 1400, 1400, imgSrc, imgUrl, "outcomeBanner", 1, pojoTmp)
                                   }}
                            />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "120px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"成果素材图"}
                        </span>
                        <div className={"imgs-wrap"}>
                            {this.loadPicSrc(data, imgUrl, "outcomeId")}
                        </div>
                        <div className={"img-upload-fun"}>
                            <label htmlFor={"picSrc"} className={"img-upload-btn"}
                                   style={{pointerEvents: isEdit ? "" : "none"}}
                            >
                                {"点击上传"}
                            </label>
                            <input type={"file"} name={"file"} id={"picSrc"}
                                   className={"input-file"}
                                   accept={picTypes}
                                   onChange={(e) => {
                                       this.handleImgUpload(e, 1400, 1400, imgSrc, imgUrl, "picSrc", 2, pojoTmp)
                                   }}
                            />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "200px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"成果描述"}
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{height: "150px", minWidth: "600px", fontSize: "14px", padding: "10px"}}
                                          key={"outcomeDes"}
                                          value={data['outcomeDes']}
                                          readOnly={!isEdit}
                                          onChange={(e) => {this.handleInput(e, pojoTmp, data, 'outcomeDes', false)}}
                                />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "300px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"成果运用"}
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{height: "600px", minWidth: "600px", fontSize: "14px", padding: "10px"}}
                                          key={"usageCt"}
                                          value={data['usageCt']}
                                          readOnly={!isEdit}
                                          onChange={(e) => {this.handleInput(e, pojoTmp, data, 'usageCt', false)}}
                                />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "300px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            {"成果前景"}
                            <span className={"outcome-item-title-tip"}>
                                {"（备注：如果没有相关内容，请填写暂无）"}
                            </span>
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{height: "600px", minWidth: "600px", fontSize: "14px", padding: "10px"}}
                                          key={"futureCt"}
                                          value={data['futureCt']}
                                          readOnly={!isEdit}
                                          onChange={(e) => {this.handleInput(e, pojoTmp, data, 'futureCt', false)}}
                                />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "150px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            {"需求关键词"}
                            <span className={"outcome-item-title-tip"}>
                                {"（备注：不需要输入名称、发布日期、种类、来源、地理位置、发布者、阶段、合作方式、价格、领域）"}
                            </span>
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{height: "100px", minWidth: "600px", fontSize: "14px", padding: "10px"}}
                                          key={"keyMap"}
                                          value={this.handleKeyMap(data['keyMap'], data['outcomeName'])}
                                          readOnly={!isEdit}
                                          onChange={(e) => {this.handleInput(e, pojoTmp, data, 'keyMap', false)}}
                                />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // geoFilter调用方法
    setGeo = (geo) => {
        this.setInitGeo(geo, "outcomeGeo");
    }

    componentDidMount() {
        this.initCheckByArea('outcomeAreas');
    }

    render() {
        let pojo = this.props.pojo;
        let isAdd = this.props.isAdd;
        let isSub = this.props.isSub;

        let pojoTmp = this.state.pojoTmp;
        let isEdit = isAdd ? true : this.state.isEdit;
        let areasTmp = this.state.areasTmp;
        let areasLoad = this.state.areasLoad;

        let pics = this.state.pics;
        let imgSrc = this.state.imgSrc;
        let imgUrl = this.state.imgUrl;

        console.log("pojo props：", pojo);
        console.log("pojo 备份：", pojoTmp);
        // console.log("修改的pics：", pics);

        let url = isSub ? "/checkOutcome" : "/dataOutcome";

        return (
            <div className={"page-inner"}>
                {this.loadHeader("outcome", url, pojoTmp, isAdd, isEdit, pics, imgSrc, "outcomeBanner", "picSrc", areasTmp, "outcomeAreas", isSub)}
                {this.loadEdit(pojoTmp, isAdd, isEdit, areasTmp, areasLoad, imgUrl, imgSrc, isSub)}
            </div>
        )
    }
}

export default OutcomeFrame;