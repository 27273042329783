import React, {Fragment} from "react";
import "./component.css";
import "../../tool/tool.css";
import {ResourceHeader} from "../../tool/http/Request";
import UPLOAD from "../../assets/img/upload-sample.jpg";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {AreaMap, itemAreas, itemCop, itemSrc, itemType, picTypes} from "../../tool/Const";
import GeoFilter from "../../tool/GeoFilter";
import FrameParent from "./FrameParent";

class DemandFrame extends FrameParent<FrameProps, FrameState> {
    constructor(props) {
        super(props);
        this.state = {
            pojoTmp: JSON.parse(JSON.stringify(this.props.pojo)),  // findDataPojo缓存
            isEdit: false,  // 是否编辑
            areas: JSON.parse(JSON.stringify(itemAreas)),  // 行业选择
            areasTmp: '',  // 行业选择缓存
            areasLoad: false,  // 行业加载完成

            pics: new Map(),
            imgSrc: {
                demandBanner: '',
                picSrc: [],
            },
            imgUrl: {
                demandBanner: '',
                picSrc: [],
            },

            selectItem: -1,
            openGeo: false,
            showCity: -1,
        }
    }

    loadEdit = (pojoTmp, isAdd, isEdit, areasTmp, areasLoad, imgUrl, imgSrc) => {
        let data = pojoTmp.pojoStr;
        let img = ResourceHeader + data['demandId'] + "/" + data['demandBanner'];
        let imgBanner = imgUrl['demandBanner'] ? imgUrl['demandBanner'] : img;
        return (
            <div className={"policy-edit-part-wrap"}>
                <div className={"policy-edit-part"}>
                    {this.loadEditItem('650px', true, '需求名称',
                        this.loadEditItemInput('540px', isEdit, !isAdd, 30, pojoTmp, data, 'demandName', false))}
                    {/*<div className={"outcome-edit-line"} style={{minHeight: "50px"}}>*/}
                        {/*<div className={"outcome-edit-item"} style={{minWidth: "650px"}}>*/}
                        {/*    <span className={"required-icon"}>{"*"}</span>*/}
                        {/*    <span className={"outcome-item-title"}>{"需求名称"}</span>*/}
                        {/*    <input className={isEdit ? "search-input" : "search-input-unactivated"}*/}
                        {/*           style={{width: "540px"}}*/}
                        {/*           key={"demandName"}*/}
                        {/*           value={data['demandName']}*/}
                        {/*           placeholder={"请输入科技需求名称"}*/}
                        {/*           maxLength={30}*/}
                        {/*           readOnly={!isAdd}*/}
                        {/*           onChange={(e) => {this.handleInput(e, pojoTmp, data, 'demandName', false)}}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    <div className={"outcome-edit-line"} style={{minHeight: "50px"}}>
                        <div className={"outcome-edit-item"} style={{minWidth: "380px"}}>
                            {this.loadEditItemTitle(true, "发布日期")}
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "60px", marginRight: "5px"}}
                                   key={"year"}
                                   value={this.handleDate.year(data, 'relDate')}
                                   placeholder={"年份"}
                                   maxLength={4}
                                   readOnly={!isEdit}
                                   onChange={(e) => {
                                       this.handleDateInput(e, pojoTmp, data, 'relDate', 0)
                                   }}
                            />
                            <span className={"outcome-item-title"}>{"年"}</span>
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "50px", marginRight: "5px"}}
                                   key={"month"}
                                   value={this.handleDate.month(data, 'relDate')}
                                   placeholder={"月份"}
                                   maxLength={2}
                                   readOnly={!isEdit}
                                   onChange={(e) => {
                                       this.handleDateInput(e, pojoTmp, data, 'relDate', 1)
                                   }}
                            />
                            <span className={"outcome-item-title"}>{"月"}</span>
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "50px", marginRight: "5px"}}
                                   key={"day"}
                                   value={this.handleDate.day(data, 'relDate')}
                                   placeholder={"日份"}
                                   maxLength={2}
                                   readOnly={!isEdit}
                                   onChange={(e) => {
                                       this.handleDateInput(e, pojoTmp, data, 'relDate', 2)
                                   }}
                            />
                            <span className={"outcome-item-title"}>{"日"}</span>
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "50px"}}>
                        <div className={"outcome-edit-item"} style={{minWidth: "380px"}}>
                            {this.loadEditItemTitle(true, "完成日期")}
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "60px", marginRight: "5px"}}
                                   key={"year"}
                                   value={this.handleDate.year(data, 'compDate')}
                                   placeholder={"年份"}
                                   maxLength={4}
                                   readOnly={!isEdit}
                                   onChange={(e) => {
                                       this.handleDateInput(e, pojoTmp, data, 'compDate', 0)
                                   }}
                            />
                            <span className={"outcome-item-title"}>{"年"}</span>
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "50px", marginRight: "5px"}}
                                   key={"month"}
                                   value={this.handleDate.month(data, 'compDate')}
                                   placeholder={"月份"}
                                   maxLength={2}
                                   readOnly={!isEdit}
                                   onChange={(e) => {
                                       this.handleDateInput(e, pojoTmp, data, 'compDate', 1)
                                   }}
                            />
                            <span className={"outcome-item-title"}>{"月"}</span>
                            <input className={isEdit ? "search-input" : "search-input-unactivated"}
                                   style={{width: "50px", marginRight: "5px"}}
                                   key={"day"}
                                   value={this.handleDate.day(data, 'compDate')}
                                   placeholder={"日份"}
                                   maxLength={2}
                                   readOnly={!isEdit}
                                   onChange={(e) => {
                                       this.handleDateInput(e, pojoTmp, data, 'compDate', 2)
                                   }}
                            />
                            <span className={"outcome-item-title"}>{"日"}</span>
                        </div>
                    </div>
                    {this.loadEditItem('380px', true, "需求种类",
                        this.loadEditItemSelect('160px', isEdit, !isEdit, pojoTmp, data, 'demandType', true, itemType))}
                    {/*<div className={"outcome-edit-line"} style={{minHeight: "50px"}}>*/}
                        {/*<div className={"outcome-edit-item"} style={{width: "380px"}}>*/}
                        {/*    {this.loadEditItemTitle(true, "需求种类")}*/}
                        {/*    <select className={isEdit ? "search-input" : "search-input-unactivated"}*/}
                        {/*            style={{width: "160px"}}*/}
                        {/*            key={"demandType"}*/}
                        {/*            value={data['demandType']}*/}
                        {/*            disabled={!isEdit}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                this.handleInput(e, pojoTmp, data, 'demandType', true)*/}
                        {/*            }}*/}
                        {/*    >*/}
                        {/*        {this.loadSelect(itemType)}*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {this.loadEditItem('380px', true, "需求来源",
                        this.loadEditItemSelect('160px', isEdit, !isEdit, pojoTmp, data, 'srcType', true, itemSrc))}
                    {/*<div className={"outcome-edit-line"} style={{minHeight: "50px"}}>*/}
                    {/*    <div className={"outcome-edit-item"} style={{width: "380px"}}>*/}
                    {/*        {this.loadEditItemTitle(true, "需求来源")}*/}
                    {/*        <select className={isEdit ? "search-input" : "search-input-unactivated"}*/}
                    {/*                style={{width: "160px"}}*/}
                    {/*                key={"srcType"}*/}
                    {/*                value={data['srcType']}*/}
                    {/*                disabled={!isEdit}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    this.handleInput(e, pojoTmp, data, 'srcType', true)*/}
                    {/*                }}*/}
                    {/*        >*/}
                    {/*            {this.loadSelect(itemSrc)}*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={"outcome-edit-line"} style={{minHeight: "50px"}}>
                        <div className={"outcome-edit-item"}>
                            {this.loadEditItemTitle(true, "地理位置")}
                            <GeoFilter options={data['demandGeo']} parent={this}
                                       areaClass={isEdit ? "filter-area" : "filter-area-unactivated"}
                                       map={AreaMap} isSub={true}
                                       mainType={null}
                                       isEdit={isEdit} setGeo={this.setGeo}
                            />
                        </div>
                    </div>
                    {this.loadEditItem('400px', true, "需求发布者",
                        this.loadEditItemInput('280px', isEdit, !isEdit, 30, pojoTmp, data, 'demandRoot', false))}
                    {/*<div className={"outcome-edit-line"} style={{minHeight: "50px"}}>*/}
                        {/*<div className={"outcome-edit-item"} style={{width: "400px"}}>*/}
                        {/*    <span className={"required-icon"}>{"*"}</span>*/}
                        {/*    <span className={"outcome-item-title"}>{"需求发布者"}</span>*/}
                        {/*    <input className={isEdit ? "search-input" : "search-input-unactivated"}*/}
                        {/*           style={{width: "280px"}}*/}
                        {/*           key={"demandRoot"}*/}
                        {/*           value={data['demandRoot']}*/}
                        {/*           placeholder={"请输入科技需求发布者"}*/}
                        {/*           maxLength={30}*/}
                        {/*           readOnly={!isEdit}*/}
                        {/*           onChange={(e) => {this.handleInput(e, pojoTmp, data, 'demandRoot', false)}}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {this.loadEditItem('380px', true, "合作方式",
                        this.loadEditItemSelect('160px', isEdit, !isEdit, pojoTmp, data, 'copWay', true, itemCop))}
                    {/*<div className={"outcome-edit-line"} style={{minHeight: "50px"}}>*/}
                    {/*    <div className={"outcome-edit-item"} style={{width: "380px"}}>*/}
                    {/*        {this.loadEditItemTitle(true, "合作方式")}*/}
                    {/*        <select className={isEdit ? "search-input" : "search-input-unactivated"}*/}
                    {/*                style={{width: "160px"}}*/}
                    {/*                key={"copWay"}*/}
                    {/*                value={data['copWay']}*/}
                    {/*                disabled={!isEdit}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    this.handleInput(e, pojoTmp, data, 'copWay', true)*/}
                    {/*                }}*/}
                    {/*        >*/}
                    {/*            {this.loadSelect(itemCop)}*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {this.loadEditItem('500px', true, '需求价格',
                        this.loadEditItemInput('150px', isEdit, !isEdit, 20, pojoTmp, data, 'demandPrice', false),
                        <span className={"outcome-item-title-tip"}>{"（价格如果是面议，请输入数字0）"}</span>)}
                    {/*<div className={"outcome-edit-line"} style={{minHeight: "50px"}}>*/}
                    {/*    <div className={"outcome-edit-item"} style={{width: "500px"}}>*/}
                    {/*        {this.loadEditItemTitle(true, "需求价格")}*/}
                    {/*        <input className={isEdit ? "search-input" : "search-input-unactivated"}*/}
                    {/*               style={{width: "150px"}}*/}
                    {/*               key={"demandPrice"}*/}
                    {/*               value={data['demandPrice']}*/}
                    {/*               maxLength={20}*/}
                    {/*               readOnly={!isEdit}*/}
                    {/*               onChange={(e) => {*/}
                    {/*                   this.handleInput(e, pojoTmp, data, 'demandPrice', false)*/}
                    {/*               }}*/}
                    {/*        />*/}
                    {/*        <span className={"outcome-item-title-tip"}>{"（价格如果是面议，请输入数字0）"}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {this.loadEditItem('300px', true, '初始点击量',
                        this.loadEditItemInput('80px', isEdit, !isEdit, 10, pojoTmp, data, 'clickNum', false))}
                    {this.loadEditItem('300px', true, '初始收藏量',
                        this.loadEditItemInput('80px', isEdit, !isEdit, 10, pojoTmp, data, 'saveNum', false))}
                    {/*<div className={"outcome-edit-line"} style={{minHeight: "50px"}}>*/}
                    {/*    <div className={"outcome-edit-item"} style={{width: "300px"}}>*/}
                    {/*        {this.loadEditItemTitle(true, "初始点击量")}*/}
                    {/*        <input className={isEdit ? "search-input" : "search-input-unactivated"}*/}
                    {/*               style={{width: "80px"}}*/}
                    {/*               key={"clickNum"}*/}
                    {/*               value={data['clickNum']}*/}
                    {/*               readOnly={!isEdit}*/}
                    {/*               onChange={(e) => {*/}
                    {/*                   this.handleInput(e, pojoTmp, data, 'clickNum', false)*/}
                    {/*               }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className={"outcome-edit-item"} style={{width: "300px"}}>*/}
                    {/*        {this.loadEditItemTitle(true, "初始收藏量")}*/}
                    {/*        <input className={isEdit ? "search-input" : "search-input-unactivated"}*/}
                    {/*               style={{width: "80px"}}*/}
                    {/*               key={"saveNum"}*/}
                    {/*               value={data['saveNum']}*/}
                    {/*               maxLength={10}*/}
                    {/*               readOnly={!isEdit}*/}
                    {/*               onChange={(e) => {*/}
                    {/*                   this.handleInput(e, pojoTmp, data, 'saveNum', false)*/}
                    {/*               }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {this.loadEditItem('400px', false, '关联用户id',
                        this.loadEditItemInput('280px', isEdit, !isAdd, 22, pojoTmp, data, 'refId', false))}
                    {/*<div className={"outcome-edit-line"} style={{minHeight: "50px"}}>*/}
                    {/*    <div className={"outcome-edit-item"} style={{width: "400px"}}>*/}
                    {/*        {this.loadEditItemTitle(false, "关联用户id")}*/}
                    {/*        <input className={isEdit ? "search-input" : "search-input-unactivated"}*/}
                    {/*               style={{width: "280px"}}*/}
                    {/*               key={"refId"}*/}
                    {/*               value={data['refId']}*/}
                    {/*               readOnly={!isAdd}*/}
                    {/*               onChange={(e) => {*/}
                    {/*                   this.handleInput(e, pojoTmp, data, 'refId', false)*/}
                    {/*               }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={"outcome-edit-line"} style={{minHeight: "100px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 0"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"需求领域"}
                        </span>
                        <div>
                            {areasLoad ? this.loadBoxes(areasTmp, isEdit, data, "demandAreas") : null}
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "100px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"需求封面"}
                        </span>
                        {
                            data['demandBanner'] ?
                                <div className={"img-wrap"}>
                                    <img className={"img-style contain"} src={imgBanner} alt={""}/>
                                </div>
                                : null
                        }
                        <div className={"img-upload-fun"}>
                            <label htmlFor={"banner"} className={"img-upload-btn"}
                                   style={{pointerEvents: isEdit ? "" : "none"}}
                            >
                                {"点击上传"}
                            </label>
                            <input type={"file"} name={"file"} id={"banner"}
                                   className={"input-file"}
                                   accept={picTypes}
                                   onChange={(e) => {
                                       this.handleImgUpload(e, 1400, 1400, imgSrc, imgUrl, "demandBanner", 1, pojoTmp)
                                   }}
                            />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "200px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            <span className={"required-icon"}>{"*"}</span>
                            {"需求简介"}
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{
                                              height: "150px",
                                              minWidth: "600px",
                                              fontSize: "14px",
                                              padding: "10px"
                                          }}
                                          key={"demandDes"}
                                          value={data['demandDes']}
                                          readOnly={!isEdit}
                                          onChange={(e) => {
                                              this.handleInput(e, pojoTmp, data, 'demandDes', false)
                                          }}
                                />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "300px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                             <span className={"required-icon"}>{"*"}</span>
                            {"需求详情"}
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{
                                              height: "600px",
                                              minWidth: "600px",
                                              fontSize: "14px",
                                              padding: "10px"
                                          }}
                                          key={"demandCt"}
                                          value={data['demandCt']}
                                          readOnly={!isEdit}
                                          onChange={(e) => {
                                              this.handleInput(e, pojoTmp, data, 'demandCt', false)
                                          }}
                                />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "300px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            {"需求拓展描述"}
                            <span className={"outcome-item-title-tip"}>
                                {"（备注：如果没有相关内容，请填写暂无）"}
                            </span>
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{
                                              height: "600px",
                                              minWidth: "600px",
                                              fontSize: "14px",
                                              padding: "10px"
                                          }}
                                          key={"extraInfo"}
                                          value={data['extraInfo']}
                                          readOnly={!isEdit}
                                          onChange={(e) => {
                                              this.handleInput(e, pojoTmp, data, 'extraInfo', false)
                                          }}
                                />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "300px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            {"对接承接方要求描述"}
                            <span className={"outcome-item-title-tip"}>
                                {"（备注：如果没有相关内容，请填写暂无）"}
                            </span>
                        </span>
                        <div>
                                <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                          style={{
                                              height: "600px",
                                              minWidth: "600px",
                                              fontSize: "14px",
                                              padding: "10px"
                                          }}
                                          key={"accepterRequest"}
                                          value={data['accepterRequest']}
                                          readOnly={!isEdit}
                                          onChange={(e) => {
                                              this.handleInput(e, pojoTmp, data, 'accepterRequest', false)
                                          }}
                                />
                        </div>
                    </div>
                    <div className={"outcome-edit-line"} style={{minHeight: "150px", flexDirection: "column"}}>
                        <span className={"edit-item-title flex-start"} style={{padding: "20px 0 10px"}}>
                            {"需求关键词"}
                            <span className={"outcome-item-title-tip"}>
                                {"（备注：不需要输入名称、发布日期、完成日期、种类、来源、地理位置、发布者、合作方式、价格、领域）"}
                            </span>
                        </span>
                        <div>
                            <textarea className={isEdit ? "search-input" : "search-input-unactivated"}
                                      style={{
                                          height: "100px",
                                          minWidth: "600px",
                                          fontSize: "14px",
                                          padding: "10px"
                                      }}
                                      key={"keyMap"}
                                      value={this.handleKeyMap(data['keyMap'], data['demandName'])}
                                      readOnly={!isEdit}
                                      onChange={(e) => {
                                          this.handleInput(e, pojoTmp, data, 'keyMap', false)
                                      }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // geoFilter调用方法
    setGeo = (geo) => {
        this.setInitGeo(geo, "demandGeo")
    }

    componentDidMount() {
        this.initCheckByArea('demandAreas');
    }

    render() {
        let pojo = this.props.pojo;
        let isAdd = this.props.isAdd;
        let isSub = this.props.isSub;

        let pojoTmp = this.state.pojoTmp;
        let isEdit = isAdd ? true : this.state.isEdit;
        let areasTmp = this.state.areasTmp;
        let areasLoad = this.state.areasLoad;

        let pics = this.state.pics;
        let imgSrc = this.state.imgSrc;
        let imgUrl = this.state.imgUrl;

        let url = isSub ? "/checkDemand" : "/dataDemand";

        console.log("pojo props：", pojo);
        console.log("pojo 备份：", pojoTmp);

        return (
            <div className={"page-inner"}>
                {this.loadHeader("demand", url, pojoTmp, isAdd, isEdit, pics, imgSrc, "demandBanner", null, areasTmp, "demandAreas", isSub)}
                {this.loadEdit(pojoTmp, isAdd, isEdit, areasTmp, areasLoad, imgUrl, imgSrc)}
            </div>
        );
    }
}

export default DemandFrame;