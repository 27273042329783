import React, {Component} from 'react';
import './App.css';
import Page from "./page/Page";
import PageRoutes from "./PageRoutes";
import Test from "./page/Test";

class App extends Component {

    render() {
        let url = window.location.href;
        let urls = url.split("/");
        let isTest = urls[urls.length - 1] === "test";
        return (
            <div className="App">
                {isTest ? <PageRoutes/> : <Page/>}
            </div>
        );
  }
}

export default App;
