import React, {Fragment} from "react";
import "./data.css";
import DataParent from "./DataParent";
import {advTypes, HandleExpertTitle, pushTypes} from "../../tool/Const";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import EMPTY from "../../assets/img/record_empty.svg";
import ExpertFrame from "../component/ExpertFrame";

const loadSetting = {
    loadingBatch: 20,
    classes: {
        outer: "data-partition-ct",
    }
}

class DataExpert extends DataParent {
    constructor(props) {
        super(props);
        this.state = {
            isAdd: false,  // 是否新增
            isEdit: false,
        }
    }

    loadCt = (partitioner, findDataPojo) => {
        let ui = [];
        let list = [];
        let showData = partitioner.showData;
        let len = showData.length;
        if (len > 0) {
            showData.forEach((item, index) => {
                let name = item['expertName'];
                let id = item['expertId'];
                let root = item['expertRoot'];
                let title = HandleExpertTitle(item['expertTitle']);
                let price = item['expertPrice'];
                let push = item['isPush'] ? <CheckOutlined/> : <CloseOutlined/>;
                let adv = item['isAd'] ? <CheckOutlined/> : <CloseOutlined/>;
                // let fun = <div className={"data-item-fun-open"} style={{background: "#0f911e"}} id={"line-fun"}>
                //     {"编辑"}
                // </div>;
                list.push(
                    <div className={"data-item"} key={item + index}
                         style={{marginBottom: index === (len - 1) ? "0" : ""}}
                         id={"line-ct:" + index}
                         onClick={(e) => {
                             this.launchExpertFrame(e, item)
                         }}
                    >
                        {this.loadLayout(name, id, root, title, price, push, adv)}
                    </div>
                )
            })
        } else {
            list.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        ui.push(
            <Fragment>
                <div className={"data-header"}>
                    {this.loadLayout("专家名称", "ID", "来源", "职称", "价格", "推送首页", "推送广告")}
                </div>
                <div className={"data-form"}>
                    {list}
                </div>
            </Fragment>
        )
        return ui;
    }

    loadLayout = (name, id, root, title, price, push, adv) => {
        return (
            <Fragment>
                <div className={"data-item-name"} style={{width: '15%'}} id={"line-ct-name"}>
                    {name}
                </div>
                <div className={"data-item-id"} id={"line-ct-id"}>
                    {id}
                </div>
                <div className={"data-item-id"} style={{width: '26%'}} id={"line-ct-root"}>
                    {root}
                </div>
                <div className={"data-item-date"} style={{width: '18%'}} id={"line-ct-date"}>
                    {title}
                </div>
                <div className={"data-item-date"} id={"line-ct-price"}>
                    {price}
                </div>
                <div className={"data-item-status"} id={"line-ct-push"}>
                    {push}
                </div>
                <div className={"data-item-status"} id={"line-ct-adv"}>
                    {adv}
                </div>
                {/*<div className={"data-item-fun"} id={"line-ct-fun"}>*/}
                {/*    {fun}*/}
                {/*</div>*/}
            </Fragment>
        )
    }

    launchExpertFrame = (e, data) => {
        let id = data['expertId'];
        if (e.target.id.includes("line-ct")) {
            if (id) {
                window.location.href = "/dataExpert?id=" + id
            }
        } else {
            this.editData(id);
        }
    }

    editData = (id) => {
        this.setState({
            isEdit: true,
        },
            () => {
                window.location.href = "/dataExpert?id=" + id
            })
    }

    // pojoStr初始化
    initPojoStr = () => {
        return {
            clickNum: null,
            expertAreas: '',
            expertBanner: '',
            expertCt: '',
            expertDes: '',
            expertEdu: '',
            expertGeo: '',
            expertHor: '',
            expertId: '',
            expertJob: '',
            expertName: '',
            expertPrice: '',
            expertRoot: '',
            expertSch: '',
            expertTarget: null,
            expertTitle: null,
            isAd: false,
            isPush: false,
            keyMap: '',
            refId: '',
            saveNum: '',
            srcType: '',
        };
    }

    componentDidMount() {
        this.init(null, '2577', loadSetting.loadingBatch);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 判断selectId的变化执行findDataById接口获取id数据
        if (prevState.selectId !== this.state.selectId) {
            this.handleSelectId("dateExpert");
        }
    }

    render() {
        let findDataPojo = this.state.findDataPojo;
        let partitioner = this.state.partitioner;
        let selectId = this.state.selectId;
        let loaded = this.state.loaded;
        let dataLoaded = this.state.dataLoaded;
        let isAdd = this.state.isAdd;

        let typeList = [
            {ct: pushTypes, key: "push", txt: "推送首页"},
            {ct: advTypes, key: "adv", txt: "推送广告"},
        ];

        let searchList = {
            0: "关键词",
            1: "数据id",
            2: "作者id"
        };

        return (
            <div className={"page-wrap"}>
                {selectId == null ?
                    loaded ?
                        this.genLayout(typeList, searchList, "dataExpert", this.loadCt(partitioner, findDataPojo), loadSetting, findDataPojo, partitioner)
                        : null
                    :
                    dataLoaded ? <ExpertFrame pojo={Object.assign({}, findDataPojo)} isAdd={isAdd} isSub={false} backUrl={"/dataExpert"}/> : null
                }
            </div>
        )
    }
}

export default DataExpert;