import React, {Component} from "react";
import "./component.css";
import {searchTypes} from "../../tool/Const";
import Search from "../../tool/Search";
import {PlusOutlined} from "@ant-design/icons";

class SelectorWin extends Component<SelectorParams> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    loadingSelect = (data) => {
        let selects = [];
        Object.keys(data).forEach((p) => {
            selects.push(
                <option value={p} key={p}>
                    {data[p]}
                </option>
            )
        })
        return selects;
    }

    // 加载selector
    loadTypeList = (typeList) => {
        let ui = [];
        let len = typeList.length;
        if (len > 0) {
            let selector = this.props.selector;
            typeList.forEach((item, index) => {
                ui.push(
                    <div className={"data-selector-item"} key={item + index}>
                        <span className={"search-txt"}>{item.txt}</span>
                        <select className={"search-input"} style={{width: "100px"}}
                                key={selector[item.key]}
                                defaultValue={selector[item.key]}
                                onChange={(e) => {
                                    this.props.sendFun(e, item.key)
                                }}
                        >
                            {this.loadingSelect(item.ct)}
                        </select>
                    </div>
                )
            })
        };
        return ui;
    }

    render() {
        let typeList = this.props.typeList;
        let searchList = this.props.searchList;
        let urlInfo = this.props.urlInfo;
        let isSub = this.props.isSub;

        return (
            <div className={"data-selector"} id={"selector"}>
                <div className={"data-selector-left"}>
                    {this.loadTypeList(typeList)}
                </div>
                <div className={"data-selector-right"}>
                    <select className={"search-input"} style={{marginRight: '5px', width: "85px"}}
                            defaultValue={0}
                            onChange={(e) => {
                                this.props.setSearchKey(e)
                            }}
                    >
                        {this.loadingSelect(searchList)}
                    </select>
                    <Search inputSize={50} onChange={this.props.setText} onClick={this.props.searchInfo}/>
                    {isSub ?
                        null :
                        <button className={"add-bt"}
                                onClick={() => {
                                    window.location.href = "/" + urlInfo + "?id=0"
                                }}
                        >
                            <PlusOutlined style={{paddingRight: "4px"}}/>
                            {"新增"}
                        </button>
                    }
                </div>
            </div>
        )
    }
}

export default SelectorWin;