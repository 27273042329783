import React, {Component} from "react";
import {EyeOutlined} from "@ant-design/icons";
import {GetVerCode, storage} from "../tool/Const";
import {genPojo, HostLoginUrl, sendPostBack} from "../tool/http/Request";
import {fail, findMsgByError} from "../tool/http/ErrorMgr";

class Login extends Component<null, LoginRequest> {
    constructor(props) {
        super(props);
        this.state = {
            hostBase: {
                hostAccount: '',
                hostPhone: '',
                hostPwd: '',
            },
            secCode: '',
            secondI: 30,  // 验证码发送间隔（s）
            showPass: false,  // 密码鱼眼镜
        }
    }

    // 加载账号登陆密码输入
    loadLoginCt = (hostBase, secCode, secondI, showPass) => {
        let canSend = secondI === 30;
        return (
            <div className={"login-plugin-ct-input-wrap"}>
                <input className={"login-input-style"}
                       key={"hostAccount"}
                       placeholder={"请输入账号"}
                       maxLength={11}
                       onChange={(e) => {
                           this.fillValue(e, "hostAccount", hostBase)
                       }}
                />
                <div style={{height: "30px"}} />
                <input className={"login-input-style"}
                       key={"hostPhone"}
                       placeholder={"请输入手机"}
                       maxLength={11}
                       onChange={(e) => {
                           this.fillValue(e, "hostPhone", hostBase)
                       }}
                />
                <div style={{height: "30px"}} />
                <input className={"login-input-style"}
                       key={"secCode"}
                       placeholder={"请输入验证码"}
                       maxLength={11}
                       onChange={(e) => {
                           this.fillSecCode(e)
                       }}
                />
                <div className={!canSend ? "send-code-get save-button-none" : "send-code-get"}
                     onClick={() => {GetVerCode(hostBase, this)}}
                >
                    {!canSend ? secondI + "秒后可重发" : "发送验证码"}
                </div>
                <div style={{height: "30px"}} />
                <div style={{display: "flex", alignItems: "center"}}>
                    <input className={"login-input-style"}
                           key={"hostPwd"}
                           placeholder={"请输入密码"}
                           type={showPass ? "text" : "password"}
                           maxLength={20}
                           onChange={(e) => {
                               this.fillValue(e, "hostPwd", hostBase)
                           }}
                    />
                    <EyeOutlined className={"eye-style"}
                                 style={{color: showPass ? "#8aaed7" : "#c4c4c4"}}
                                 onClick={() => {
                                     this.setState({showPass: !showPass})
                                 }}
                    />
                </div>
                <div style={{height: "30px"}} />
            </div>
        )
    }

    // 登录信息填充
    fillValue = (e, tag, hostBase) => {
        hostBase[tag] = e.target.value;
        this.setState({
            hostBase: hostBase,
        })
    }

    // 填充验证码
    fillSecCode = (e) => {
        this.setState({
            secCode: e.target.value,
        })
    }

    // 确认登录
    login = (hostBase, secCode) => {
        let pojo = {
            hostBase: hostBase,
            secCode: secCode,
        }
        const loginBack: DataBack = {

            successBack(res: any) {
                storage.set("hostInfo", res);
                storage.set("infoTime", new Date().getTime())
                // that.setLogin(back);
                window.location.reload();
            },

            failBack(error: any) {
                fail(findMsgByError(error))
            }
        }

        sendPostBack(genPojo(HostLoginUrl, pojo), true, loginBack);
    }

    render() {
        let hostBase = this.state.hostBase;
        let secCode = this.state.secCode;
        let secondI = this.state.secondI;
        let showPass = this.state.showPass;

        console.log("登录信息：", hostBase);

        return (
            <div className={"login-plugin"}>
                <div className={"login-plugin-ct-wrap"}>
                    <div className={"login-plugin-ct"}>
                        <div className={"login-plugin-ct-title"}>
                            <div className={"title-name"}>
                                {"欢迎登录"}
                            </div>
                        </div>
                        {this.loadLoginCt(hostBase, secCode, secondI, showPass)}
                        <div className={"login-plugin-ct-btn"}
                             onClick={() => {
                                 this.login(hostBase, secCode)
                             }}
                        >
                            {"登录"}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;