import React, {Component} from "react";
import "./tool.css";
import {CloseOutlined} from "@ant-design/icons";
import {delWin} from "./Luncher";
import {FindAnglePhonesUrl, genPojo, sendPostBack} from "./http/Request";
import {GetVerCodeByLevel, storage} from "./Const";

class AuthWin extends Component<AuthTool, AuthParam> {
    constructor(props: AuthTool) {
        super(props);
        this.state = {
            angleList: [],
        }
    }

    componentDidMount() {
        let level = this.props.level;
        if (level !== 2) {
            let userInfo = storage.get("hostInfo");
            let pojo = {
                account: userInfo.hostAccount,
            };
            let dom = this;
            const findAnglePhoneBack: DataBack = {
                successBack(res: any) {
                    dom.setState({
                        angleList: res ? res: [],
                    })
                },
                failBack(error: any) {
                    // if (error === loginOut) {
                    //     storage.remove("hostInfo");
                    // } else {
                    //     fail(findMsgByError(error))
                    // }
                }
            }

            sendPostBack(genPojo(FindAnglePhonesUrl, pojo), true, findAnglePhoneBack)
        }
    }

    genPhoneDropDown = () => {
        let selects = [];
        let phones = this.state.angleList;
        selects.push(<option value={null} key={"ini"}>
            {null}
        </option>)
        phones.forEach((p, i) => {
            selects.push(<option value={p} key={p + i}>
                {p}
            </option>)
        })
        return selects;
    }

    setAngle = (e) => {
        this.setState({
            anglePhone: e.target.value,
        })
    }

    setSecCode = (e, level) => {
        let vl = e.target.value;
        if (vl) {
            if (level < 3) {
                this.setState({
                    secCode: vl,
                })
            } else {
                this.setState({
                    angleSec: vl,
                })
            }
        }
    }

    setSecPass = (e) => {
        let vl = e.target.value;
        if (vl) {
            this.setState({
                passCode: vl,
            })
        }
    }

    setParent = (level, id, winId) => {
        let angle = this.state.anglePhone;
        let angleSec = this.state.angleSec;
        let sec = this.state.secCode;
        let pass = this.state.passCode;
        let isLegal = false;
        if (level < 3 && sec) {
            isLegal = true;
        } else {
            if (angle && angleSec) {
                if (level === 4) {
                    if (pass) {
                        isLegal = true;
                    }
                } else {
                    isLegal = true;
                }
            }
        }

        const myBack: WinBack = {
            callBack() {
                delWin(id, winId)
            }
        }
        if (isLegal) {
            this.props.backFun(angle, angleSec, sec, pass, myBack);
        }
    }

    loadWinByLevel = (level, parentId, winId) => {
        let isAngle = level > 2;
        let res = [];
        if (level === 3 || level === 4) {
            res.push(
                <div className={"auth-tool"} key={"phone"}>
                    <span className={"auth-title"}>{"请选择管理员验证手机 "}</span>
                    <select className={"search-input"} style={{width: "50%"}} onChange={(e) => {
                        this.setAngle(e)
                    }}>
                        {this.genPhoneDropDown()}
                    </select>
                </div>
            )
        }

        res.push(<div className={"auth-tool"} key={"code"}>
            <span className={"auth-title"}>{"输入验证码 "}</span>
            <input className={"search-input"} maxLength={6} onChange={(e) => {
                this.setSecCode(e, level)
            }}/>
            <button className={"search-bt"} style={{width: "100px"}}
                    onClick={() => {GetVerCodeByLevel(isAngle ? this.state.anglePhone : this.props.phone, isAngle, this.props.account)}}
            >{"发送验证码"}</button>
        </div>)

        if (level === 4) {
            res.push(<div className={"auth-tool"} key={"pass"}>
                <span className={"auth-title"}>{"请输入操作秘钥 "}</span>
                <input className={"search-input"} style={{width: "60%"}} maxLength={50} onChange={(e) => {
                    this.setSecPass(e)
                }}/>
            </div>)
        }
        res.push(
            <div className={"auth-tool"} key={"commit"}>
                <button className={"search-bt"} style={{width: "100%"}} onClick={() => {
                    this.setParent(level, parentId, winId)
                }}>{"确认"}</button>
            </div>
        )
        return res;
    }

    handleCloseWin = (cleanFun, parentId, winId) => {
        if (cleanFun) {
            cleanFun();
        }
        delWin(parentId, winId);
    }


    render() {
        let level = this.props.level;
        let parentId = this.props.parentId;
        let winId = this.props.winId;

        let cleanFun = this.props.cleanFun;

        return <div className={"auth-win"}>
            <div className={"auth-close"}>
                <CloseOutlined style={{fontSize: "22px", marginRight: "10px"}} onClick={() => {
                    this.handleCloseWin(cleanFun, parentId, winId)
                }}/>
            </div>
            <div className={"auth-body"}>
                {this.loadWinByLevel(level, parentId, winId)}
            </div>
            <div style={{height: "30px", width: "100%"}}/>
        </div>;
    }
}

export default AuthWin;