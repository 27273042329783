import {
    CheckExpertByUserIdUrl,
    CountAvailableExpertsUrl,
    FindAvailableExpertsUrl,
    FindAwaitEditExpertUrl, FindExpertAppointsUrl,
    sendGetBack
} from "../../tool/http/Request";

class ExpertOperator {
    findDataSize = (back: DataBack, params) => {
        sendGetBack(CountAvailableExpertsUrl, params, true, back);
    }

    findData = (back: DataBack, params) => {
        sendGetBack(FindAvailableExpertsUrl, params, true, back);
    }

    findAwaitData = (back: DataBack, params) => {
        sendGetBack(FindAwaitEditExpertUrl, params, true, back);
    }

    findExpertById = (back: DataBack, params) => {
        sendGetBack(CheckExpertByUserIdUrl, params, true, back);
    }

    findExpertAppoints = (back: DataBack, params) => {
        sendGetBack(FindExpertAppointsUrl, params, true, back);
    }
}

export default ExpertOperator;