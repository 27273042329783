import React, {Component} from "react";
import "../data/data.css";
import "../../tool/tool.css";
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/markPoint'
import ReactEcharts from 'echarts-for-react'
import {genProcessBack, InitAuth, storage} from "../../tool/Const";
import {findStatistics, sendGetBack} from "../../tool/http/Request";
import * as echarts from 'echarts';
import 'default-passive-events';

const clickItems = [
    {id: "DEMAND", name: "科技需求", color: "rgba(75,161,29,0.6)"},
    {id: "EXPERT", name: "专家人才", color: "rgba(19,182,187,0.6)"},
    {id: "OUTCOME", name: "科技成果", color: "rgba(165,130,24,0.6)"},
    {id: "POLICY", name: "科技政策", color: "rgba(193,17,17,0.6)"},
    {id: "NEWS", name: "科技资讯", color: "rgba(25,92,193,0.6)"}
];

const pageClick = [
    {id: "p-1", name: "平台首页", color: "rgb(0,117,255)"},
    {id: "p-2", name: "科技成果", color: "rgb(70,145,255)"},
    {id: "p-3", name: "科技需求", color: "rgb(144,189,255)"},
    {id: "p-4", name: "专家人才", color: "rgb(190,216,255)"},
    {id: "p-5", name: "解决方案", color: "rgb(218,225,239)"},
    {id: "p-6", name: "科技政策", color: "rgb(183,190,205)"},
    {id: "p-7", name: "科技资讯", color: "rgb(139,148,161)"},
    {id: "p-8", name: "公司介绍", color: "rgb(104,107,113)"}
]

const saveItems = [
    {id: "DEMAND", name: "科技需求", color: "rgba(75,161,29,0.6)"},
    {id: "EXPERT", name: "专家人才", color: "rgba(19,182,187,0.6)"},
    {id: "OUTCOME", name: "科技成果", color: "rgba(165,130,24,0.6)"},
    {id: "POLICY", name: "科技政策", color: "rgba(193,17,17,0.6)"},
];

const totalUser = {id: "TOTAL", name: "注册总数"}

const userItems = [
    {id: "IS_PER", name: "个人认证"},
    {id: "IS_COMP", name: "企业认证"},
    {id: "IS_EXP", name: "专家认证"},
    {id: "USER_VIP", name: "VIP用户"},
]

const opItems = [
    {id: "DEMAND_PUSH", name: "需求提交"},
    {id: "OUTCOME_PUSH", name: "成果提交"},
    {id: "EXPERT_APPOINT", name: "专家预约"},
];

const solutionClick = [
    {id: "s-1", name: "校企产学研合作", color: "rgb(0,117,255)"},
    {id: "s-2", name: "共建企业技术中心", color: "rgb(0,117,255)"},
    {id: "s-3", name: "共建联合实验室", color: "rgb(0,117,255)"},
    {id: "s-4", name: "专精特新咨询申报", color: "rgb(0,117,255)"},
    {id: "s-5", name: "科技奖项申报", color: "rgb(0,117,255)"},
    {id: "s-6", name: "研发情报指南", color: "rgb(0,117,255)"},
    {id: "s-7", name: "知识产权分析布局", color: "rgb(0,117,255)"},
    {id: "s-8", name: "专家云会议", color: "rgb(0,117,255)"},
    {id: "s-9", name: "专家面对面", color: "rgb(0,117,255)"},
    {id: "s-10", name: "区域技术服务平台", color: "rgb(0,117,255)"},
    {id: "s-11", name: "科创空间运营", color: "rgb(0,117,255)"},
    {id: "s-12", name: "科技创新活动", color: "rgb(0,117,255)"},
    {id: "s-13", name: "政策解读与培训", color: "rgb(0,117,255)"},
    {id: "s-14", name: "技术转移分中心", color: "rgb(0,117,255)"},
    {id: "s-15", name: "新型研发机构", color: "rgb(0,117,255)"},
    {id: "s-16", name: "科技工作站", color: "rgb(0,117,255)"},
    {id: "s-17", name: "科技成果评估", color: "rgb(0,117,255)"},
    {id: "s-18", name: "检测与认证服务", color: "rgb(0,117,255)"},
    {id: "s-19", name: "技术智囊团", color: "rgb(0,117,255)"},
    {id: "s-20", name: "科研人才招聘", color: "rgb(0,117,255)"},
    {id: "s-21", name: "创新人才工作站", color: "rgb(0,117,255)"},
    {id: "s-22", name: "技术经纪人培训", color: "rgb(0,117,255)"},
    {id: "s-23", name: "企业技术人员培训", color: "rgb(0,117,255)"},
    {id: "s-24", name: "高层次人才引进", color: "rgb(0,117,255)"},
]

const dataIndex = {click: 1, save: 2, op: 3, info: 4}

class StatisticsSummary extends Component<null, StatisticsState> {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            auth: null,
            itemClickMap: null,
            pageClickMap: null,
            solutionClickMap: null,
            itemSaveMap: null,
            itemOpMap: null,
            userMap: null,
            totalUser: null,
        }
    }

    componentDidMount() {
        let host = storage.get("hostInfo");
        let auth = InitAuth(host.hostAccount);
        auth.phone = host.hostPhone;
        let dom = this;
        const stateBack: WinBack = {
            callDataBack(data: any) {
                dom.loadingData(data, dom, auth)
            }
        }

        sendGetBack(findStatistics, {auth: auth}, true, genProcessBack(stateBack, true))
    }

    loadingData = (data, dom, auth) => {
        let clickMap = data[dataIndex.click];
        let saveMap = data[dataIndex.save];
        let opMap = data[dataIndex.op];
        let infoMap = data[dataIndex.info];

        let itemClickMap = dom.fillMap(clickItems, clickMap);
        let pageClickMap = dom.fillMap(pageClick, clickMap);
        let solutionClickMap = dom.fillMap(solutionClick, clickMap);
        let itemSaveMap = dom.fillMap(saveItems, saveMap);
        let itemOpMap = dom.fillMap(opItems, opMap);
        let userMap = dom.fillMap(userItems, infoMap);
        let total = {name: totalUser.name, value: infoMap[totalUser.id]};
        dom.setState({
            loaded: true,
            auth: auth,
            itemClickMap: itemClickMap,
            pageClickMap: pageClickMap,
            solutionClickMap: solutionClickMap,
            itemSaveMap: itemSaveMap,
            itemOpMap: itemOpMap,
            userMap: userMap,
            totalUser: total,
        })
    }

    fillMap = (info, map) => {
        let res = [];
        info.forEach((item) => {
            let id = item.id;
            let name = item.name;
            let obj = {};
            obj.name = name;
            if (map.hasOwnProperty(id)) {
                obj.value = map[id]
            } else {
                obj.value = 0
            }

            if (item.hasOwnProperty('color')) {
                obj.color = item.color;
            }

            res.push(obj)
        })
        return res;
    }

    genTitleLine = (title) => {
        return <div className={"statistics-title"}>
            <span>{title}</span>
        </div>
    }

    genPieChart = (title, dataMap, name, top, right, center, radius, itemStyle, orient, x) => {
        let itemNames = dataMap.map((item) => item.name)
        let color = dataMap.map((item) => item.color)
        return {
            title: {
                text: title,
                x: x
            },

            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c} ({d}%)"
            },

            legend: {
                orient: orient,
                top: top,
                right: right,
                data: itemNames
            },

            color: color,

            series: [
                {
                    name: name,
                    type: 'pie',
                    itemStyle: itemStyle,
                    center: center,
                    radius: radius,
                    top: '60px',
                    data: dataMap,
                }
            ]
        };
    }

    genColChart = (title, dataMap) => {
        let itemNames = dataMap.map((item) => item.name)
        return {
            grid: {
                left: '56px',
                top: 0,
            },

            title: {
                text: title,
                show: false
            },

            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c}"
            },
            xAxis: {
                show: false,
                type: 'value',
            },
            yAxis: {
                data: itemNames,
                textStyle: {
                    color: '#476092',
                },
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
            },

            series: [
                {
                    name: '用户统计',
                    type: 'bar',
                    barWidth: '22%',
                    showBackground: true,
                    backgroundStyle: {
                        // 背景颜色
                        color: 'rgba(156, 170, 211, .2)',
                        borderRadius: 30,
                    },
                    label: {
                        show: true,
                        position: 'right',
                        color: '#070707',
                    },
                    itemStyle: {
                        //雷达图区域颜色
                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                            offset: 0,
                            color: 'rgba(51, 136, 255, 0.5)'
                        },
                            {
                                offset: 0.98,
                                color: '#3388FF'
                            }], false),
                        borderRadius: 30,
                    },
                    data: dataMap
                }
            ]
        };
    }

    genColChartV = (title, dataMap) => {
        let itemNames = dataMap.map((item) => item.name)
        return {
            grid: {
                left: 0,
                top: '40px',
            },

            title: {
                text: title,
                show: false
            },

            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c}"
            },
            xAxis: {
                data: itemNames,
                textStyle: {
                    color: '#326645',
                },
                axisLine: {
                    show: true,
                },
                axisTick: {
                    show: true,
                },
            },
            yAxis: {
                show: false,
                type: 'value',
            },

            series: [
                {
                    name: '用户统计',
                    type: 'bar',
                    barWidth: '10%',
                    showBackground: true,
                    backgroundStyle: {
                        // 背景颜色
                        color: 'rgba(156, 170, 211, .2)',
                    },
                    label: {
                        show: true,
                        position: 'top',
                        color: '#070707',
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                            offset: 0,
                            color: 'rgba(152,255,177,0.5)'
                        },
                            {
                                offset: 0.98,
                                color: '#33ff7e'
                            }], false),
                    },
                    data: dataMap
                }
            ]
        };
    }

    loadRatio = (total, map) => {
        let lst = [];
        map.forEach((item, index) => {
            lst.push(<span key={index} className={"num-tag-item"}>
                {item.name + ": " + (item.value / total * 100).toFixed(2) + "%"}
            </span>)
        })

        return lst;
    }

    loadUserPart = () => {
        let total = this.state.totalUser;
        let userMap = this.state.userMap;
        return <div className={"statistics-item-part"}>
            {this.genTitleLine("用户群体分析")}
            <div className={"statistics-user"} style={{height: "300px"}}>
                <div style={{margin: "10px 0 5px 0"}}>
                    <span className={"num-tag-item"}>{total.name + ": " + total.value}</span>
                    {this.loadRatio(total.value, userMap)}
                </div>
                {<ReactEcharts
                    option={this.genColChart("", userMap)}
                    opts={{renderer: 'svg'}}
                    style={{width: '80%', height: '100%'}}
                />}
            </div>
        </div>
    }

    loadSolutionPart = () => {
        let solutionMap = this.state.solutionClickMap;
        let total = solutionMap.reduce((sum, page) => {
            sum += page.value;
            return sum
        }, 0)


        let lst = [];

        solutionMap.forEach((s, index) => {
            let data = [];
            data.push({name: "其他", value: total - s.value, color: "#aab0bb"});
            data.push(s);
            lst.push(
                <div key={index} style={{paddingBottom: "20px"}}>
                    <ReactEcharts
                        option={this.genPieChart(s.name, data, "点击量", 40, 'center',
                            ['50%', '50%'], ['40%', '60%'], {
                                borderRadius: 2,
                                borderColor: "white",
                                borderWidth: 4,
                            }, 'horizontal', 'center')}
                        opts={{renderer: 'svg'}}
                        style={{width: '430px', height: '430px'}}
                    />
                </div>
            )
        })

        return <div className={"statistics-item-part"}>
            <div className={"statistics-user"} style={{display: "flex",
                flexWrap: "wrap", justifyContent: "space-between"}}>
                {lst}
            </div>
        </div>
    }

    loadUserOpPart = () => {
        let opMap = this.state.itemOpMap;
        let opLst = [];
        opMap.forEach((item, index) => {
            opLst.push(<span key={index} className={"num-tag-item"}>
                {item.name + ": " + item.value}
            </span>)
        })
        return <div className={"statistics-item-part"}>
            <div className={"statistics-user"} style={{height: "300px"}}>
                <div style={{margin: "10px 0 5px 0"}}>
                    {opLst}
                </div>
                {<ReactEcharts
                    option={this.genColChartV("", this.state.itemOpMap)}
                    opts={{renderer: 'svg'}}
                    style={{width: '80%', height: '100%'}}
                />}
            </div>
        </div>
    }

    itemDataPart = () => {
        let itemClickMap = this.state.itemClickMap;
        let itemSaveMap = this.state.itemSaveMap;
        let top = 60;
        let right = 40;
        let center = ['30%', '60%'];
        let radius = ['40%', '60%'];
        let orient = 'vertical';
        let x = '20%';
        return <div className={"statistics-item-part"}>
            {this.genTitleLine("服务扩散对比分析")}
            <div className={"statistics-user"}
                 style={{display: "flex", justifyContent: "space-between", height: "360px"}}>
                {<ReactEcharts
                    option={this.genPieChart("服务项目点击量", itemClickMap, "点击量", top, right, center, radius, '', orient, x)}
                    opts={{renderer: 'svg'}}
                    style={{width: '40%', height: '90%', minWidth: "500px"}}
                />}
                {<ReactEcharts
                    option={this.genPieChart("服务项目收藏量", itemSaveMap, "收藏量", top, right, center, radius, '', orient, x)}
                    opts={{renderer: 'svg'}}
                    style={{width: '40%', height: '90%', minWidth: "500px"}}
                />}
            </div>
        </div>
    }

    loadWebClick = () => {
        let pageMap = this.state.pageClickMap;
        let total = pageMap.reduce((sum, page) => {
            sum += page.value;
            return sum
        }, 0)
        return <div className={"statistics-item-part"}>
            {this.genTitleLine("网站浏览量分析")}
            <div className={"statistics-user"}
                 style={{display: "flex", height: "600px", justifyContent: "center"}}>
                {<ReactEcharts
                    option={this.genPieChart("网站基础页面点击量 —— 点击总数: " + total, pageMap, "点击量", 60, 'center',
                        ['50%', '50%'], ['40%', '60%'], {
                            borderRadius: 2,
                            borderColor: "white",
                            borderWidth: 4,
                        }, 'horizontal', 'center')}
                    opts={{renderer: 'svg'}}
                    style={{width: '100%', height: '90%', minWidth: "750px", marginTop: "40px"}}
                />}
            </div>
        </div>
    }

    render() {
        let loaded = this.state.loaded;
        return <div className={"page-wrap"}>
            {loaded ?
                <div style={{position: "relative", width: "100%", height: "100%"}}>
                    <div style={{
                        minWidth: "1250px",
                        height: "100%",
                        padding: "0",
                        background: "rgb(255,255,255)"
                    }}>
                        <div className={"user-table"}>
                            <div style={{height: "20px"}}/>
                            {this.loadUserPart()}
                            {this.loadUserOpPart()}
                            {this.itemDataPart()}
                            {this.loadWebClick()}
                            {this.loadSolutionPart()}
                        </div>
                    </div>
                </div>
                : null}
        </div>
    }
}

export default StatisticsSummary;