import React, {Component, Fragment} from "react";
import "./check.css";
import {InitAuthPojo, RemoveLoginInfo, storage} from "../../tool/Const";
import CheckOperator from "./CheckOperator";
import {fail, failReturn, findMsgByError, loginOut} from "../../tool/http/ErrorMgr";
import SelectorWin from "../component/SelectorWin";
import {urlHeader} from "../../tool/http/Request";

class CheckParent extends Component<null, CheckState> {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        }
    }

    loadLayout = (name, userId, id, type, area, date) => {
        return (
            <Fragment>
                <div className={"data-item-name"} id={"line-ct:name"} key={"name"}>
                    {name}
                </div>
                <div className={"data-item-id"} id={"line-ct:userId"} key={"userId"}>
                    {userId}
                </div>
                <div className={"data-item-id"} id={"line-ct:id"} key={"id"}>
                    {id}
                </div>
                <div className={"data-item-type"} id={"line-ct:type"} key={"type"}>
                    {type}
                </div>
                <div className={"data-item-area"} style={{width: '15%'}} id={"line-ct:area"} key={"area"}>
                    {area}
                </div>
                <div className={"data-item-date"} style={{width: '20%'}} id={"line-ct:date"} key={"date"}>
                    {date}
                </div>
            </Fragment>
        )
    }

    // 初始化
    init = (index, status) => {
        console.log("初始化！");
        let url = window.location.href;
        let param = url.split("?id=");
        if (param.length !== 1) {
            let id = param[1];
            this.setState({
                selectId: id,
            })
            let hostInfo = storage.get("hostInfo");
            this.initSubItemPojo(1, null, null, index, InitAuthPojo(hostInfo), null, null);
        } else {
            this.findDataByStatus(index, status);
        }
    }

    // 初始化subItemPojo
    initSubItemPojo = (status, subIndex, userId, index, auth, reason, itemId) => {
        const data: SubItemPojo = {
            status: status,
            subIndex: subIndex,
            userId: userId,
            index: index,
            auth: auth,
            reason: reason,
            itemId: itemId,
        }
        this.setState({
            subItemPojo: data,
        })
        return data;
    }

    // 获取用户提交的数据
    // WAIT=1; ACCEPT=2; REJECT=3
    findDataByStatus = (index, status) => {
        let hostInfo = storage.get("hostInfo");
        status = Number.parseInt(status);
        let params = this.initSubItemPojo(status, null, null, index, InitAuthPojo(hostInfo), null, null);

        let operator = new CheckOperator();

        let _this = this;
        const findData: DataBack = {
            successBack(res: any) {
                console.log("获取用户提交的数据res: ", res);
                _this.setState({
                    data: res,
                    loaded: true,
                    subItemPojo: params,
                })
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        operator.findDataByStatus(params, findData);
    }

    // 加载界面布局
    genLayout = (typeList, searchList, urlInfo, ct) => {
        return (
            <div className={"page-ct"}>
                {this.genSelector(typeList, searchList, urlInfo)}
                <div className={"data-ct"}>
                    {ct}
                </div>
            </div>
        )
    }

    // 加载selector
    genSelector = (typeList, searchList, urlInfo) => {
        let selector = this.state.subItemPojo;
        return <SelectorWin selector={selector}
                            typeList={typeList}
                            searchList={searchList}
                            urlInfo={urlInfo}
                            sendFun={this.sendSelector}
                            setText={this.setText}
                            searchInfo={this.searchInfo}
                            isSub={true}
                            setSearchKey={null}/>
    }

    // 更换status
    sendSelector = (e) => {
        let val = e.target.value;
        if (val) {
            let index = this.state.subItemPojo.index;
            this.findDataByStatus(index, val);
        }
    }

    // 搜索文本
    setText = (e) => {
        let val = e.target.value;
        console.log(val);
        this.setState({
            text: val,
        })
    }

    // 执行搜索
    searchInfo = () => {
        let text = this.state.text;
        if (text) {
            let url = window.location.href;
            let toUrl = url + "?id=" + text;
            console.log(toUrl);
            window.location.href = toUrl;
        }
    }

    // 加载行业领域
    loadArea = (areas) => {
        let lst = [];
        if (areas) {
            let tags = areas.split(";");
            tags.forEach((it) => {
                    lst.push(
                        <span className={"area-tag"} key={it}>
                        {it}
                    </span>
                    )
                }
            )
        }
        return lst;
    }

    // 判断selectId的变化执行findDataById接口获取id数据
    handleSelectId = (urlInfo) => {
        let dataPojo = this.state.subItemPojo;
        console.log("selectId变化时： ", dataPojo);

        if (this.state.selectId !== "0") {
            let subIndex = this.state.selectId;
            let index = dataPojo.index;
            this.getDataBySubIndex(dataPojo, subIndex, index, urlInfo);
        }
    }

    // 根据subIndex获取数据详情
    getDataBySubIndex = (dataPojo, subIndex, index, urlInfo) => {
        let hostInfo = storage.get("hostInfo");
        // let params = this.initSubItemPojo(dataPojo.status, subIndex, null, index, InitAuthPojo(hostInfo), null, null);

        dataPojo.subIndex = subIndex;
        dataPojo.index = index;
        dataPojo.auth = InitAuthPojo(hostInfo);
        let operator = new CheckOperator();

        let _this = this;
        const findData: DataBack = {
            successBack(res: any) {
                console.log("根据subIndex获取数据res: ", res);
                let dataPojo = _this.state.subItemPojo;
                dataPojo.pojoStr = res;
                dataPojo.userId = res['userId'];
                _this.getMedias(dataPojo, urlInfo);
                _this.setState({
                    subItemPojo: dataPojo,
                    dataLoaded: true,
                })
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    failReturn(findMsgByError(error), urlHeader + urlInfo);
                }
            }
        }

        operator.findDataBySubIndex(dataPojo, findData);
    }

    // 获取静态资源
    getMedias = (dataPojo, urlInfo) => {
        console.log("获取静态资源: ", dataPojo)
        let operator = new CheckOperator();

        let _this = this;
        const findMedias: DataBack = {
            successBack(res: any) {
                console.log("获取静态资源res: ", res);
                // let dataPojo = _this.state.subItemPojo;
                // dataPojo.pojoStr = res;
                // dataPojo.userId = res['userId'];
                // _this.setState({
                //     subItemPojo: dataPojo,
                //     dataLoaded: true,
                // })
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    // failReturn(findMsgByError(error), urlHeader + urlInfo);
                }
            }
        }

        operator.findMedias(dataPojo, findMedias);
    }
}

export default CheckParent;