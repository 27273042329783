import React, {Fragment} from "react";
import "./data.css";
import DataParent from "./DataParent";
import {advTypes, HandleItemType, pushTypes} from "../../tool/Const";
import PolicyFrame from "../component/PolicyFrame";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import EMPTY from "../../assets/img/record_empty.svg";
import moment from "moment";

const loadSetting = {
    loadingBatch: 20,
    classes: {
        outer: "data-partition-ct",
    }
}

class DataPolicy extends DataParent {
    constructor(props) {
        super(props);
        this.state = {
            isAdd: false,
        }
    }

    loadCt = (partitioner, findDataPojo) => {
        let ui = [];
        let list = [];
        let showData = partitioner.showData;
        let len = showData.length;
        if (len > 0) {
            showData.forEach((item, index) => {
                let name = item['policyName'];
                let id = item['policyId'];
                let root = item['policyRoot'];
                let area = item['policyPri'] + (item['policyCity'] ? " " + item['policyCity'] : '');
                let date = item['policyDate'];
                let push = item['isPush'] ? <CheckOutlined/> : <CloseOutlined/>;
                let adv = item['isAd'] ? <CheckOutlined/> : <CloseOutlined/>;
                let fun = <div className={"data-item-fun-open"} style={{background: "#d41a1a"}} id={"line-fun"}>
                            {"删除"}
                          </div>;
                list.push(
                    <div className={"data-item"} key={item + index}
                         style={{marginBottom: index === (len - 1) ? "0" : ""}}
                         id={"line-ct:" + index}
                         onClick={(e) => {
                             this.launchPolicyFrame(e, item, findDataPojo)
                         }}
                    >
                        {this.loadLayout(name, id, root, area, date, push, adv, fun)}
                    </div>
                )
            })
        } else {
            list.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        ui.push(
            <Fragment>
                <div className={"data-header"}>
                    {this.loadLayout("政策名称", "ID", "发布者", "政策范围", "发布日期", "推送首页", "推送广告", "操作")}
                </div>
                <div className={"data-form"}>
                    {list}
                </div>
            </Fragment>
        )
        return ui;
    }

    loadLayout = (name, id, root, area, date, push, adv, fun) => {
        return (
            <Fragment>
                <div className={"data-item-name"} id={"line-ct-name"}>
                    {name}
                </div>
                <div className={"data-item-id"} id={"line-ct-id"}>
                    {id}
                </div>
                <div className={"data-item-type"} id={"line-ct-type"}>
                    {root}
                </div>
                <div className={"data-item-area"} id={"line-ct-area"}>
                    {area}
                </div>
                <div className={"data-item-date"} id={"line-ct-date"}>
                    {date}
                </div>
                <div className={"data-item-status"} id={"line-ct-push"}>
                    {push}
                </div>
                <div className={"data-item-status"} id={"line-ct-adv"}>
                    {adv}
                </div>
                <div className={"data-item-fun"} id={"line-ct-fun"}>
                    {fun}
                </div>
            </Fragment>
        )
    }

    launchPolicyFrame = (e, data, dataPojo) => {
        if (e.target.id.includes("line-ct")) {
            let id = data['policyId'];
            if (id) {
                window.location.href = "/dataPolicy?id=" + id
            }
        } else {
            this.handleOpenItem(data, dataPojo, "menu", "auth", 4, this.delDataPre)
        }
    }

    // pojoStr初始化
    initPojoStr = () => {
        return {
            bigData: null,
            clickNum: null,
            isAd: false,
            isPush: false,
            keyMap: null,
            policyBanner: null,
            policyCity: null,
            policyCtr: "中国",
            policyDate: moment().format('YYYY-MM-DD'),
            policyDes: null,
            policyId: null,
            policyName: null,
            policyPri: null,
            policyRoot: null,
            saveNum: null,
            geo: null,
        };
    }

    componentDidMount() {
        this.init(null, '3478', loadSetting.loadingBatch);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 判断selectId的变化执行findDataById接口获取id数据
        if (prevState.selectId !== this.state.selectId) {
            this.handleSelectId("dataPolicy");
        }
    }

    render() {
        let findDataPojo = this.state.findDataPojo;
        let partitioner = this.state.partitioner;
        let selectId = this.state.selectId;
        let loaded = this.state.loaded;
        let dataLoaded = this.state.dataLoaded;
        let isAdd = this.state.isAdd;

        let typeList = [
            {ct: pushTypes, key: "push", txt: "推送首页"},
            {ct: advTypes, key: "adv", txt: "推送广告"},
        ];

        let searchList = {
            0: "关键词",
            1: "数据id",
        };

        return (
            <div className={"page-wrap"}>
                {selectId == null ?
                    loaded ?
                        this.genLayout(typeList, searchList, "dataPolicy", this.loadCt(partitioner, findDataPojo), loadSetting, findDataPojo, partitioner)
                        : null
                    :
                    dataLoaded ? <PolicyFrame pojo={Object.assign({}, findDataPojo)} isAdd={isAdd} setPojo={this.setDataPojo} isSub={false}/> : null
                }
            </div>
        )
    }
}

export default DataPolicy;