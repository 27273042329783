import axios from "axios";
import {connectionError, findMsgByError, fail} from "./ErrorMgr";

const data = "responseData";

const error = "errorType";

const TempId = "SMS_236640043";

const Header = "https://servicemgrplatform.paton-tech.com";

// 静态资源路径
const ResourceHeader = Header + "/src/";

// url头
const urlHeader = "https://servicemgrplatform.paton-tech.com/";

// const RequestHeader = "http://localhost:8085";

const RequestHeader = Header + "/host/api";

const Dir = "";

// 发送短信验证码-客服
const SendPhoneValidUrl = RequestHeader + Dir + "/sendPhoneValid";

// 发送短信验证码-天使账号
const SendAnglePhoneValidUrl = RequestHeader + Dir + "/sendAnglePhoneValid";

// 获取天使账号手机号码
const FindAnglePhonesUrl = RequestHeader + Dir + "/findAnglePhones";

// 添加管理员账号
const AddHostUserUrl = RequestHeader + Dir + "/addHostUser";

// 管理员登录
const HostLoginUrl = RequestHeader + Dir + "/hostLogin";

// 管理员退出登录
const HostLogoutUrl = RequestHeader + Dir + "/hostLogout";

// 更换密码
const UpdateHostPwdUrl = RequestHeader + Dir + "/updateHostPwd";

// 更换操作密钥
const UpdatePassCodeUrl = RequestHeader + Dir + "/updatePassCode";

// 添加数据（获取itemId）
const AddSourceDataUrl = RequestHeader + Dir + "/addSourceData";

// 更新数据
const UpdateSourceDataUrl = RequestHeader + Dir + "/updateSourceData";

// 推送广告
const AdvSourceDataUrl = RequestHeader + Dir + "/advSourceData";

// 推送首页
const PushSourceDataUrl = RequestHeader + Dir + "/pushSourceData";

// 获取数据
const FindDataUrl = RequestHeader + Dir + "/findData";

// 获取数据数量
const FindDataSizeUrl = RequestHeader + Dir + "/findDataSize";

// 上下架数据
const SwitchDataShowUrl = RequestHeader + Dir + "/switchDataShow";

// 删除数据
const DelDataUrl = RequestHeader + Dir + "/delData";

// 根据id获取数据
const FindDataByIdUrl = RequestHeader + Dir + "/findDataById";

// 获取统计数据
const FindStatisticsUrl = RequestHeader + Dir + "/findStatistics";

// 根据id获取统计数据
const FindStatisticsByIdUrl = RequestHeader + Dir + "/findStatisticsById";

// 根据状态获取用户提交的科研成果/技术需求
const FindSubItemsByStatusUrl = RequestHeader + Dir + "/findSubItemsByStatus";

// 获取用户提交的科研成果/技术需求详情
const FindSubItemBySubIndexUrl = RequestHeader + Dir + "/findSubItemBySubIndex";

// 获取用户提交的科研成果/技术需求素材
const FindSubItemMediasUrl = RequestHeader + Dir + "/findSubItemMedias";

// 驳回用户提交素材
const RejectSubItemUrl = RequestHeader + Dir + "/rejectSubItem";

// 通过用户提交素材
const AcceptSubItemUrl = RequestHeader + Dir + "/acceptSubItem";

// 获取等待认证
const FindAwaitAuthListUrl = RequestHeader + Dir + "/findAwaitAuthList";

// 根据userId查询相关的认证
const CheckAuthsByUserUrl = RequestHeader + Dir + "/checkAuthsByUser";

// 获取认证资源
const CheckUserAuthMaterialsUrl = RequestHeader + Dir + "/checkUserAuthMaterials";

// 审核认证
const CommitAuthUrl = RequestHeader + Dir + "/commitAuth";

// 0是未支付订单（后台创建）1是已支付待审核 2是成功 3是失败 index(1:预约，2:vip，3:解锁，4:派通币，5：其他)
const CheckOrdersByStatusUrl = RequestHeader + Dir + "/checkOrdersByStatus";

// 获取订单size
const CheckOrderSizeByIdAndIndexUrl = RequestHeader + Dir + "/checkOrderSizeByIdAndIndex";

// 根据id获取订单
const CheckOrderByOrderIdUrl = RequestHeader + Dir + "/checkOrderByOrderId";

// 获取order服务内容
const CheckServiceByOrderUrl = RequestHeader + Dir + "/checkServiceByOrder";

// 确认订单
const ConfirmOrderUrl = RequestHeader + Dir + "/confirmOrder";

// 完成预约
const FinishAppointUrl = RequestHeader + Dir + "/finishAppoint";

// 查询已完成审核正等待编辑资料的expert
const FindAwaitEditExpertUrl = RequestHeader + Dir + "/findAwaitEditExpert";

// 获取expert数量
const CountAvailableExpertsUrl = RequestHeader + Dir + "/countAvailableExperts";

// 获取expert
const FindAvailableExpertsUrl = RequestHeader + Dir + "/findAvailableExperts";

// 获取专家预约情况
const FindExpertAppointsUrl = RequestHeader + Dir + "/findExpertAppoints";

// 根据id获取expert
const CheckExpertByUserIdUrl = RequestHeader + Dir + "/checkExpertByUserId";

const findHostNames = RequestHeader + Dir + "/findHostNames";

const findHostLogs = RequestHeader + Dir + "/findLog";

const refreshHost = RequestHeader + Dir + "/refreshHost";

const findHostInfo = RequestHeader + Dir + "/findHostInfos";

const addHost = RequestHeader + Dir + "/addHostUser";

const delHost = RequestHeader + Dir + "/delHost";

const updateHostLevel = RequestHeader + Dir + "/updateHostLevel";

const findVipList = RequestHeader + Dir + "/findVipList";

const findVipById = RequestHeader + Dir + "/findVipById";

const terminateVip = RequestHeader + Dir + "/stopVip";

const delayVip = RequestHeader + Dir + "/extendVip";

const delayVipBase = RequestHeader + Dir + "/extendVipBase";

const addNewVip = RequestHeader + Dir + "/openNewVip";

const findUsers = RequestHeader + Dir + "/findUsers";

const countUsers = RequestHeader + Dir + "/countUsers";

const checkUser = RequestHeader + Dir + "/checkUser";

const checkUserFavor = RequestHeader + Dir + "/checkUserFavor";

const findUserUnlockItems = RequestHeader + Dir + "/findUserUnlockItems";

const updateUserPhone = RequestHeader + Dir + "/updatePhone";

const disablePer = RequestHeader + Dir + "/disablePer";

const disableComp = RequestHeader + Dir + "/disableComp";

const updatePoints = RequestHeader + Dir + "/updatePoints";

const unlockItemForUser = RequestHeader + Dir + "/unlockItemForUser";

const checkPeriodBills = RequestHeader + Dir + "/checkPeriodBills";

const checkBillsByOrderId = RequestHeader + Dir + "/checkBillsByOrderId";

const checkBillsByUser = RequestHeader + Dir + "/checkBillsByUser";

const checkUserRefill = RequestHeader + Dir + "/checkUserRefill";

const plusUserPtc = RequestHeader + Dir + "/plusUserPtc";

const minusUserPtc = RequestHeader + Dir + "/minusUserPtc";

const findStatistics = RequestHeader + Dir + "/findStatistics";

const findUserSubscript = RequestHeader + Dir + "/findUserSubscript";

const sendPostBack = (pojo, cred, back: DataBack) => {
    console.log("post请求: ", pojo);
    sendPostRequest(pojo, cred).then(res => {
        console.log("post的res: ", res);
        if (res === connectionError) {
            fail(findMsgByError(connectionError));
        } else {
            if (res && res.hasOwnProperty("success") && res.success) {
                back.successBack(res[data])
            } else {
                back.failBack(res[error])
            }
        }
    })
}

const sendGetBack = (url, params, cred, back: DataBack) => {
    console.log("get请求: ", params);
    sendGetRequest(url, params, cred).then(res => {
        console.log("get的res: ", res);
        if (res === connectionError) {
            fail(findMsgByError(connectionError));
        } else {
            if (res && res.hasOwnProperty("success") && res.success) {
                back.successBack(res[data])
            } else {
                back.failBack(res[error])
            }
        }
    })
}

async function sendPostRequest(pojo, cred) {
    axios.defaults.withCredentials = cred;
    return await axios.post(pojo.url, JSON.stringify(pojo.data)).then(
        function (response) {
            return response.data;
        }
    ).catch(function (error) {
        console.log(error)
        return connectionError;
    })
}

async function sendGetRequest(url, params, cred) {
    axios.defaults.withCredentials = cred;
    return await axios.get(url, {
        params: params,
    }).then(
        function (response) {
            return response.data;
        }
    ).catch(function (error) {
        console.log(error)
        return connectionError;
    })
}

const genPojo = (url, data) => {
    return {
        url: url,
        data: data,
    }
}

export {
    Header,
    ResourceHeader,
    urlHeader,
    data,
    error,
    TempId,
    sendPostRequest,
    sendGetRequest,
    genPojo,
    SendPhoneValidUrl,
    SendAnglePhoneValidUrl,
    FindAnglePhonesUrl,
    AddHostUserUrl,
    HostLoginUrl,
    HostLogoutUrl,
    UpdateHostPwdUrl,
    UpdatePassCodeUrl,
    AddSourceDataUrl,
    UpdateSourceDataUrl,
    AdvSourceDataUrl,
    PushSourceDataUrl,
    FindDataUrl,
    FindDataSizeUrl,
    SwitchDataShowUrl,
    DelDataUrl,
    FindDataByIdUrl,
    FindStatisticsUrl,
    FindStatisticsByIdUrl,
    FindSubItemsByStatusUrl,
    FindSubItemBySubIndexUrl,
    FindSubItemMediasUrl,
    RejectSubItemUrl,
    AcceptSubItemUrl,
    FindAwaitAuthListUrl,
    CheckAuthsByUserUrl,
    CheckUserAuthMaterialsUrl,
    CommitAuthUrl,
    CheckOrdersByStatusUrl,
    CheckOrderSizeByIdAndIndexUrl,
    CheckOrderByOrderIdUrl,
    CheckServiceByOrderUrl,
    ConfirmOrderUrl,
    FinishAppointUrl,
    FindAwaitEditExpertUrl,
    CountAvailableExpertsUrl,
    FindAvailableExpertsUrl,
    FindExpertAppointsUrl,
    CheckExpertByUserIdUrl,
    sendPostBack,
    sendGetBack,
    findHostNames,
    findHostLogs,
    refreshHost,
    findHostInfo,
    addHost,
    delHost,
    updateHostLevel,
    findVipList,
    findVipById,
    terminateVip,
    delayVip,
    addNewVip,
    findUsers,
    countUsers,
    checkUser,
    checkUserFavor,
    findUserUnlockItems,
    delayVipBase,
    updateUserPhone,
    disablePer,
    disableComp,
    updatePoints,
    unlockItemForUser,
    checkPeriodBills,
    checkBillsByOrderId,
    checkBillsByUser,
    checkUserRefill,
    plusUserPtc,
    minusUserPtc,
    findStatistics,
    findUserSubscript
}