import React, {Component} from "react";
import "./check.css";
import {CloseOutlined} from "@ant-design/icons";
import {delWin} from "../../tool/Luncher";

class CommitWin extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let classWrap = this.props.class;
        let parentId = this.props.parentId;
        let winId = this.props.winId;
        let pojo = this.props.pojo;

        return (
            <div className={classWrap}>
                <div className={"auth-close"}>
                    <CloseOutlined style={{fontSize: "22px", marginRight: "10px"}} onClick={() => {delWin(parentId, winId)}}/>
                </div>
                <div className={"commit-ct"}>
                    {this.props.funArea()}
                    <div className={"commit-ct-item"}>
                        <button className={"search-bt"}
                                onClick={() => {this.props.sendConfirm(parentId, winId, pojo)}}
                        >
                            {"确认"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommitWin;