import React, {Component} from "react";
import "./data.css";
import {InitData, InitSelector, RemoveLoginInfo, storage} from "../../tool/Const";
import SelectorWin from "../component/SelectorWin";
import Partitioner from "../../tool/Partitioner";
import LunchWin from "../../tool/Tools";
import AuthWin from "../../tool/AuthWin";
import DataOperator from "../component/DataOperator";
import {fail, failReturn, findMsgByError, loginOut, successReturn} from "../../tool/http/ErrorMgr";
import {urlHeader} from "../../tool/http/Request";

class DataParent extends Component<LayoutProps, DataState> {
    constructor(props) {
        super(props);
        this.state = {
            findDataPojo: null,
            loaded: false,
            partitioner: null,
            selectId: null,
            isAdd: false,  // 是否新增
        }
    }

    // 加载行业领域
    loadArea = (areas) => {
        let lst = [];
        if (areas) {
            let tags = areas.split(";");
            tags.forEach((it) => {
                    lst.push(
                        <span className={"area-tag"} key={it}>
                            {it}
                        </span>
                    )
                }
            )
        }
        return lst;
    }

    sendSelector = (e, tag) => {
        let val = e.target.value;
        if (val) {
            let dataPojo = this.state.findDataPojo;
            dataPojo.selector = InitSelector(null, null, null, 3, 3, 3);
            let selector = dataPojo.selector;
            selector[tag] = Number.parseInt(val);
            this.setState({
                findDataPojo: Object.assign({}, dataPojo),
            })
            console.log(selector)
        }
    }

    // 搜索类型
    setSearchKey = (e) => {
        let val = e.target.value;
        console.log(val)
        this.setState({
            searchKey: val,
        })
    }

    // 搜索文本
    setText = (e) => {
        let val = e.target.value;
        console.log(val);
        this.setState({
            text: val,
        })
    }

    // 根据searchKey切换搜索类型(0=keyMap;1=id;2=refId)
    switchSearchKey = (key) => {
        switch (key) {
            case "0": {
                return "keyMap";
            }
            case "1": {
                return "id";
            }
            case "2": {
                return "refId";
            }
            default: {
                return "keyMap";
            }
        }
    }

    // 执行搜索
    searchInfo = () => {
        let text = this.state.text;
        if (text) {
            let searchKey = this.state.searchKey;
            if (searchKey === "1") {
                let url = window.location.href;
                let toUrl = url + "?id=" + text;
                console.log(toUrl);
                window.location.href = toUrl;
            } else {
                let key = this.switchSearchKey(searchKey);
                let dataPojo = this.state.findDataPojo;
                dataPojo.selector = InitSelector(null, null, null, 3, 3, 3);
                let selector = dataPojo.selector;
                selector[key] = text;
                this.setState({
                    findDataPojo: Object.assign({}, dataPojo),
                })
                console.log(selector)
            }
        }
    }

    // 加载selector
    genSelector = (typeList, searchList, urlInfo) => {
        let selector = this.state.findDataPojo.selector;
        console.log("genSelector: ", selector)
        return <SelectorWin selector={selector}
                            typeList={typeList}
                            searchList={searchList}
                            urlInfo={urlInfo}
                            sendFun={this.sendSelector}
                            setSearchKey={this.setSearchKey}
                            setText={this.setText}
                            searchInfo={this.searchInfo}
                            isSub={false}/>
    }

    // 加载layout
    genLayout = (typeList, searchList, urlInfo, ct, loadSetting, findDataPojo, partitioner) => {
        let operator = new DataOperator();
        return (
            <div className={"page-ct"}>
                {this.genSelector(typeList, searchList, urlInfo)}
                <div className={"data-ct"}>
                    {ct}
                </div>
                <div className={"data-partition"}
                     style={{display: partitioner.showData.length > 0 ? "" : "none"}}
                >
                    <Partitioner parent={this} loadSetting={loadSetting}
                                 options={findDataPojo} sizeFun={operator.findDataSize} dataFun={operator.findData}/>
                </div>
            </div>
        )
    }

    // 初始化pojo
    initDataPojo = (pojoStr, index, pageLimit) => {
        this.setState({
            findDataPojo: this.initParam(pojoStr, index, pageLimit, 1),
            loaded: true,
            partitioner: {
                totalSize: 0,
                showData: [],
                loadComplete: false,
            },
        })
    }

    initParam = (pojoStr, index, pageLimit, pageNo) => {
        let userInfo = storage.get("hostInfo");
        return InitData(pojoStr, index, pageLimit, pageNo, userInfo.hostAccount, null, null, null, 3, 3, 3);
    }

    // 初始化
    init = (pojoStr, index, pageLimit) => {
        this.initDataPojo(pojoStr, index, pageLimit);
        let url = window.location.href;
        let param = url.split("?id=");
        if (param.length !== 1) {
            let id = param[1];
            this.setState({
                selectId: id,
            })
        }
    }

    // 上下架
    handleOpenItem = (data, dataPojo, id, winId, level, backFun) => {
        dataPojo.pojoStr = JSON.stringify(data);
        dataPojo.disable = data['isOpen'];
        this.setState({
            findDataPojo: dataPojo,
        },
            () => {
                this.launchAuthWin(id, winId, level, backFun)
            });
    }

    // launch authWin
    launchAuthWin = (id, winId, level, backFun) => {
        let userInfo = storage.get("hostInfo");
        let phone = userInfo.hostPhone;
        LunchWin(<AuthWin level={level} parentId={id} phone={phone} winId={winId} backFun={backFun} account={userInfo.hostAccount}/>, id, winId);
    }

    // 生成authPojo
    genAuthPojo = (authPojo, angle, angleSec, sec, pass) => {
        authPojo.anglePhone = angle;
        authPojo.secCode = sec;
        authPojo.angleSec = angleSec;
        authPojo.passCode = pass;
        return authPojo;
    }

    // 数据上下架authWin执行操作（天使手机、验证码、操作密钥）
    switchDataShowPre = (angle, angleSec, sec, pass, myBack) => {
        console.log("setAuthPojo: ", angle, angleSec, sec, pass, myBack)
        let dataPojo = this.state.findDataPojo;
        dataPojo.authPojo = this.genAuthPojo(dataPojo.authPojo, angle, angleSec, sec, pass);
        this.setState({
            findDataPojo: dataPojo,
        }, () => {
            myBack.callBack();
            this.switchDataShow();
        })
    }

    // 切换数据上下架
    switchDataShow = () => {
        let operator = new DataOperator();

        const switchData: DataBack = {
            successBack(res: any) {
                successReturn("数据发布状态修改成功", window.location.href)
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        operator.switchData(switchData, this.state.findDataPojo)
    }

    // 数据上下架authWin执行操作（天使手机、验证码、操作密钥）
    delDataPre = (angle, angleSec, sec, pass, myBack) => {
        let dataPojo = this.state.findDataPojo;
        dataPojo.authPojo = this.genAuthPojo(dataPojo.authPojo, angle, angleSec, sec, pass);
        this.setState({
            findDataPojo: dataPojo,
        }, () => {
            myBack.callBack();
            this.delData();
        })
    }

    delData = () => {
        let operator = new DataOperator();

        const switchData: DataBack = {
            successBack(res: any) {
                successReturn("数据删除成果", window.location.href)
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        operator.delData(switchData, this.state.findDataPojo)
    }

    // setState findDataPojo
    setDataPojo = (pojo, back, backT) => {
        this.setState({
                findDataPojo: pojo,
            },() =>
                back,
                backT,
        )
    }

    // 判断selectId的变化执行findDataById接口获取id数据
    handleSelectId = (urlInfo) => {
        let dataPojo = this.state.findDataPojo;

        if (this.state.selectId !== "0") {
            let selector = dataPojo.selector;
            selector.id = this.state.selectId;
            this.getDataById(dataPojo, urlInfo);
        } else {
            dataPojo.pojoStr = this.initPojoStr();
            this.setState({
                findDataPojo: dataPojo,
                dataLoaded: true,
                isAdd: true,
            })

        }
    }

    // 根据itemId获取数据
    getDataById = (dataPojo, urlInfo) => {
        let operator = new DataOperator();

        let _this = this;
        const findDataById: DataBack = {
            successBack(res: any) {
                dataPojo.pojoStr = res;
                _this.setState({
                    findDataPojo: dataPojo,
                    dataLoaded: true,
                })
            },
            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    failReturn(findMsgByError(error), urlHeader + urlInfo)
                }
            }
        }

        operator.findDataById(findDataById, dataPojo);
    }

}

export default DataParent;