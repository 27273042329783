import {CloseOutlined} from "@ant-design/icons";
import {delWin} from "../../tool/Luncher";
import React from "react";

class VipDelayHandler extends React.Component<VipDelayProps, null> {

    genDelayWin = (winId, setDays, handleDelay) => {
        return (
            <div className={"vip-delay-win"}>
                <div className={"auth-close"}>
                    <CloseOutlined style={{fontSize: "22px", marginRight: "10px"}} onClick={() => {
                        delWin("menu", winId)
                    }}/>
                </div>
                <div className={"vip-delay-win-ct"}>
                    <div style={{height: "60px"}}>
                        <span className={"vip-win-des"}>{"延长 / 赠予用户 VIP 特权"}</span>
                        <input className={"search-input"} style={{width: "60px", margin: "0 10px"}} maxLength={3}
                               onChange={(e) => {
                                   setDays(e)
                               }}/>
                        <span className={"vip-win-des"}>{"天"}</span>
                    </div>
                    <div>
                        <button className={"search-bt"} onClick={() => {
                            handleDelay(winId)
                        }}>{"确认"}</button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let winId = this.props.winId;
        let setDays = this.props.setDays;
        let handleDelay = this.props.handleDelay;
        return this.genDelayWin(winId, setDays, handleDelay);
    }
}

export default VipDelayHandler;