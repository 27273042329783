import React, {Fragment} from "react";
import "./order.css";
import OrderParent from "./OrderParent";
import EMPTY from "../../assets/img/record_empty.svg";
import {genProcessBack, PayMethod, SchTime} from "../../tool/Const";
import OrderOperator from "./OrderOperator";
import {ArrowLeftOutlined} from "@ant-design/icons";
import LunchWin from "../../tool/Tools";
import RejectWin from "../check/RejectWin";
import {successReturn} from "../../tool/http/ErrorMgr";

const loadSetting = {
    loadingBatch: 20,
    classes: {
        outer: "data-partition-ct",
    }
}

const awaitStyle = {
    orderId: '20%',
    userId: '14%',
    realPrice: '8%',
    rmbPrice: '8%',
    paiPrice: '8%',
    disPrice: '8%',
    method: '8%',
    subTime: '12%',
    fun: '14%',
}

const otherStyle = {
    orderId: '24%',
    userId: '18%',
    realPrice: '9%',
    rmbPrice: '9%',
    paiPrice: '9%',
    disPrice: '9%',
    method: '9%',
    subTime: '13%',
    fun: '0%',
}

const ExpertAppointE = [
    "审核流程中", "专家预约成功", "专家预约失败", "当前预约已取消", "已完成此次专家咨询"
]

class OrderExpert extends OrderParent{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    loadCt = (partitioner, options) => {
        let ui = [];
        let list = [];
        let showData = partitioner.showData;
        let len = showData.length;

        let hasFun = options.status === 1 || options.status === 2 || options.status === 5 || options.status === 6;
        let style = hasFun ? awaitStyle : otherStyle;
        let funH = hasFun ? "操作" : null;

        if (len > 0) {
            showData.forEach((item, index) => {
                let orderId = item['orderId'];
                let userId = item['userId'];
                let realPrice = item['realPrice'];
                let rmbPrice = item['rmbPrice'];
                let paiPrice = item['paiPrice'];
                let disPrice = item['disPrice'];
                let method = PayMethod[item["method"]];
                let subTime = item['subTime'];
                let funT = this.switchFunCt(index, options, item);
                list.push(
                    <div className={"data-item"} key={item + index}
                         style={{marginBottom: index === (len - 1) ? "0" : ""}}
                         id={"line-ct:" + index}
                         onClick={(e) => {
                             this.launchService(e, partitioner, index, options)
                         }}
                    >
                        {this.loadLayout(style, orderId, userId, realPrice, rmbPrice, paiPrice, disPrice, method, subTime, funT)}
                    </div>
                )
            })
        } else {
            list.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        ui.push(
            <Fragment>
                <div className={"data-header"}>
                    {this.loadLayout(style, "订单id", "用户id", "应付金额", "应付人民币", "应付湃通币", "折扣金额", "支付方式", "订单时间", funH)}
                </div>
                <div className={"data-form"}>
                    {list}
                </div>
            </Fragment>
        )
        return ui;
    }

    switchFunCt = (index, options, item) => {
        switch (options.status) {
            case 0: return null;
            case 1: {
                return (
                    <div className={"data-item-fun-open"} style={{background: "#d41a1a"}} id={"line-fun-fail"}
                         onClick={() => {this.confirmOrder(3, index, options)}}
                    >
                        {"驳回"}
                    </div>
                )
            }
            case 2: {
                return (
                    [
                    <div className={"data-item-fun-open"} style={{background: "#099240"}} id={"line-fun-cancel"}
                         onClick={() => {this.finishAppointPre(options, item)}}
                    >
                        {"完成"}
                    </div>,
                    <div className={"data-item-fun-open"} style={{marginLeft: '10px', background: "#dcbf40"}} id={"line-fun-cancel"}
                         onClick={() => {this.confirmOrder(4, index, options)}}
                    >
                        {"取消"}
                    </div>]
                )
            }
            case 3: return null;
            case 4: return null;
            case 5: return null;
            case 6: {
                return (
                    <div className={"data-item-fun-open"} style={{background: "#099240"}} id={"line-fun-success"}
                          onClick={() => {this.confirmOrder(2, index, options)}}
                    >
                        {"通过"}
                    </div>
                )
            }
            default: return null;
        }
    }

    finishAppointPre = (options, item) => {
        console.log(options);
        console.log(item);
        let serviceItem = item.serviceItem;
        let expertId = serviceItem.split(":")[1];
        let params = {
            userId: item.userId,
            auth: options.auth,
            orderId: item.orderId,
            expertId: expertId,
        };
        this.setState({
            expertAppointPojo: params,
        },
            () => {
                this.launchAuthWin("menu", "finish", 3, this.finishAppoint);
            })
    }

    finishAppoint = (angle, angleSec, sec, pass, myBack) => {
        let pojo = this.state.expertAppointPojo;
        pojo.auth = this.genAuthPojo(pojo.auth, angle, angleSec, sec, pass);
        this.setState({
                expertAppointPojo: pojo,
            },
            () => {
                myBack.callBack();
                this.finishAppointFinal();
            })
    }

    finishAppointFinal = () => {
        let operator = new OrderOperator();

        const finishBack: WinBack = {
            callDataBack(data: any) {
                console.log(data);
                successReturn("预约已完成", window.location.href)
            }
        }

        operator.finishAppoint(genProcessBack(finishBack, true), this.state.expertAppointPojo);
    }


    launchService = (e, partitioner, index, options) => {
        if (e.target.id.includes("line-ct")) {
            this.getService(partitioner, index, options);
        }
    }

    getService = (partitioner, index, options) => {
        let orderPojo = partitioner.showData[index];
        options.orderId = orderPojo.orderId;

        let operator = new OrderOperator();

        let _this = this;
        const serviceBack: WinBack = {
            callDataBack(data: any) {
                console.log("service: ", data);
                _this.setState({
                    serviceData: data,
                    selected: true,
                })
            }
        }

        operator.getService(genProcessBack(serviceBack, true), options);
    }

    genService = (serviceData) => {
        return (
            <div className={"page-inner"}>
                <div className={"edit-header"} style={{background: 'rgb(238, 238, 238)'}}>
                    <div className={"item-back"} onClick={() => {window.location.href = "/orderExpert"}}>
                        <ArrowLeftOutlined />
                    </div>
                    <span className={"user-header-title"}>{}</span>
                </div>
                <div style={{minWidth: "1000px", margin: "20px 0 0", height: "calc(100% - 120px)"}}>
                    <div className={"user-info-outer"}>
                        {this.loadService(serviceData)}
                    </div>
                </div>
            </div>
        )
    }

    loadService = (serviceData) => {
        let expertDes = serviceData.expertDes;
        let userDes = serviceData.userDes;
        return (
            <Fragment>
                {this.fillDetailLine(<span className={"user-info-big-title"}>{"专家预约订单信息"}</span>, "t")}
                {this.fillDetailLine([this.genCommTag("订单ID", serviceData.orderId, "orderId"),
                    this.genCommTag("提交时间", serviceData.subTime, "subTime")], "ct-1")}
                {this.fillDetailLine([this.genCommTag("预约日期", serviceData.appointDate, "appointDate"),
                    this.genCommTag("预约时间", this.handleAppTime(serviceData.appointTime), "appointTime")], "ct-2")}
                {this.fillDetailLine([this.genCommTag("预约状态", ExpertAppointE[serviceData.appointStatus], "appointStatus"),
                    this.genCommTag("预约主题", serviceData.appointTitle, "appointTitle") ], "ct-3")}
                {this.fillDetailLine(null, "expert-s")}
                {this.fillDetailLine(<span className={"user-info-big-title"}>{"专家信息"}</span>, "expert")}
                {this.fillDetailLine(
                    [this.genCommTag("专家id", expertDes.userId, "userId"),
                        this.genCommTag("专家名字", expertDes.userReal ? expertDes.userReal : "待补充", "userReal"),
                        this.genCommTag("专家昵称", expertDes.userNick, "userNick")], "expert-info-1")}
                {this.fillDetailLine([this.genCommTag("专家手机", expertDes.userPhone, "userPhone"),
                    this.genCommTag("专家领域", expertDes.userArea, "userArea")], "expert-info-2")}
                {this.fillDetailLine(null, "user-s")}
                {this.fillDetailLine(<span className={"user-info-big-title"}>{"预约者信息"}</span>, "user")}
                {this.fillDetailLine(
                    [this.genCommTag("用户id", userDes.userId, "userId"),
                        this.genCommTag("用户名字", userDes.userReal ? userDes.userReal : "待补充", "userReal"),
                        this.genCommTag("用户昵称", userDes.userNick, "userNick")], "user-info-1")}
                {this.fillDetailLine([this.genCommTag("用户手机", userDes.userPhone, "userPhone"),
                    this.genCommTag("所在单位", userDes.userComp, "userComp"),
                    this.genCommTag("所属行业", userDes.userArea, "userArea")], "expert-info-2")}
            </Fragment>
        )
    }

    fillDetailLine = (items, key) => {
        return (
            <div className={"user-detail-line"} key={key}>
                {items}
            </div>
        )
    }

    genCommTag = (name, val, key) => {
        return (
            <div className={"user-line-item"} key={key}>
                <span className={"user-detail-title"}>{name}</span>
                <span className={"user-detail-value"}>{val}</span>
            </div>
        )
    }

    handleAppTime = (time) => {
        let startTime = SchTime[time.split(';')[0]];
        let endTime = SchTime[time.split(';')[2]];
        return startTime + " - " + endTime;
    }

    componentDidMount() {
        this.init(1, 1, loadSetting.loadingBatch);
    }

    render() {
        let options = this.state.options;
        let partitioner = this.state.partitioner;
        let loaded = this.state.loaded;
        let selected = this.state.selected;
        let serviceData = this.state.serviceData;

        // console.log("options: ", options);
        // console.log("partitioner: ", partitioner);
        console.log("requestPojo: ", this.state.requestPojo);

        return (
            <div className={"page-wrap"}>
                {loaded ?
                    (selected ? this.genService(serviceData) : this.genLayout(this.loadCt(partitioner, options), loadSetting, options, partitioner))
                    : null
                }
            </div>
        );
    }
}

export default OrderExpert;