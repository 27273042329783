import React, {Component} from "react";
import "./tool.css";
import {CloseOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {delWin} from "./Luncher";

class PicWin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pics: ["1", "2", "3"],
            select: 1,
            scale: 1,
        }
    }

    handleSize = (sc) => {
        let s = sc === 2 ? 1 : 2;
        this.setState({
            scale: s,
        })
    }

    switchPic = (forward, select, size) => {
        let num = forward ? select + 1 : select - 1;
        if (num > 0 && num < size + 1) {
            this.setState({
                select: num,
            })
        }
    }

    loadPic = (pics, select) => {
        let nowPic = "data:image/jpeg;base64," + pics[select - 1];
        return (
            <img className={"img-style contain"} src={nowPic} alt={""}/>
        )
    }

    render() {
        let pics = this.props.pics;
        let select = this.state.select;
        let scale = this.state.scale;
        let picSize = pics.length;
        return (
            <div className={"pic-win"}>
                <div className={"close-pic"}>
                    <span style={{marginLeft: "10px"}}>
                        <span>{"第 "}</span>
                        <span>{select}</span>
                        <span>{" 张 / 总张数 "}</span>
                        <span>{picSize}</span>
                    </span>
                    <CloseOutlined style={{fontSize: "20px", marginRight: "10px"}} onClick={() => {delWin("menu", "pic")}}/>
                </div>
                <div className={"pic-layout"}>
                    <div className={"pic-switch"}
                         onClick={() => {this.switchPic(false, select, picSize)}}
                    >
                        <LeftOutlined style={{cursor: "pointer"}}/>
                    </div>
                    <div className={"pic-ct"} style={{transform: "scale(" + scale + ")"}}
                         onClick={() => {this.handleSize(scale)}}
                    >
                        {this.loadPic(pics, select)}
                    </div>
                    <div className={"pic-switch"}
                         onClick={() => {this.switchPic(true, select, picSize)}}
                    >
                        <RightOutlined style={{cursor: "pointer"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default PicWin;