import React, {Component} from "react";
import "./check.css";
import {CloseOutlined} from "@ant-design/icons";
import {delWin} from "../../tool/Luncher";

class RejectWin extends Component<null, RejectState> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleInput = (e, pojo, tag) => {
        let val = e.target.value;
        if (val) {
            pojo[tag] = val;
        }
    }

    render() {
        let parentId = this.props.parentId;
        let winId = this.props.winId;
        let level = this.props.level;
        let pojo = this.props.pojo;
        let id = this.props.id;
        let tag = this.props.tag;

        console.log("驳回pojo：", pojo);

        return (
            <div className={"reject-win"}>
                <div className={"auth-close"}>
                    <CloseOutlined style={{fontSize: "22px", marginRight: "10px"}}
                                   onClick={() => {delWin(parentId, winId)}}
                    />
                </div>
                <div className={"reject-ct"}>
                    <div className={"reject-ct-item"}>
                        <span style={{width: '80px'}}>{"id"}</span>
                        <span>{id}</span>
                    </div>
                    <div className={"reject-ct-item"}>
                        <span style={{width: '80px'}}>{"驳回理由"}</span>
                        <input className={"search-input"} style={{ width: "240px"}}
                               key={"reason"}
                               onChange={(e) => {this.handleInput(e, pojo, tag)}}
                        />
                    </div>
                    <div className={"reject-ct-item"}>
                        <button className={"search-bt"}
                                onClick={() => {this.props.submitFun(parentId, winId, level, pojo)}}
                        >
                            {"确认"}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default RejectWin;