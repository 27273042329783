import React, {Component} from "react";
import {genProcessBack, RemoveLoginInfo, scrollToAnchor, storage} from "../../tool/Const";
import {fail, failReturn, findMsgByError, loginOut} from "../../tool/http/ErrorMgr";
import {
    addHost,
    delHost,
    findHostInfo,
    genPojo,
    sendGetBack,
    sendPostBack,
    updateHostLevel
} from "../../tool/http/Request";
import "../data/data.css"
import "../../tool/tool.css"
import LunchWin from "../../tool/Tools";
import AuthWin from "../../tool/AuthWin";

class HostMgr extends Component<null, HostState> {
    constructor(props) {
        super(props);
        this.state = {
            hostInfo: "",
            hostData: [],
            loaded: false,
            openAdd: false,
            tableItems: [],
            newHost: {
                authLevel: 1,
                authPojo: null,
                hostAccount: null,
                hostPhone: null,
                hostPwd: null,
            },
            delAccount: "",
            level: 0,
        }
    }

    componentDidMount() {
        const host: HostInfo = storage.get("hostInfo");
        let hostPhone = host.hostPhone;
        host.authPojo = {
            phone: hostPhone,
            account: host.hostAccount,
        };
        let level = host.authLevel;
        let dom = this;
        const hostInfoBack: DataBack = {
            successBack(res: any) {
                let lst = dom.loadDataList(res, level, hostPhone);
                dom.setState({
                    hostInfo: host,
                    hostData: res,
                    tableItems: lst,
                    loaded: true,
                })
            },

            failBack(error: any) {
                if (error === loginOut) {
                    RemoveLoginInfo();
                    failReturn(findMsgByError(error), window.location.href);
                } else {
                    fail(findMsgByError(error))
                }
            }
        }

        sendGetBack(findHostInfo, {auth: host.authPojo, level: level}, true, hostInfoBack);
    }

    loadingDataLine = (account, phone, lastDate, regDate, level, op) => {
        return [
            <div className={"host-account-item"} key={"h-account"}>
                {account}
            </div>,
            <div className={"host-phone-item"} key={"h-phone"}>
                {phone}
            </div>,
            <div className={"host-last-item"} key={"h-last"}>
                {lastDate}
            </div>,
            <div className={"host-reg-item"} key={"h-reg"}>
                {regDate}
            </div>,
            <div className={"host-level-item"} key={"h-level"}>
                {level}
            </div>,
            <div className={"host-level-op"} key={"h-op"}>
                {op}
            </div>
        ];
    }

    handleDelHost = (account, hostPhone) => {
        let id = "menu";
        let winId = "host-auth";
        this.setState({
            delAccount: account
        }, () => {
            LunchWin(<AuthWin level={2} parentId={id} phone={hostPhone} winId={winId}
                              backFun={this.processDel} account={this.state.hostInfo.hostAccount}/>, id, winId);
        })
    }

    processDel = (angle, angleSec, sec, pass, myBack) => {
        let account = this.state.delAccount;
        if (account) {
            let auth = this.genAuth(angle, angleSec, sec, pass);
            sendGetBack(delHost, {auth: auth, account: account}, true, genProcessBack(this.successBack(myBack), false));
        } else {
            fail("请选择需要删除的账户！")
        }
    }

    successBack = (myBack) => {
        const successBack: WinBack = {
            callBack() {
                myBack.callBack();
                window.location.reload();
            }
        }
        return successBack
    }

    processLevelChange = (angle, angleSec, sec, pass, myBack) => {
        let account = this.state.delAccount;
        let level = this.state.level;
        if (account) {
            let auth = this.genAuth(angle, angleSec, sec, pass);
            sendGetBack(updateHostLevel, {
                auth: auth,
                account: account,
                level: level
            }, true, genProcessBack(this.successBack(myBack), false));
        } else {
            fail("请选择需要更新权限的账户！")
        }
    }

    setNewHost = (e, tag, newHost) => {
        let val = e.target.value;
        if (val) {
            newHost[tag] = val;
            this.setState({
                newHost: newHost
            })
        }
    }

    loadDataList = (hostData, hostLevel, hostPhone) => {
        let len = hostData.length;
        if (len === 0) {
            return null;
        }
        let lst = [];
        hostData.forEach((host, i) => {
            let account = host["hostAccount"];
            let level = host["authLevel"];
            let del = <button className={"host-button"} style={{background: "#dc2929"}} onClick={() => {
                this.handleDelHost(account, hostPhone)
            }}>{"删除账号"}</button>
            lst.push(<div className={"log-line"} key={i} id={i + "data"}
                          style={{
                              border: "2px solid rgba(125, 163, 205, 0.4)",
                              margin: i === 0 ? "0 0 8px 0" : i === (len - 1) ? "8px 0 0 0" : "8px 0"
                          }}>
                {this.loadingDataLine(account, host["hostPhone"], host["lastDate"], host["regDate"],
                    this.loadLevelChange(level, hostLevel, true, account, hostPhone), del)}
            </div>)
        })

        return lst;
    }

    loadLevels = (hostLevel) => {
        let levels = [];
        for (let i = 0; i < hostLevel - 1; i++) {
            let level = i + 1;
            levels.push(
                <option value={level} key={i}>
                    {level}
                </option>
            )
        }
        return levels;
    }

    handleSettingLevel = (e, needSubmit, account, hostPhone) => {
        let val = e.target.value;
        if (val) {
            let level = parseInt(val);
            if (needSubmit) {
                let id = "menu";
                let winId = "host-auth";
                this.setState({
                    delAccount: account,
                    level: level,
                }, () => {
                    LunchWin(<AuthWin level={2} parentId={id} phone={hostPhone} winId={winId}
                                      backFun={this.processLevelChange}
                                      account={this.state.hostInfo.hostAccount}/>, id, winId);
                })
            } else {
                let newHost = this.state.newHost;
                newHost.authLevel = level;
                this.setState({
                    newHost: newHost,
                })
            }
        }
    }

    genAuth = (angle, angleSec, sec, pass) => {
        let authPojo = this.state.hostInfo.authPojo;
        authPojo.anglePhone = angle;
        authPojo.secCode = sec;
        authPojo.angleSec = angleSec;
        authPojo.passCode = pass;
        return authPojo;
    }

    processAdd = (angle, angleSec, sec, pass, myBack) => {
        console.log("setAuthPojo: ", angle, angleSec, sec, pass, myBack)
        let newHost = this.state.newHost;
        newHost.authPojo = this.genAuth(angle, angleSec, sec, pass);
        console.log(newHost)

        sendPostBack(genPojo(addHost, newHost), true, genProcessBack(this.successBack(myBack), false));
    }

    submitNewHost = (hostPojo, hostPhone) => {
        if (!hostPojo.hostAccount || !hostPojo.hostPwd || !hostPojo.hostPhone) {
            fail("请正确填写信息！")
            return;
        }
        let id = "menu";
        let winId = "host-auth";
        LunchWin(<AuthWin level={2} parentId={id} phone={hostPhone} winId={winId}
                          backFun={this.processAdd} account={this.state.hostInfo.hostAccount}/>, id, winId);
    }

    loadLevelChange = (level, hostLevel, needSubmit, account, hostPhone) => {
        return <select className={"host-input"} defaultValue={level} onChange={(e) => {
            this.handleSettingLevel(e, needSubmit, account, hostPhone)
        }}>
            {this.loadLevels(hostLevel)}
        </select>
    }

    removeAdd = () => {
        let tableItems = this.state.tableItems;
        let lst = [];
        let len = tableItems.length;
        console.log(tableItems)
        for (let i = 0; i < len - 1; i++) {
            lst.push(tableItems[i])
        }
        this.setState({
            openAdd: false,
            tableItems: lst,
        }, () => {
            scrollToAnchor(0 + "data")
        })
    }

    addUserEditLine = (openAdd, tableItems, hostLevel, newHost, hostPhone) => {
        if (!openAdd) {
            let account = <div style={{display: "flex"}}>
                <input className={"host-input"} style={{width: "150px", marginRight: "10px"}} placeholder={"请输入账号"}
                       maxLength={20} onChange={(e) => {
                    this.setNewHost(e, "hostAccount", newHost)
                }}/>
                <input className={"host-input"} style={{width: "150px"}} placeholder={"请输入密码"} maxLength={20}
                       onChange={(e) => {
                           this.setNewHost(e, "hostPwd", newHost)
                       }}/>
            </div>;
            let phone = <input className={"host-input"} style={{width: "130px"}} placeholder={"请输入绑定手机"}
                               maxLength={11} onChange={(e) => {
                this.setNewHost(e, "hostPhone", newHost)
            }}/>;
            let op = <div>
                <button className={"host-button"} style={{background: "#16c133", margin: "8px"}} onClick={() => {
                    this.submitNewHost(newHost, hostPhone)
                }}>
                    {"确认"}
                </button>
                <button className={"host-button"} style={{background: "#e7aa00", margin: "8px"}} onClick={() => {
                    this.removeAdd()
                }}>
                    {"取消"}
                </button>
            </div>
            let lst = [];
            tableItems.forEach((item) => {
                lst.push(item)
            })
            lst.push(
                <div className={"log-line"} key={"add-user"} id={"new-user"}
                     style={{border: "2px solid rgba(125, 163, 205, 0.4)", margin: "8px 0"}}>
                    {this.loadingDataLine(account, phone, "当前时间", "当前时间",
                        this.loadLevelChange(1, hostLevel, false, null, null), op)}
                </div>
            )
            console.log(tableItems)
            this.setState({
                openAdd: true,
                tableItems: lst,
            }, () => {
                scrollToAnchor("new-user")
            })
        }
    }

    render() {
        let host = this.state.hostInfo;
        let loaded = this.state.loaded;
        let openAdd = this.state.openAdd;
        let tableItems = this.state.tableItems;
        let hostLevel = host.authLevel;
        let hostPhone = host.hostPhone;
        let newHost = this.state.newHost;
        return (
            <div className={"page-wrap"} id={"host-fun"}>
                {loaded ?
                    <div style={{position: "relative", width: "100%", height: "100%"}}>
                        <div style={{minWidth: "1130px", padding: "20px 30px 0 30px"}}>
                            <div className={"log-line"}>
                                <button className={"add-host"} onClick={() => {
                                    this.addUserEditLine(openAdd, tableItems, hostLevel, newHost, hostPhone)
                                }} style={openAdd ? {
                                    background: "rgb(147,147,147)",
                                    cursor: "not-allowed"
                                } : {}}>{"添加管理员"}</button>
                            </div>
                            <div className={"log-line"}
                                 style={{fontWeight: "bold", background: "#d1e4f8", marginBottom: "6px"}}>
                                {this.loadingDataLine("管理员账号", "管理员手机", "最近登录时间", "注册时间", "权限等级", "操作")}
                            </div>
                        </div>
                        <div style={{minWidth: "1130px", padding: "0 30px", height: "calc(100% - 140px)"}}>
                            <div className={"host-table"}>
                                {tableItems}
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        )
    }

}

export default HostMgr;