import {createMsg} from "../MsgWin";

const connectionError = "CT_ERROR";

const loginOut = "LOGIN_OUT";

const fail = (failInfo) => {
    return createMsg()(failInfo, 2, 2);
}

const failReturn = (failInfo, url) => {
    return createMsg()(failInfo, 2, 2, url);
}

const success = (successInfo) => {
    return createMsg()(successInfo, 2, 1);
}

const successReturn = (successInfo, url) => {
    return createMsg()(successInfo, 2, 1, url);
}

const findMsgByError = (error) => {
    console.log(error)
    switch (error) {
        case connectionError: {
            return "网络连接错误，请稍后重试！";
        }
        case loginOut: {
            return "账号登录超时，请重新登录！";
        }
        case "SYSTEM_ERROR": {
            return "系统错误，请稍后重试！";
        }
        case "OP_ERROR": {
            return "操作错误，请检查！";
        }
        case "NOT_MATCH_ERROR": {
            return "未找到对应的数据，请重新检索！";
        }
        case "INPUT_ERROR": {
            return "输入错误，请重试！";
        }
        case "USER_REPEAT": {
            return "用户名重复，请重新输入！";
        }
        case "PHONE_REPEAT": {
            return "手机重复，请重新输入！";
        }
        case "EMAIL_REPEAT": {
            return "邮箱重复，请重新输入！";
        }
        case "WRONG_PASS_ERROR": {
            return "系统错误，请稍后重试！";
        }
        case "TIME_OUT": {
            return "操作超时！";
        }
        case "CODE_ERROR": {
            return "验证码错误，请重新输入！";
        }
        case "ACCESS_ERROR": {
            return "数据错误，请重试！";
        }
        case "PIC_DEL": {
            return "照片删除错误，请稍后重试";
        }
        case "SERVICE_DISABLE": {
            return "服务失效，请稍后重试！";
        }
    }
}

const findMsgByTip = (tip) => {
    switch (tip) {
        case "NO_PHONE": {
            return "请输入手机号";
        }
        case "NO_ACCOUNT": {
            return "请输入账号"
        }
    }
}

export {
    connectionError,
    loginOut,
    fail,
    failReturn,
    success,
    successReturn,
    findMsgByError,
    findMsgByTip,
}
